import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as ProcessingReportAction from "../../actions/limsProcessingReportAction";
import DataGrid from "../../components/DataGrid";
import _ from "lodash";
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import { hasRole } from '../../utils/auth';
import ReferralService from '../../services/referralService';

class ProcessingPatientReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: { sortField: "created_at", sortOrder: "desc" },
      tags: {
        patient_name: "",
        referral_date: "",
      },
      filter: {
        patient_name: "",
        referral_date: "",
      },
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.props.paginate({ sortBy: this.state.sortBy });
  }

  columns = () => {
    return [
      {
        dataField: "created_at",
        text: "Date Received",
      },
      {
        dataField: "name",
        text: "Patient Name",
      },
      {
        dataField: "doctor_name",
        text: "Referred Doctor",
      },
      {
        dataField: "sample_collected_at",
        text: "Sample Collected At",
      },
      {
        dataField: "status",
        text: "Status",
        formatter: this.statusFormatter,
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: this.actionFormatter,
      },
    ];
  };

  statusFormatter = (row) => {
    if (_.isEmpty(row)) {
      return "NA";
    } else {
      return _.capitalize(row);
    }
  };

  actionFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <Link to={"/lab-report/" + row.id} title="View"><span className="icon file"></span></Link>
      </React.Fragment>
    );
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText, filter }) => {
    if (type === "search") {
      page = 1;
    }

    let sortBy = sortField ? { sortField, sortOrder } : this.state.sortBy;
    filter = filter ? filter : this.state.filter;

    this.props.paginate({ page, sizePerPage, sortBy, searchText, filter });
  };

  removeFilter = (e) => {
    const filter = this.state.filter;
    const tags = this.state.tags;

    filter[e.target.dataset.tag] = "";
    tags[e.target.dataset.tag] = "";

    this.setState({ filter: filter, tags: tags });
    this.props.paginate({ sortBy: this.state.sortBy, filter });
  };

  renderTags(tags) {
    let options;
    return Object.keys(tags).map((tag) => {
      if (tags[tag] !== "" && !_.isEmpty(tags[tag])) {
        options = (
          <span className="search-list">
            {tags[tag]}{" "}
            <span
              className="removesearch"
              data-tag={tag}
              onClick={this.removeFilter.bind(this)}
            >
              {" "}
              x{" "}
            </span>{" "}
          </span>
        );
      } else {
        return false;
      }
      return options;
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    window['searchToggle']();

    this.setState({
      tags: {
        patient_name: this.state.filter.patient_name,
        referral_date: this.state.filter.referral_date,
      }
    });
    this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
  };

  handleChange = (e) => {
    const filter = this.state.filter;
    filter[e.target.name] = e.target.value ? e.target.value : undefined;
    this.setState({ filter });
  };

  resetSearch = () => {
    this.setState({
      filter: {
        patient_name: "",
        referral_date: "",
      },
      tags: {
        patient_name: "",
        referral_date: "",
      },
    });

    window["searchToggle"]();

    this.props.paginate({ sortBy: this.state.sortBy });
  };

  filterComponent = (tags) => {
    return (
      <div className="search-wrapper">
        <label>Search</label>
        <div className="search-box clearfix">
          {tags && this.renderTags(tags)}
          <span className="search-box-icon"></span>
          <div className="search-container">
            <form onSubmit={this.handleSubmit}>
              <ul>
                <li>
                  <span>Patient Name</span>
                  <input
                    type="text"
                    value={this.state.filter.patient_name}
                    onChange={this.handleChange}
                    name="patient_name"
                    autoComplete="off"
                  ></input>
                  {this.validator.message('patient_name', this.state.filter.patient_name, 'alpha_num_space')}

                </li>
                <li>
                  <span>Date Received</span>
                  <input
                    type="date"
                    value={this.state.filter.referral_date}
                    onChange={this.handleChange}
                    name="referral_date"
                    autoComplete="off"
                    max={moment().format('YYYY-MM-DD')}
                  ></input>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={this.resetSearch}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  };

  handleExportCSV = () => {
    ReferralService.exportProcessingReferrals({ sortBy: this.state.sortBy, filter: this.state.filter }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "ProcessingReport.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  render() {
    let { data, meta } = this.props.lims_processing_report;
    return (
      <React.Fragment>
        <Helmet>
          <title>Processing Patient Report</title>
        </Helmet>
        <div className="row">
          <div className="col-md-24">
            <h4 className="page-title">Processing Patient Report</h4>
            {this.filterComponent(this.state.tags)}
          </div>
        </div>
        <div className="content-wrapper">
          {hasRole(this.props.selected_profile, ["pathology", "pathologist", "pathology-manager"]) &&
            <button type="button" className="btn btn-primary" onClick={this.handleExportCSV}>Export CSV</button>
          }
          <DataGrid
            columns={this.columns()}
            data={data}
            meta={meta}
            handleTableChange={this.handleTableChange}
            noDataIndication="No Record Found"
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lims_processing_report: state.lims_processing_report,
  selected_profile: state.selected_user_profile
});

const mapActionsToProps = {
  paginate: ProcessingReportAction.list
};

export default connect(mapStateToProps, mapActionsToProps)(ProcessingPatientReport);
