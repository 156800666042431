import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as UserManagementAction from '../../actions/userManagementAction';
import DataGrid from '../../components/DataGrid';
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import UserManagementService from '../../services/userManagementService';
import Notification from '../../utils/notification';
import ServiceProviderLocations from '../../components/ServiceProviderLocations';
import * as Config from '../../config/index';
import * as loaderAction from '../../actions/loaderAction';
import RoleService from '../../services/roleService';
import { hasRole } from '../../utils/auth';

class UserManagement extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			service_provider_id: null,
			service_provider_type: null,
			addFormData: {
				email: '',
				location_id: '',
				role_slug: '',
			},
			editFormData: {
				first_name: '',
				last_name: '',
				email: '',
				location_id: '',
				role_slug: '',
				user_profile_id: ''
			},
			filter: {
				user_location_id: undefined
			},
			isLoading: false,
			delete_id: null,
		}
		this.validator = new SimpleReactValidator();
		this.validatorEdit = new SimpleReactValidator();
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		const service_provider_id = this.props.selected_user_profile.service_provider_id;

		this.setState({
			service_provider_id: service_provider_id,
			service_provider_type: this.props.selected_user_profile.service_provider.service_provider_type,
		})

		this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" } });
	}

	columns = () => {
		return [
			{
				dataField: 'user_profile_id',
				text: 'Profile Id',
			}, {
				dataField: 'user.full_name',
				text: 'Name',
				sort: true
			}, {
				dataField: 'user.email',
				text: 'Email',
			}, {
				dataField: 'role_slug',
				text: 'Role',
				formatter: (cell) => {
					return RoleService.getRoleNameBySlug(cell);
				}
			}, {
				dataField: 'user.status',
				text: 'Status',
				formatter: this.statusFormatter,
			}, {
				dataField: 'user_location.name',
				text: 'Location',
			}, {
				dataField: "procedure",
				text: "Procedure Cost",
				isDummyField: true,
				formatter: this.procedureFormatter
			}, {
				dataField: "actions",
				text: "Action",
				isDummyField: true,
				formatter: this.actionFormatter
			}
		]
	};

	handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
		if (type === 'search') {
			page = 1;
		}
		const filter = this.state.filter;

		this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter });
	}

	procedureFormatter = (_cell, row) => {
		if (row.user_profile_id === this.props.selected_user_profile.user_profile_id) {
			return <React.Fragment></React.Fragment>;
		}

		return ( hasRole(row, ["associate-doctor","doctor-nurse"]) && row.user ? <React.Fragment>
			<Link to="#" className={"btn-toggle " + (row.is_procedure_listed ? '' : 'off')} onClick={e => this.updateProcedureListedStatus(row.user_profile_id, row.is_procedure_listed)}></Link>
		</React.Fragment> : '');
	};

	actionFormatter = (_cell, row) => {
		if (row.user_profile_id === this.props.selected_user_profile.user_profile_id) {
			return <React.Fragment></React.Fragment>;
		}

		return (row.user ? <React.Fragment>
			<Link to="#" className={"btn-toggle " + (row.is_active ? '' : 'off')} onClick={e => this.updateUserStatus(row.user_profile_id, row.is_active)}></Link>
			{row.user.status === 0 && <Link to='#' data-modal-id={row.is_active ? 'edit-user' : ''} className={"edit " + (row.is_active ? '' : 'off')} onClick={e => this.setEditFormHandler(row)}></Link>}
		</React.Fragment> : '');
	};

	statusFormatter = (cell, row) => {
		return (<React.Fragment>
			{cell === 1 ? 'Verified' : <React.Fragment>{'Pending'}<Link className="resend-email" onClick={e => this.sendReverifyEmail(row.user.email)}></Link></React.Fragment>}
		</React.Fragment>)
	}

	rowClasses = (row, rowIndex) => {
		return row.is_active ? '' : ' disable';
	};

	sendReverifyEmail = (email) => {
		this.props.showLoader();
		UserManagementService.sendReverifyEmail(email).then(resp => {
			let data = resp.data;
			this.props.hideLoader();
			Notification.show('success', data)
		}).catch(error => {
			this.props.hideLoader();
			if (error.response && error.response.status) {
				Notification.show('error', error.response.data);
			}
		});
	}

	updateUserStatus(user_profile_id, status) {
		let status_val = status ? 'deactivate' : 'activate';
		let confirm = window.confirm("Are you sure you want to " + status_val + " this user?");
		if (confirm === true) {
			this.props.showLoader();
			if (user_profile_id !== null) {

				UserManagementService.updateUserStatus(user_profile_id, status_val)
					.then(resp => {
						this.props.hideLoader();
						Notification.show("success", { message: "User is " + (status_val) + "d successfully" });
						this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter });
					})
					.catch(error => {
						this.props.hideLoader();
						if (error.response && error.response.status) {
							Notification.show('error', error.response.data);
						}
					});
			}
		}
	}

	updateProcedureListedStatus(user_profile_id, status) {
		let status_val = status ? 'deactivate' : 'activate';
		let confirm = window.confirm("Are you sure you want to " + status_val + " procedure cost?");
		if (confirm === true) {
			this.props.showLoader();
			if (user_profile_id !== null) {

				UserManagementService.updateUserProcedure(user_profile_id, status_val)
					.then(resp => {
						this.props.hideLoader();
						Notification.show("success", { message: "Procedure Cost " + (status_val) + "d successfully" });
						this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter });
					})
					.catch(error => {
						this.props.hideLoader();
						if (error.response && error.response.status) {
							Notification.show('error', error.response.data);
						}
					});
			}
		}
	}

	setEditFormHandler = (userData) => {
		this.setState({
			editFormData: {
				email: userData.user.email,
				location_id: userData.user_location_id,
				role_slug: userData.role_slug,
				user_profile_id: userData.user_profile_id
			}
		});
	}

	handleChange(e) {
		const { addFormData } = this.state;
		addFormData[e.target.name] = e.target.value;

		this.setState({
			addFormData: addFormData
		});
	}

	onHandleChange = (e) => {
		const { editFormData } = this.state;
		editFormData[e.target.name] = e.target.value;

		this.setState({
			editFormData: editFormData
		});
	}

	handleSubmit = e => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
		}
		this.setState({ isLoading: true })

		let data = {
			email: this.state.addFormData.email,
			role_slug: this.state.addFormData.role_slug,
			location_id: this.state.addFormData.location_id,
			service_provider_id: this.state.service_provider_id,
			procedure: this.state.addFormData.procedure,
		}
		this.props.showLoader();
		UserManagementService.create({ data }).then(resp => {
			let data = resp.data;
			this.props.hideLoader();
			Notification.show('success', data)
			this.closeModal.click();
			this.addUserForm.reset();
			this.validator.hideMessages();
			this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" } });
		}).catch(error => {
			this.props.hideLoader();
			if (error.response && error.response.status) {
				Notification.show('error', error.response.data);
			}
		});
	}

	handleAddUserClick = (e) => {
		e.preventDefault();
		const addFormData = {
			email: '',
			location_id: '',
			role_slug: '',
			procedure: 'no',
		};

		this.setState({
			addFormData: addFormData
		});
	}

	handleLocationFilter = (user_location_id) => {
		const filter = this.state.filter;
		filter.user_location_id = user_location_id ? user_location_id : undefined;

		this.setState({
			filter: filter,
		});
		this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter });
	}

	handleLocationChange = (location_id) => {
		const { addFormData } = this.state;
		addFormData['location_id'] = location_id;

		this.setState({
			addFormData: addFormData
		});
	}

	handleEditLocationChange = (location_id) => {
		const { editFormData } = this.state;
		editFormData['location_id'] = location_id;

		this.setState({
			editFormData: editFormData
		});
	}

	renderRoleSlug = () => {
		return (Config.roles[this.state.service_provider_type].map(function (key, index) {
			return <option value={key.slug} key={index}>
				{key.name}
			</option>;
		}));
	}

	handleResetAddUserForm = (e) => {
		e.preventDefault();
		this.addUserForm.reset();
		this.validator.hideMessages();
	}

	handleResetEditUserForm = (e) => {
		e.preventDefault();
		this.editUserForm.reset();
		this.validatorEdit.hideMessages();
	}

	handleEditSubmit = e => {
		e.preventDefault();
		if (!this.validatorEdit.allValid()) {
			this.validatorEdit.showMessages();
			this.forceUpdate();
			return false;
		}

		this.setState({ isLoading: true });

		let data = this.state.editFormData;
		this.props.showLoader();

		UserManagementService.updateUser({ data }).then(resp => {
			this.props.hideLoader();
			Notification.show('success', { message: 'User data updated successfully.' });
			this.closeModal.click();
			this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" } });
		}).catch(error => {
			this.props.hideLoader();
			if (error.response && error.response.status) {
				Notification.show('error', error.response.data);
			}
		});
	}

	render() {
		let { data, meta, isLoading } = this.props.user_management;

		return (<React.Fragment>
			<Helmet>
				<title>User Management</title>
			</Helmet>
			<div className="row">
				<div className="col-md-24">
					<h4 className="page-title">User Management</h4>
					<div className="content-wrapper add-user-section">
						<div className="select-location">
							<label>Select Location</label>
							<ServiceProviderLocations
								service_provider_id={this.state.service_provider_id}
								handleLocationChange={this.handleLocationFilter} />
						</div>
						<a href data-modal-id="add-user" className="btn btn-primary" onClick={this.handleAddUserClick}>Add User</a>
					</div>
					<div className="content-wrapper">
						{this.props.user_management && <DataGrid
							loading={isLoading}
							noDataIndication="No Record Found"
							classes="user-management"
							columns={this.columns()}
							data={data}
							meta={meta}
							handleTableChange={this.handleTableChange}
							rowClasses={this.rowClasses}
						/>}
					</div>
				</div>
			</div>

			<div id="add-user">
				<div className="modal-box">
					<div className="user-popup-icon">
						<img src="/images/create-user-icon.png" alt="" />
						<h4>Create New User</h4>
					</div>
					<form ref={form => this.addUserForm = form}>
						<ul className="form-listing">
							<li>
								<label className="required">Location</label>
								<ServiceProviderLocations service_provider_id={this.state.service_provider_id} handleLocationChange={this.handleLocationChange} />
								{this.validator.message('location', this.state.addFormData.location_id, 'required')}
							</li>
							<li>
								<label className="required">Role</label>
								<select className="form-control" onChange={this.handleChange} value={this.state.addFormData.role_slug} name="role_slug">
									<option value=""> Select Role </option>
									{this.renderRoleSlug()}
								</select>
								{this.validator.message('role', this.state.addFormData.role_slug, 'required')}
							</li>
							<li>
								<label className="required">Email</label>
								<input type="text" className="form-control" name="email" value={this.state.addFormData.email} onChange={this.handleChange} />
								{this.validator.message('email', this.state.addFormData.email, 'required|email')}
							</li>
							<li>
								<label className="required procedure-label">
									Access to Procedure Cost
								</label>
								<input className='form-control' type="radio" id="yes" name="procedure" value="yes" onChange={this.handleChange} checked={this.state.addFormData.procedure == 'yes' ? true : false} />
								<label htmlFor="yes" className="procedure-radio-label">Yes</label>
								<input className='form-control' type="radio" id="no" name="procedure" value="no" onChange={this.handleChange} checked={this.state.addFormData.procedure == 'no' ? true : false} />
								<label htmlFor="no" className="procedure-radio-label">No</label>
							</li>
							<li className="button-wrapper">
								<button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
								<button type="button" className="btn btn-secondary js-modal-close" onClick={this.handleResetAddUserForm} ref={input => this.closeModal = input}>Cancel</button>
							</li>
						</ul>
					</form>
				</div>
			</div>

			<div id="edit-user">
				<div className="modal-box">
					<div className="user-popup-icon">
						<img src="/images/edit-user-icon.png" alt="" />
						<h4>Edit User</h4>
					</div>
					<form onSubmit={this.handleEditSubmit} ref={form => this.editUserForm = form}>
						<ul className="form-listing">
							<li>
								<label className="required">Location</label>
								<ServiceProviderLocations service_provider_id={this.state.service_provider_id} handleLocationChange={this.handleEditLocationChange} user_location_id={this.state.editFormData.location_id} />
								{this.validatorEdit.message('location', this.state.editFormData.location_id, 'required')}
							</li>
							<li>
								<label className="required">Role</label>
								<select className="form-control" onChange={this.onHandleChange} value={this.state.editFormData.role_slug} name="role_slug">
									<option value=""> Select Role </option>
									{this.renderRoleSlug()}
								</select>
								{this.validatorEdit.message('role', this.state.editFormData.role_slug, 'required')}
							</li>
							<li>
								<label className="required">Email</label>
								<input type="email" className="form-control" name="email" onChange={this.onHandleChange} value={this.state.editFormData.email} />
								{this.validatorEdit.message('email', this.state.editFormData.email, 'required|email')}
							</li>
							<li className="button-wrapper">
								<button type="submit" className="btn btn-primary">Submit</button>
								<button className="btn btn-secondary js-modal-close" onClick={this.handleResetEditUserForm} ref={input => this.closeModal = input}>Cancel</button>
							</li>
						</ul>
					</form>
				</div>
			</div>


		</React.Fragment>);
	}
}

const mapStateToProps = (state) => ({
	user_management: state.user_management,
	selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = ({
	paginate: UserManagementAction.list,
	showLoader: loaderAction.loaderShow,
	hideLoader: loaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(UserManagement);
