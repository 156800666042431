import initialState from './initialState';

export default function report(state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case 'REPORT_LIST_REQUEST':
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case 'REPORT_LIST_SUCCESS':
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                isLoading: false
            };
        case 'REPORT_LIST_FAILED':
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        default:
            return state;
    }
}

 
