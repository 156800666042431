export const PAGINATION_LIMIT = 25;

export const name_title = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Mast.'];

export const minor_name_title = ['Ms.', 'Mast.'];

export const doctor_degree = ['MBBS', 'BDS', 'MD', 'MS', 'MDS', 'DM', 'DS', 'DNB'];

export const doctor_specialization = ['General Practitioner', 'Family Physician', 'Pediatrician', 'Cardiologist', 'Obstetrician/Gynecologist', 'Surgeon', 'Psychiatrist', 'Dermatologist', 'Orthopedic', 'Endocrinologist', 'Neurologist', 'Nephrologist', 'Gastroenterologist', 'Infectious Disease', 'Ophthalmologist', 'Otolaryngologist/ ENT', 'Pulmonologist', 'Radiologist', 'Oncologist', 'Anesthesiologist'];


export const allowUserRole = [
    'doctor',
    'receptionist',
    'nurse',
    'pharmacist'
];

export const parish = {
    'Saint Andrew': 'Saint Andrew',
    'Kingston': 'Kingston',
    'Saint Thomas': 'Saint Thomas',
    'Portland': 'Portland',
    'Saint Mary': 'Saint Mary',
    'Saint Ann': 'Saint Ann',
    'Trelawny': 'Trelawny',
    'Saint James': 'Saint James',
    'Hanover': 'Hanover',
    'Westmoreland': 'Westmoreland',
    'Saint Elizabeth': 'Saint Elizabeth',
    'Manchester': 'Manchester',
    'Clarendon': 'Clarendon',
    'Saint Catherine': 'Saint Catherine',
};

export const roles = {
    doctor: [
        {
            name: 'Doctor\'s Assistant',
            slug: 'junior-doctor',
        }, {
            name: 'Associate Doctor',
            slug: 'associate-doctor'
        }, {
            name: 'Receptionist',
            slug: 'doctor-receptionist',
        }, {
            name: 'Nurse',
            slug: 'doctor-nurse'
        }, {
            name: 'Medical Practice Manager',
            slug: 'medical-practice-manager'
        }],

    pharmacy: [
        {
            name: 'Pharmacist',
            slug: 'pharmacist',
        }, {
            name: 'Technician',
            slug: 'pharmacy-technician'
        }, {
            name: 'Pharmacy Manager',
            slug: 'pharmacy-manager'
        }],

    pathology: [
        {
            name: 'Pathologist',
            slug: 'pathologist',
        }, {
            name: 'Manager',
            slug: 'pathology-manager'
        }, {
            name: 'Receptionist',
            slug: 'pathology-receptionist'
        }, {
            name: 'Phlebotomist',
            slug: 'pathology-phlebotomist'
        }, {
            name: 'Med Tech',
            slug: 'pathology-med-tech'
        }, {
            name: 'Lab Tech',
            slug: 'pathology-lab-tech'
        }, {
            name: 'Data Entry Clerk',
            slug: 'data-entry-clerk'
        }],

    radiology: [
        {
            name: 'Radiologist',
            slug: 'radiologist',
        }, {
            name: 'Receptionist',
            slug: 'radiology-receptionist'
        }],

    ambulance: [{
        name: 'Assistant',
        slug: 'ambulance-assistant'
    }],
    hospital: [{
        'name': 'Paramedic Doctor',
        'slug': 'paramedic-doctor',
    }],
    'covid-center': [{
        'name': 'Doctor',
        'slug': 'covid-doctor',
    }, {
        'name': 'Nurse',
        'slug': 'covid-nurse',
    }, {
        'name': 'Technician',
        'slug': 'covid-technician',
    }, {
        'name': 'Vendor',
        'slug': 'covid-vendor',
    }],
    airline: [{
        'name': 'Authority Access Assistant',
        'slug': 'airport-assistant',
    }],
    non_dh_doctor: [{
        name: "Non DH Doctor",
        slug: "nondhdoctor"
    }]

}

export const DOCTORDEGREE = ['MBBS', 'BDS', 'MD', 'MS', 'MDS', 'DM', 'DS', 'DNB', 'OTHER'];

export const SPECIALIZATION = ['General Practitioner', 'Family Physician', 'Pediatrician', 'Cardiologist', 'Obstetrician/Gynecologist', 'Surgeon', 'Psychiatrist', 'Dermatologist', 'Orthopedic', 'Endocrinologist', 'Neurologist', 'Nephrologist', 'Gastroenterologist', 'Infectious Disease', 'Ophthalmologist', 'Otolaryngologist/ ENT', 'Pulmonologist', 'Radiologist', 'Oncologist', 'Anesthesiologist', 'Rheumatologist', 'Other'];

export const BLOODGROUP = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

export const TOLL_FREE_NO = '1-888-429-5765';

export const DISEASE_LIST = ['Cancer', 'Heart Disease', 'High Blood Pressure', 'Diabetes', 'Stroke', 'High Cholesterol', 'Liver Disease', 'Alcohol or Drug Abuse', 'Anxiety', 'Psychiatric Illness', 'Tuberculosis', 'Anesthesia Complications', 'Genetic Disorder', 'Other'];

export const FAMILY_MEMBERS = ['Grandparents', 'Father', 'Mother', 'Brothers', 'Sisters', 'Sons', 'Daughters', 'None']

export const INSURANCE = {
    "private-insurance-sagicore": 'Sagicor',
    "private-insurance-medicus": 'Medicus',
    "private-insurance-canopy": 'Canopy',
    'jadep': 'JADEP',
    'nhf': 'NHF',
    'goj': 'GOJ',
    'other': 'Other'
}
export const DIMENSION = 'small';
export const PL_POINT_RATE = 0.7

export const FLAG = {
    'low': 'Low',
    'medium': 'Medium',
    'high': 'High',
    'positive': 'Positive',
    'negative': 'Negative',
    'normal': 'Normal',
    'abnormal': 'Abnormal',
    'cancer': 'Cancer',
};