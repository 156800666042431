import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as TempDocReportAction from "../../actions/tempDocReportAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import _ from 'lodash';
import { hasProvider } from '../../utils/auth';
import { Link } from "react-router-dom";
import DownloadAndPrintReport from '../../components/DownloadAndPrintReport';
import TempDocReportService from '../../services/tempDocReportService';

class VerifiedReportListPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            referralId: '',
            pdfUrl:''
        }
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" } });

    }

    columns = (selected_user_profile) => {
        if (selected_user_profile.service_provider.service_provider_type === 'nondhdoctor') {
            return [
                {
                    dataField: "report_name",
                    text: "Report Name",
                    sort: false
                },
                {
                    dataField: "lab_name",
                    text: "Lab Name",
                    sort:false
                },
                {
                    dataField: "patient_name",
                    text: "Patient Name",
                    sort: false,
                },
                {
                    dataField: "report_viewed",
                    text: "Report Viewed on",
                    sort: true,
                    formatter:this.dateFormatter
                },
                {
                    dataField: "patient_name",
                    text: "Action",
                    isDummyField: true,
                    formatter: this.actionFormatter
                }
            ];
        } 
    };

    handleClick(id){
        this.setState({
            referralId: id
        });
        setTimeout(() => {
            TempDocReportService.getReportDownload(this.state.referralId).then(resp => {
                this.setState({
                    isloading: false,
                    pdfUrl: resp.data.url
                });
            }).catch(error => {
                this.setState({
                    isloading: false
                });
                Notification.show('error', error.response.data);
            });
        }, "50");
        
    }

    actionFormatter = (_cell, row) => {
        return (
            <React.Fragment>
                <Link id={row.report_id} onClick={(e) => this.handleClick(row.report_id)} data-modal-id="authorized" title="View Report">View Report</Link>
            </React.Fragment>
        )
    }

    dateFormatter = (cell) => {
        return Helper.getDateFormat(cell);
    };

    handleTableChange = ({ page, sizePerPage, sortField, sortOrder}) => {
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    render() {
        let { data, meta, isLoading } = this.props.reports;
        let { selected_user_profile } = this.props;
        const pdfUrl = this.state.pdfUrl;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Report</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Report Details</h4>
                        {data && hasProvider(selected_user_profile.service_provider, ['nondhdoctor'])}
                    </div>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        loading={isLoading}
                        noDataIndication="No Record Found"
                        columns={this.columns(selected_user_profile)}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>
                <div id="authorized">
                    <div className="modal-box">
                        <div className="modal-close">
                            <button type="button" class="close js-modal-close" onClick={this.hideOtpDiv}>
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <DownloadAndPrintReport
                            pdfUrl={pdfUrl}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    reports: state.temp_doc_reports,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    paginate: TempDocReportAction.verifiedlist
};

export default connect(mapStateToProps, mapActionsToProps)(VerifiedReportListPage);