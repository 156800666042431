import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class DoctorService {
    static patientList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter})
        };
        return axios.get(apiRoute("/v1/non-dh-patients-list"), requestOptions);
    }
}


export default DoctorService;