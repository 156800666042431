import * as types from './actionTypes';
import LimsTestService from '../services/limsTestService';
import Notification from '../utils/notification';

export function reset() {
    return dispatch => {
        dispatch({ type: types.LIMS_TEST_LIST_RESET });
    }
}

export function getLimsTestPriceList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.LIMS_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        LimsTestService.list({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.LIMS_TEST_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.LIMS_TEST_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function getLimsTestList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.LIMS_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        LimsTestService.limsList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.LIMS_TEST_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.LIMS_TEST_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function pathologyLimsTestUpdate(id, statedata, history) {

    return dispatch => {
        LimsTestService.pathologyLimsTestUpdate(id, statedata)
            .then(resp => {
                let data = resp.data;
                Notification.show('success', { message: 'Lims Test updated successfully' });
                dispatch({ type: types.ALERT_SUCCESS, message: 'Lims Test updated successfully' });
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/pathology-lims-tests');
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    Notification.show('error', { message: 'Something Went Wrong, Try Again' });
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function pathologyLimsTestAdd(statedata, history) {

    return dispatch => {

        LimsTestService.pathologyLimsTestAdd(statedata)
            .then(resp => {
                let data = resp.data;
                if(data == true){
                    Notification.show('success', { message: 'Lims Test added successfully' });
                    dispatch({ type: types.ALERT_SUCCESS, message: 'Lims Test added successfully' });
                }else{
                    Notification.show('error', { message: 'Lims Test already exists' });
                    dispatch({ type: types.ALERT_ERROR, message: 'Lims Test already exists' });
                }               
                setTimeout(() => {
                    dispatch({ type: types.ALERT_CLEAR });
                    history.push('/pathology-lims-tests');
                }, 2500);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    dispatch({ type: types.ALERT_ERROR, ...errorData });
                } else {
                    Notification.show('error', { message: 'Something Went Wrong, Try Again' });
                    dispatch({ type: types.ALERT_ERROR, message: 'Something Went Wrong, Try Again' });
                }
            });
    }
}

export function getMasterLimsTestList({ page, sizePerPage, sortBy, searchText, filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' })
        dispatch({ type: types.LIMS_MASTER_TEST_LIST_REQUEST, request: { page, sizePerPage, sortBy, filter } });

        LimsTestService.limsMasterTestList({ page, sizePerPage, sortBy, searchText, filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' })
                dispatch({ type: types.LIMS_MASTER_TEST_LIST_SUCCESS, ...data });
            })
            .catch(error => {
                dispatch({ type: types.LIMS_MASTER_TEST_LIST_FAILED, ...error.response })
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}