import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Auth from '../utils/auth'

class RedirectPage extends Component {

    componentDidMount() {
        const { user_profile, history } = this.props;

        if (Auth.hasRole(user_profile, ["doctor-nurse", "junior-doctor"])) {
            history.push("/prescriptions");
        } else if (Auth.hasRole(user_profile, ["pharmacist"])) {
            history.push('/prescription/orders/queue');
        } else if (Auth.hasRole(user_profile, ["pharmacy-technician"])) {
            history.push('/pharmacy/technician/prescription/list');
        } else if (Auth.hasRole(user_profile, ["pathologist"])) {
            history.push('/pathology/orders');
        } else if (Auth.hasRole(user_profile, ["radiologist"])) {
            history.push('/radiology/orders');
        } else if (Auth.hasRole(user_profile, ["radiology-receptionist"])) {
            history.push('/patient/radiology-tests');
        } else if (Auth.hasRole(user_profile, ["pathology-receptionist"])) {
            history.push('/patient/pathology-tests');
        } else if (Auth.hasRole(user_profile, ["doctor-receptionist", 'covid-nurse', 'covid-technician', 'covid-doctor'])) {
            history.push('/patients/search');
        } else if (Auth.hasRole(user_profile, ["airline-owner"])) {
            history.push('/user-management');
        } else if (Auth.hasRole(user_profile, ['covid-center-owner'])) {
            history.push('/dashboard');
        } else if (Auth.hasRole(user_profile, ["airport-assistant"])) {
            history.push('/authority-access/patients/search');
        } else if (Auth.hasRole(user_profile, ['covid-vendor'])) {
            history.push('/patient-details');
        } else if (Auth.hasRole(user_profile, ['nondhdoctor'])) {
            history.push('/temp-doctor/patients/search');
        } else {
            history.push('/dashboard');
        }
    }

    render() {
        return (
            <React.Fragment>
                <h1>Redirecting....</h1>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user_profile: state.selected_user_profile
});

export default connect(mapStateToProps)(RedirectPage);