import React from "react";
import { Modal } from 'react-bootstrap';
import limsTestService from '../services/limsTestService';
import Notification from '../utils/notification'
import _ from 'lodash'
import SimpleReactValidator from 'simple-react-validator';

class AddTestCategoryBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: '',
            searchKey: '',
            searchResult: [],
            selectedCategory: '',
            pathology: [],
            selectedTests: []
        }
        this.validator = new SimpleReactValidator();
        this.handleClick = this.handleClick.bind();
    }

    componentDidUpdate(prevProps, prevState) {
        let {resetValue} = this.props;
        if (resetValue !== prevProps.resetValue && resetValue === true) {
          this.setState({
            selectedCategory: '',
            pathology: [],
            selectedTests: []
          });
          this.props.updateResetValue(false);
        }
    }

    componentDidMount() {
        limsTestService.listAll().then(resp => {
            this.setState({
                tests: _.groupBy(resp.data.data, 'category')
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleChange = (evt, index) => {
        let value = evt.target.value
        let pathology = this.state.pathology;
        pathology[index] = {
            test_id: '',
            test_type: '',
            test_category: '',
            test_name: value,
            test_description: '',
            is_other: true
        }
        this.setState({ pathology: pathology });
    }

    handleSearch = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const searchKey = this.state.searchKey.toLowerCase();
        let searchResult = []
        _.forEach(this.state.tests, function (value) {
            if (!_.isEmpty(value.test_category) && _.includes(value.test_category.toLowerCase(), searchKey)) {
                searchResult = searchResult.concat(value)
            } else if (!_.isEmpty(value.test_name) && _.includes(value.test_name.toLowerCase(), searchKey)) {
                searchResult = searchResult.concat(value)
            }
        });
        this.setState({
            searchResult: searchResult
        })
    }

    handleClick = (key) => {
        let data = _.filter(this.state.tests, function (test, index) {
            return index === key
        });

        const tests = this.state.selectedTests;

        data[0].map((test, index) => (
            test.checked = tests.some(el => el.test_id === test.id) ? true : false
        ));
        const limsTests = _.groupBy(data[0], (item) => {
            return [item['name'], item['category']];
        });
        let limsData = [];
        Object.keys(limsTests).map(function (key, index) {
            if(limsTests[key].length >1){
                let findData = _.findKey(limsTests[key], ['new', true]);
                limsData.push(limsTests[key][findData]); 
            }else{
                limsData.push(limsTests[key][0]);
            }
        })

        this.setState({
            selectedCategory: [limsData]
        })
    }

    handleCheckbox = (e, test, index) => {
        const { selectedCategory } = this.state
        const options = selectedCategory[0].slice();
        if (options[index]) {
            options[index].checked = !options[index].checked;
        }
        if (e.target.checked) {
            this.setState({
                selectedTests: this.state.selectedTests.concat([{
                    test_id: test.id,
                    test_type: test.type,
                    test_category: test.category,
                    test_name: test.name,
                    test_description: '',//test.test_description,
                    is_other: false,
                    sub_tests: test.sub_test,
                    sample_nos: ''
                }])
            })
        }
        if (!e.target.checked) {
            const selectedTests = this.state.selectedTests.filter((s) => test.id !== s.test_id);
            this.setState({ selectedTests: selectedTests });
        }

    }

    handleAddPathology = () => {
        this.setState({
            pathology: this.state.pathology.concat([{
                test_id: '',
                test_type: '',
                test_category: '',
                test_name: '',
                test_description: '',
                is_other: true
            }])
        });
    }

    handleRemovePathologyTest = (event) => {
        this.validator.hideMessages()
        const idx = Number(event.target.dataset["id"]);
        const pathology = this.state.pathology.filter((s, sidx) => idx !== sidx);
        this.setState({ pathology: pathology });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() && !_.isEmpty(this.state.pathology)) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        let newData = this.state.selectedTests.concat(this.state.pathology);
        if (!_.isEmpty(newData)) {
            this.props.handlePathology(newData);
        } else {
            this.props.handlePathology([]);
        }
        this.props.handleAddTestCategoryBlock()
    }

    handleAddTestCategoryBlock = () => {
        this.validator.hideMessages()
        this.setState({
            selectedCategory: '',
            pathology: [],
            selectedTests: []
        });
        this.props.handleAddTestCategoryBlock()
    }

    render() {
        return (
            <>
                <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.handleAddTestCategoryBlock} className="original-prescription">
                    <Modal.Header closeButton>
                        <h6>Add Lab Test</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="category-list">
                            {this.state.tests && <div>
                                <h3>Test Category</h3>
                                <div className="scroll-list">
                                    <ul>
                                        {this.state.tests && Object.keys(this.state.tests).map((key, index) => (
                                            <li onClick={e => this.handleClick(key)} key={index} style={{}}>
                                                {key !== "null" ? key : "Profiles"}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                            {this.state.selectedCategory && <div>
                                <h3>Test Name</h3>
                                <div className="scroll-list">
                                    <ul>
                                        {this.state.selectedCategory[0].map((test, index) => (
                                            <li key={test.id}>
                                                <input type="checkbox" id={test.id} name="name" onClick={e => this.handleCheckbox(e, test, index)} checked={test.checked} />
                                                <label htmlFor={test.id}>{test.name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                    </Modal.Body>
                </Modal>
            </>)
    }
}
export default AddTestCategoryBlock
