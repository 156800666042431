import React from 'react';

export const Prostate = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Prostate</h6>
        <input type="checkbox" id='prostate-normal' name='prostate-normal' onChange={e => handleChange(e, 'prostate')} checked={checkIfProblemExist('prostate', 'prostate-normal') ? 'checked' : ''} />
        <label htmlFor='prostate-normal'>Normal</label>
    </React.Fragment>
}
export const ExternalGenitalia = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">External Genitalia </label>
            <input type="checkbox" id='prostate-external-genitalia-lumps' name='prostate-external-genitalia-lumps' onChange={e => handleChange(e, 'prostate')} checked={checkIfProblemExist('prostate', 'prostate-external-genitalia-lumps') ? 'checked' : ''} />
            <label htmlFor='prostate-external-genitalia-lumps'>Lumps</label>
            <input type="checkbox" id='prostate-external-genitalia-bumps' name='prostate-external-genitalia-bumps' onChange={e => handleChange(e, 'prostate')} checked={checkIfProblemExist('prostate', 'prostate-external-genitalia-bumps') ? 'checked' : ''} />
            <label htmlFor='prostate-external-genitalia-bumps'>Bumps</label>
            <input type="checkbox" id='prostate-external-genitalia-redness' name='prostate-external-genitalia-redness' onChange={e => handleChange(e, 'prostate')} checked={checkIfProblemExist('prostate', 'prostate-external-genitalia-redness') ? 'checked' : ''} />
            <label htmlFor='prostate-external-genitalia-redness'>Redness</label>
        </div>
    </React.Fragment>
}
export const DigitalRectalExam = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Digital Rectal Exam </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'prostate', 'prostate-digital-rectal-exam')} value={getProblemComment('prostate', 'prostate-digital-rectal-exam') ? getProblemComment('prostate', 'prostate-digital-rectal-exam') : ''} disabled={checkIfProblemExist('prostate', 'prostate-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Consistency = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Consistency </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'prostate', 'prostate-consistency')} value={getProblemComment('prostate', 'prostate-consistency') ? getProblemComment('prostate', 'prostate-consistency') : ''} disabled={checkIfProblemExist('prostate', 'prostate-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Sensitivity = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Sensitivity </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'prostate', 'prostate-sensitivity')} value={getProblemComment('prostate', 'prostate-sensitivity') ? getProblemComment('prostate', 'prostate-sensitivity') : ''} disabled={checkIfProblemExist('prostate', 'prostate-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const UrinarySymptoms = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Urinary Symptoms </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'prostate', 'prostate-urinary-symptoms')} value={getProblemComment('prostate', 'prostate-urinary-symptoms') ? getProblemComment('prostate', 'prostate-urinary-symptoms') : ''} disabled={checkIfProblemExist('prostate', 'prostate-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const PSABloodTest = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">PSA Blood Test </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'prostate', 'prostate-psa-blood-test')} value={getProblemComment('prostate', 'prostate-psa-blood-test') ? getProblemComment('prostate', 'prostate-psa-blood-test') : ''} disabled={checkIfProblemExist('prostate', 'prostate-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'prostate')} disabled={checkIfProblemExist('prostate', 'prostate-normal') ? 'disabled' : ''} value={getCategoryComment('prostate') ? getCategoryComment('prostate') : ''}></textarea>
    </React.Fragment>
}