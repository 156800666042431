import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pagination, { type, ...payload }) {
    switch (type) {
        case types.NEW_REPORT_REQUEST:
            return {
                ...state,
                ...payload
            };
        case types.NEW_REPORT_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case types.NEW_REPORT_FAILED:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}


