/* eslint-disable no-lone-blocks */
import React from 'react';
import Notification from '../utils/notification';
import TempDocOtpService from '../services/tempDoctorOtpService';

class AuthenticationNonDH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorOtp: '',
      patientOtp: '',
      countdown: 900,
      otpData: '',
      referralId: '',
    };
    this.timer = null;
  }

  componentDidMount() {
    this.startCountdown();
    TempDocOtpService.generateOtp(this.props.patientId).then( response => { 
      let { data } = response;
      this.setState({
          otpData: data,
      });
      Notification.show('success', {message:"OTP generated successfully"})
    })
    .catch(error => {
        if(error.response){
            Notification.show('error', error.response.data)
        }
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleOTPChange1 = (event) => {
    this.setState({ doctorOtp: event.target.value });
  };

  handleOTPChange2 = (event) => {
    this.setState({ patientOtp: event.target.value });
  };

  startCountdown = () => {
    this.timer = setInterval(() => {
      const { countdown } = this.state;
      if (countdown > 0) {
        this.setState({ countdown: countdown - 1 });
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  };


  handleSubmit = () => {
    TempDocOtpService.verifyOtp(this.props.patientId, this.props.referralId, this.state.doctorOtp, this.state.patientOtp).then( response => { 
      Notification.show('success', {message:"Otp Verified Successfully"})
      {this.props.showDownload()}
    })
    .catch(error => {
        if(error.response){
            Notification.show('error', error.response.data)
        }
    });
  };

  render() {
    const { doctorOtp, patientOtp, countdown } = this.state;
    return (
      <li className="AuthenticationWrapper">
        <div className="otpWrapper">
          <div>
            <h6>Enter Doctor's OTP</h6>
            <input className="form-control" type="text" value={doctorOtp} maxLength={6} onChange={this.handleOTPChange1} />
          </div>
          <div>
            <h6>Enter Patient's OTP</h6>
            <input className="form-control" type="text" value={patientOtp} maxLength={6} onChange={this.handleOTPChange2} />
          </div>
        </div>

        <h5>Doctor OTP Sent to {this.state.otpData.nondh_doctor_contact_number} <br/>
        Patient OTP Sent to {this.state.otpData.patient_contact_number}</h5>

        <h5><b>For temporary purpose OTP is showing here</b><br/>
        Doctor OTP: {this.state.otpData.nondh_doctor_otp} <br/>
        Patient OTP:  {this.state.otpData.patient_otp}
        </h5>

        {countdown > 0 && (
          <h4 className="countdown">OTP Valid for next {countdown} seconds</h4>
        )}

        <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
      </li>
    );
  }
}

export default AuthenticationNonDH;