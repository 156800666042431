import React from 'react';
import { connect } from 'react-redux';
import * as QueueAction from '../actions/queueAction';
import QueueDoctorSelectModal from './QueueDoctorSelectModal';
import AppointmentService from '../services/appointmentService';
import * as loaderAction from '../actions/loaderAction';
import Notification from '../utils/notification';

class DoctorAddQueueButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false, doctors: '' }
    }

    handleQueueClick = () => {
        const { user_location_id } = this.props.selected_user_profile
        this.props.showLoader();
        AppointmentService.getDoctorByLocation(user_location_id).then(resp => {
            this.setState({
                doctors: resp.data,
            });
            this.props.hideLoader();
        }).catch(_error => {
            this.props.hideLoader();
            Notification.show('error', { message: 'Something went wrong.' })
        })
        this.handleModalShow()
    }

    handleModalShow = () => {
        this.setState({ show: !this.state.show })
    }

    handleSubmit = (selectedDoctorState) => {
        const {type} = this.props
        if(type === "prescription"){
            this.props.handleAssignToDoctor(selectedDoctorState.doctor_profile_id)
        } else {
            const queueData = {
                patient_id: this.props.patientId,
                appointment_type: 'queue',
                prescription_id: '',
                doctor_profile_id: selectedDoctorState.doctor_profile_id
            };

            this.props.createQueue(queueData, this.props.history);
        }
    }

    render() {
        const {type} = this.props
        return (<>
            <button className={type && type === "prescription" ? "btn btn-primary" : "btn btn-secondary"} onClick={this.handleQueueClick.bind(this, this.props.patientId)}>
                {this.props.title ? this.props.title : "Add To Queue"}
            </button>
            <QueueDoctorSelectModal
                handleModalShow={this.handleModalShow}
                show={this.state.show}
                title="Doctor Selection"
                handleSubmit={this.handleSubmit}
                doctors={this.state.doctors && this.state.doctors}
            />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = ({
    createQueue: QueueAction.create,
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
});

export default connect(mapStateToProps, mapActionsToProps)(DoctorAddQueueButton);