import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import * as HELPER from "../../utils/helpers";
import PatientService from "../../services/patientService";
import Notification from "../../utils/notification";
import { hasRole } from '../../utils/auth';

class ReferralPatientDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: "",
        phone: "",
        doctor: "",
        sample_collected_at: "",
        address: "",
        dob: "",
        doctor_contact: "",
        doctor_email: "",
        height: "",
        weight: "",
        bmi: "",
        lab_id:"",
        comments:"",
      },
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    PatientService.get(id, '').then(resp => {
      let address = resp.data.address;
      address = (address.address_line_1 ? (address.address_line_1 + " ") : "") + (address.address_line_2 ? (address.address_line_2 + " ") : "") + (address.address_line_3 ? (address.address_line_3 + " ") : "") + (address.parish ? (address.parish + " ") : "") + (address.city ? (address.city + " ") : "") + (address.pincode ? address.pincode : "");
      const formData = {
        name: resp.data.full_name,
        phone: resp.data.contact_number,
        doctor: "",
        sample_collected_at: this.props.userlocation,
        dob: resp.data.date_of_birth,
        address: address,
        doctor_email: "",
        height: "",
        weight: "",
        bmi: "",
        lab_id:"",
        comments:"",
      }
      this.setState({
        formData
      })
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { formData } = this.state;
    if (prevState.formData !== formData) {
      this.props.onReferralFormChange(formData);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }), () => {
      if (['height', 'weight'].includes(name)) {
        let height = parseFloat(this.state.formData.height);
        let weight = parseFloat(this.state.formData.weight);
        let bmi = '';
        if (height && weight)
          bmi = parseFloat(((weight) / (height * height * 0.0001)).toFixed(2));

        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            bmi: bmi,
          },
        }));
      }
    });
  };

  isValid = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    return true;
  }

  handleReset = () => {
    this.setState({
      formData: {
        doctor: '',
        doctor_contact: '',
        doctor_email: '',
        height: '',
        weight: '',
        bmi: ''
      }
    })
  };

  render() {
    const { formData } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-24">
            <div className="content-wrapper add-lab-report">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="form-control"
                        value={formData.name}
                        onChange={this.handleChange}
                        disabled
                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        className="form-control"
                        value={formData.phone}
                        onChange={this.handleChange}
                        disabled
                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Doctor *</label>
                      <input
                        type="text"
                        name="doctor"
                        id="doctor"
                        placeholder="Doctor"
                        className="form-control"
                        value={formData.doctor}
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        'doctor',
                        formData.doctor,
                        "required"
                      )}
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Sample Collected At</label>
                      <input
                        type="text"
                        name="sample_collected_at"
                        id="sample_collected_at"
                        placeholder="Sample Collected At"
                        className="form-control"
                        value={this.props.userlocation}
                        disabled
                      />
                    </fieldset>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        className="form-control"
                        value={formData.address}
                        onChange={this.handleChange}
                        disabled
                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        name="dob"
                        id="dob"
                        placeholder="Date of Birth"
                        className="form-control"
                        value={formData.dob}
                        disabled
                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Doctor Contact</label>
                      <input
                        type="text"
                        name="doctor_contact"
                        id="doctor_contact"
                        placeholder="Doctor Contact"
                        className="form-control"
                        value={formData.doctor_contact}
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "doctor_contact",
                        formData.doctor_contact,
                        "phone|max:10"
                      )}
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Doctor Email</label>
                      <input
                        type="text"
                        name="doctor_email"
                        id="doctor_email"
                        placeholder="Doctor Email"
                        className="form-control"
                        value={formData.doctor_email}
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "doctor_email",
                        formData.doctor_email,
                        "email"
                      )}
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Height <small>[in cm]</small></label>
                      <input
                        type="number"
                        min="0"
                        name="height"
                        id="height"
                        className="form-control"
                        value={formData.height}
                        onChange={this.handleChange}
                        placeholder="cm"
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Weight <small>[in kgs]</small></label>
                      <input
                        type="number"
                        min="0"
                        name="weight"
                        id="weight"
                        className="form-control"
                        value={formData.weight}
                        onChange={this.handleChange}
                        placeholder="kgs"
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>BMI</label>
                      <input
                        type="text"
                        name="bmi"
                        id="bmi"
                        placeholder="-"
                        className="form-control"
                        readOnly
                        value={formData.bmi}
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Lab ID</label>
                      <input
                        type="text"
                        name="lab_id"
                        id="lab_id"
                        placeholder="Lab Id"
                        className="form-control"
                        value={formData.lab_id}
                        onChange={this.handleChange}
                      />
                    </fieldset>
                  </div>   
                  {hasRole(this.props.selected_profile, ['pathology-lab-tech','pathology-med-tech']) &&    
                    <div className="col-sm-6">
                      <fieldset className="form-group">
                        <label>Comments</label>
                        <textarea
                          name="comments"
                          id="comments"
                          placeholder="Comments"
                          className="form-control"
                          value={formData.comments}
                          onChange={this.handleChange}
                        />
                      </fieldset>
                    </div>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferralPatientDetails;