import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getDateFormat } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import Notification from '../../utils/notification'
import * as PrescriptionAction from '../../actions/prescriptionAction';
import { Tab, Tabs } from "react-bootstrap";
import PatientService from '../../services/patientService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import _ from 'lodash';
import { hasRole } from '../../utils/auth';

class ListTestFeesPage extends React.Component {
    columns = () => {
        return [{
            text: 'Date',
            dataField: 'date',
            formatter: this.dateTimeFormat,
        }, {
            dataField: 'patient_id',
            text: 'Patient Id',
        }, {
            text: 'Patient Name',
            dataField: 'patient_name',
        }, {
            text: 'Collected By',
            dataField: 'collected_by',
            formatter: this.displayValueFormatter,
        }, {
            text: 'Currency',
            dataField: 'currency.name'
        }, {
            text: 'Test Fee',
            dataField: 'lims_test_fee',
        }, {
            text: 'From Insurance',
            dataField: 'from_insurance',
        }, {
            text: 'Cash Collected',
            dataField: 'cash_collected',
        }, {
            text: 'From Card',
            dataField: 'from_card',
        }, {
            text: 'From Online',
            dataField: 'from_online',
        }, {
            text: 'Balance',
            dataField: 'balance',
        }, {
            text: 'Remark',
            dataField: 'payment_remark',
            formatter: this.displayValueFormatter,
            csvFormatter: this.displayValueFormatter
        }, {
            text: 'Total Amount',
            formatter: this.totalAmountFormatter,
            csvFormatter: this.totalAmountFormatter,
            csvExport: false,
            hidden: true
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter,
            csvExport: false
        }]
    };

    displayValueFormatter = (cell, row) => {
        return cell ? cell : '--'
    }

    dateTimeFormat = (cell, row) => {
        return getDateFormat(row.date)
    }

    totalAmountFormatter = (cell, row) => {
        return ((row.lims_test_fee ? parseFloat(row.lims_test_fee) : 0)).toFixed(2)
    }

    actionFormatter = (cell, row) => {
        if (row.status === "paid") {
            return (<React.Fragment>
                <a href onClick={e => this.getPdfLink(row.id)} title="View in New Tab">
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/>
                </a>
            </React.Fragment>);
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            sortBy: { sortField: "created_at", sortOrder: "desc" },
            filter: {
                type: "lims",
                prescription_fee_status: "paid",
                start_date: "",
                end_date: "",
            },
            tags: {
                date_filter: "",
            },
            show_start_date: new Date(),
            show_end_date: new Date(),
            isloading: false,
        }
    }

    componentDidMount() {
        this.props.paginatePrescriptionFees({ sortBy: this.state.sortBy, filter: this.state.filter });
    }

    getPdfLink = (feeId) => {
		this.setState({
			isloading: true
		});
		PatientService.getPatientFeeDownloadLink(feeId).then(resp => {
			this.setState({
				isloading: false
			});
			window.open(resp.data.link);
		}).catch(error => {
			this.setState({
				isloading: false
			});
			Notification.show('error', error.response.data);
		});

	}

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }

        let sortBy = sortField ? { sortField, sortOrder } : this.state.sortBy;

        this.props.paginatePrescriptionFees({ page, sizePerPage, sortBy, filter: this.state.filter });
    }

    onTabChange = key => {
        let { filter, sortBy } = this.state;
        filter.prescription_fee_status = key === "pending_fees" ? 'unpaid' : 'paid'
        this.setState({
            activeKey: key,
            filter
        })
        this.props.paginatePrescriptionFees({ sortBy, filter });
    }

    renderTags(tags) {
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
            } else {
                return false
            }
            return options
        })
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter, tags, show_start_date: new Date(), show_end_date: new Date() });
        this.props.paginatePrescriptionFees({ sortBy: this.state.sortBy, filter });
    }

    handleEvent = (_event, picker) => {
        let { show_start_date, show_end_date, filter, tags } = this.state;
        show_start_date = picker.startDate._d;
        show_end_date = picker.endDate._d;
        filter.start_date = (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '';
        filter.end_date = (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '';
        tags.date_filter = (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '';
        this.setState({ filter, show_start_date, show_end_date, tags });
    }

    handleSearch = () => {
        window['searchToggle']();
        this.props.paginatePrescriptionFees({ sortBy: this.state.sortBy, filter: this.state.filter });
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.start_date = "";
        filter.end_date = "";
        this.setState({
            filter,
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
            }
        });
        window['searchToggle']();
        this.props.paginatePrescriptionFees({ sortBy: this.state.sortBy, filter });
    }

    render() {
        let { data, meta } = this.props.prescription_fees.paginate;
        const { tags } = this.state
        const dataTable = <DataGrid
            columns={this.columns()}
            data={data}
            noDataIndication="No Record Found"
            handleTableChange={this.handleTableChange}
            meta={meta}
            pagination={true}
            export_csv={hasRole(this.props.selected_user_profile, ["pathology", "pathologist", "pathology-manager"])}
            export_class="btn btn-default btn-outline-primary"
        />
        return (<React.Fragment>
            {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
            <Helmet>
                <title>Test Fee/Charges</title>
            </Helmet>
            <section className="container-fluid" id="list_fee_collection">
                <div className="row">
                    <div className="col-md-24 requester-tabs">
                        <h4 className="page-title">Test Fee/Charges</h4>
                    </div>
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs defaultActiveKey="past_record"
                            onSelect={this.onTabChange}>
                            <Tab eventKey="past_record" title="Paid">
                                <React.Fragment>
                                    <div className="search-wrapper mt-3">
                                        <label>Search</label>
                                        <div className="search-box clearfix">
                                            {tags && this.renderTags(tags)}
                                            <span className="search-box-icon"></span>
                                            <div className="search-container">
                                                <ul>
                                                    <li>
                                                        <span>Select Date</span>
                                                        <DateRangePicker
                                                            startDate={this.state.show_start_date}
                                                            onApply={this.handleEvent}
                                                            endDate={this.state.show_end_date}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="search-input"
                                                                name="start_date"
                                                                autoComplete="off"
                                                                value={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''}

                                                            ></input>
                                                            <input
                                                                type="text"
                                                                className="search-input"
                                                                name="end_date"
                                                                autoComplete="off"
                                                                value={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''}

                                                            ></input>
                                                        </DateRangePicker>
                                                    </li>
                                                    <li>
                                                        <button type="button" className="btn btn-secondary" onClick={this.resetSearch}>Reset</button>
                                                        <button type="submit" class="btn btn-primary" onClick={this.handleSearch}>Search</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                {data && <div className="content-wrapper">
                                    {dataTable}
                                </div>}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>
        </React.Fragment >)
    }
}

const mapStateToProps = (state) => ({
    prescription_fees: state.prescription,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    paginatePrescriptionFees: PrescriptionAction.getPrescriptionFees
};
export default connect(mapStateToProps, mapActionsToProps)(ListTestFeesPage)