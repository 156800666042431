import React from 'react';

export const Skin = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Skin</h6>
        <input type="checkbox" id='skin-normal' name='skin-normal' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-normal') ? 'checked' : ''} />
        <label htmlFor='skin-normal'>Normal</label>
    </React.Fragment>
}
export const Lesions = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Lesions </label>
            <input type="checkbox" id='skin-lesions-yes' name='skin-lesions-yes' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-lesions-yes') ? 'checked' : ''} />
            <label htmlFor='skin-lesions-yes'>Yes</label>
            <input type="checkbox" id='skin-lesions-no' name='skin-lesions-no' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-lesions-no') ? 'checked' : ''} />
            <label htmlFor='skin-lesions-no'>No</label>
        </div>
    </React.Fragment>
}
export const Rashes = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Rashes </label>
            <input type="checkbox" id='skin-rashes-yes' name='skin-rashes-yes' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-rashes-yes') ? 'checked' : ''} />
            <label htmlFor='skin-rashes-yes'>Yes</label>
            <input type="checkbox" id='skin-rashes-no' name='skin-rashes-no' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-rashes-no') ? 'checked' : ''} />
            <label htmlFor='skin-rashes-no'>No</label>
        </div>
    </React.Fragment>
}
export const Scars = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Scars </label>
            <input type="checkbox" id='skin-scars-yes' name='skin-scars-yes' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-scars-yes') ? 'checked' : ''} />
            <label htmlFor='skin-scars-yes'>Yes</label>
            <input type="checkbox" id='skin-scars-no' name='skin-scars-no' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-scars-no') ? 'checked' : ''} />
            <label htmlFor='skin-scars-no'>No</label>
        </div>
    </React.Fragment>
}
export const Bruising = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Bruising </label>
            <input type="checkbox" id='skin-bruising-yes' name='skin-bruising-yes' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-bruising-yes') ? 'checked' : ''} />
            <label htmlFor='skin-bruising-yes'>Yes</label>
            <input type="checkbox" id='skin-bruising-no' name='skin-bruising-no' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-bruising-no') ? 'checked' : ''} />
            <label htmlFor='skin-bruising-no'>No</label>
        </div>
    </React.Fragment>
}
export const Texture = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Texture </label>
            <input type="checkbox" id='skin-texture-dry' name='skin-texture-dry' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-texture-dry') ? 'checked' : ''} />
            <label htmlFor='skin-texture-dry'>Dry</label>
            <input type="checkbox" id='skin-texture-rough' name='skin-texture-rough' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-texture-rough') ? 'checked' : ''} />
            <label htmlFor='skin-texture-rough'>Rough</label>
            <input type="checkbox" id='skin-texture-flacking' name='skin-texture-flacking' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'skin-texture-flacking') ? 'checked' : ''} />
            <label htmlFor='skin-texture-flacking'>Flacking</label>
        </div>
    </React.Fragment>
}
export const Color = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Color </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'skin', 'skin-color')} value={getProblemComment('skin', 'skin-color') ? getProblemComment('skin', 'skin-color') : ''} disabled={checkIfProblemExist('skin', 'skin-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Moisture = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Moisture </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'skin', 'skin-moisture')} value={getProblemComment('skin', 'skin-moisture') ? getProblemComment('skin', 'skin-moisture') : ''} disabled={checkIfProblemExist('skin', 'skin-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Hair = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Hair </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'skin', 'skin-hair')} value={getProblemComment('skin', 'skin-hair') ? getProblemComment('skin', 'skin-hair') : ''} disabled={checkIfProblemExist('skin', 'skin-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Nails = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Nails </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'skin', 'skin-nails')} value={getProblemComment('skin', 'skin-nails') ? getProblemComment('skin', 'skin-nails') : ''} disabled={checkIfProblemExist('skin', 'skin-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Sensitivity = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Sensitivity </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'skin', 'skin-sensitivity')} value={getProblemComment('skin', 'skin-sensitivity') ? getProblemComment('skin', 'skin-sensitivity') : ''} disabled={checkIfProblemExist('skin', 'skin-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'skin')} disabled={checkIfProblemExist('skin', 'skin-normal') ? 'disabled' : ''} value={getCategoryComment('skin') ? getCategoryComment('skin') : ''}></textarea>
    </React.Fragment>
}