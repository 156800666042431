import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Table, Form } from "react-bootstrap";
import { FLAG } from '../../config';

class TestDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        this.validator = new SimpleReactValidator({
            messages: {
                required: "The result field is required."
            }
        });
    }

    componentDidMount() {
        this.props.onTestDetailsChange(this.state.data);
        this.props.onSampleNosDetailsChange(this.state.data);
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.state;
        if (prevState.data !== data) {
            this.setState({
                data: this.props.testsData,
            })
            this.props.onTestDetailsChange(data);
            this.props.onSampleNosDetailsChange(data);
            this.validator.showMessages();
        }
    }

    isValid = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        return true;
    };

    handleInputChange = (e, rowId) => {
        this.props.onTestDetailsChange(e.target.value, rowId);
    };

    handleOnBlurInput = (e, rowId) => {
        this.props.handleOnBlurTestDetails(e.target.value, rowId);
    };

    handleChangeSampleNos = (e, rowId) => {
        this.props.onSampleNosDetailsChange(e.target.value, rowId);
    };

    handleTestFlagChange = (e, rowId) => {
        this.props.onTestFlagChange(e.target.value, rowId);
    };

    getReferenceRange = (lowerLimit = null, upperLimit = null) => {
        if (lowerLimit && upperLimit) {
            return `${lowerLimit} - ${upperLimit}`;
        } else {
            return "-";
        }
    };

    handleReset = () => {
        this.props.resetValue();
    };

    render() {
        const { testsData } = this.props;
        return (
            <>
                <style>
                    {`
                    .test-details tbody tr td {
                    padding: 10px 30px 10px 5px !important;
                    }
                    `}
                </style>
                <Table className="test-details">
                    <thead>
                        <tr>
                            <th>Test Name</th>
                            <th>Result</th>
                            <th>Reference Range</th>
                            <th>Flag</th>
                            <th>Unit</th>
                            <th>Sample Nos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {testsData &&
                            testsData.map((row) => (
                                <tr key={row.id}>
                                    <td style={{ width: "250px" }}>{row.lims_test.name}</td>
                                    <Form.Control
                                        type="hidden"
                                        value={row.id}
                                        name="test_category"
                                        id={`test_category_${row.lims_test.category}`}
                                    />
                                    <td>

                                        {row.lims_test.type == "select" && (
                                            <>
                                                <Form.Control as="select" onChange={(e) => this.handleInputChange(e, row.id)} name="result" value={row.value} disabled={this.props.disabledValue}>
                                                    <option></option>
                                                    {row.lims_test.alternate_result && row.lims_test.alternate_result.split("/").map(alternate_result => (
                                                        <option value={alternate_result.toLowerCase()} key={alternate_result.toLowerCase()}>{alternate_result}</option>
                                                    ))}
                                                </Form.Control>
                                                {this.validator.message(
                                                    `result_${row.id}`,
                                                    row.value,
                                                    "required"
                                                )}
                                            </>
                                        )}

                                        {row.lims_test.type == "number" && (
                                            <>
                                                <Form.Control
                                                    type="number"
                                                    value={row.value}
                                                    name={`result_${row.id}`}
                                                    id={`result_${row.id}`}
                                                    onChange={(e) => this.handleInputChange(e, row.id)}
                                                    onBlur={(e) => this.handleOnBlurInput(e, row.id)}
                                                    className={`result_${row.id}`}
                                                    disabled={this.props.disabledValue}
                                                />
                                                {this.validator.message(
                                                    `result_${row.id}`,
                                                    row.value,
                                                    "required|numeric"
                                                )}
                                            </>
                                        )}

                                        {row.lims_test.type == "text" && (
                                            <>
                                                <Form.Control
                                                    type="text"
                                                    value={row.value}
                                                    name={`result_${row.id}`}
                                                    id={`result_${row.id}`}
                                                    onChange={(e) => this.handleInputChange(e, row.id)}
                                                    onBlur={(e) => this.handleOnBlurInput(e, row.id)}
                                                    className={`result_${row.id}`}
                                                    disabled={this.props.disabledValue}
                                                />
                                                {this.validator.message(
                                                    `result_${row.id}`,
                                                    row.value,
                                                    "required"
                                                )}
                                            </>
                                        )}

                                        {row.lims_test.type == "alpha-numeric" && (
                                            <>
                                                <Form.Control
                                                    type="text"
                                                    value={row.value}
                                                    name={`result_${row.id}`}
                                                    id={`result_${row.id}`}
                                                    onChange={(e) => this.handleInputChange(e, row.id)}
                                                    onBlur={(e) => this.handleOnBlurInput(e, row.id)}
                                                    className={`result_${row.id}`}
                                                    disabled={this.props.disabledValue}
                                                />
                                                {this.validator.message(
                                                    `result_${row.id}`,
                                                    row.value,
                                                    ['required', { regex: '^[a-zA-Z0-9. ]*$' }],
                                                    { messages: { regex: "Only letters [a-z], numbers [0-9] and space are allowed" } }
                                                )}
                                            </>
                                        )}

                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={this.getReferenceRange(
                                                row.lims_test.lower_limit,
                                                row.lims_test.upper_limit
                                            )}
                                            name={`reference_range_${row.id}`}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control as="select" onChange={(e) => this.handleTestFlagChange(e, row.id)} name="flag" value={row.flag} disabled={this.props.disabledValue}>
                                            <option value=""> </option>
                                            {Object.keys(FLAG).map((key, idx) => {
                                                return <option key={idx} value={key}>{FLAG[key]}</option>
                                            })}
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={row.lims_test.unit_of_measurement}
                                            name={`unit_${row.id}`}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            className="text-capitalize"
                                            value={row.sample_nos}
                                            onChange={(e) => this.handleChangeSampleNos(e, row.id)}
                                            name={`sample_nos_${row.id}`}
                                            disabled={this.props.disabledValue}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </>
        );
    }
}

export default TestDetails;
