import React from "react";
import * as Helper from "../../utils/helpers";
import * as Config from "../../config";
import { capitalize } from 'lodash';
import './styles.css';

class PrintCompletedReportPage extends React.Component {
    constructor(props) {
        super(props);
    }
    getReferenceRange = (lowerLimit = null, upperLimit = null) => {
        if (lowerLimit && upperLimit) {
            return `${lowerLimit} - ${upperLimit}`;
        } else {
            return "-";
        }
    };

    render() {
        const { content } = this.props;
        const testsData = content.testsData;
        const formData = content.formData;
        const serviceProviderAddress = content.serviceProviderAddress;
        const serviceProviderName = content.serviceProviderName;
        const address1 = (serviceProviderAddress && serviceProviderAddress.address_line_1) ? serviceProviderAddress.address_line_1 : "";
        const address2 = (serviceProviderAddress && serviceProviderAddress.address_line_2) ? serviceProviderAddress.address_line_2 : "";
        const address3 = (serviceProviderAddress && serviceProviderAddress.address_line_3) ? serviceProviderAddress.address_line_3 : "";
        const parish = (serviceProviderAddress && serviceProviderAddress.parish) ? serviceProviderAddress.parish : "";
        const city = (serviceProviderAddress && serviceProviderAddress.city) ? serviceProviderAddress.city : "";
        const pincode = (serviceProviderAddress && serviceProviderAddress.pincode) ? serviceProviderAddress.pincode : "";
        return (
            content && <React.Fragment>

                <div>
                    <title>LimsReport</title>
                    <div className="print-wrapper">
                        <div className="print-page">
                            <div className="container">
                                <div className="middleWrapper">
                                    <div class="nhf-header">
                                        <img src="/images/nhf-lims-logo.png" alt="lims-logo" />
                                        <p class="text-right">6th Floor, The Tower, <br />25 Dominica Dr, Kingston<br />8769061106</p>
                                    </div>
                                    <div class="header">
                                        <h2>{serviceProviderName}</h2>
                                        <div class="right">
                                            <p>{address1} {address2} {address3}<br />{parish}, {city} {pincode}</p>
                                        </div>
                                    </div>
                                    <div className="patient-details">
                                        <table>
                                            <tr>
                                                <th>Name</th>
                                                <td>{formData.name}</td>
                                                <th>Phone</th>
                                                <td>{formData.phone}</td>
                                                <th>Age</th>
                                                <td>{formData.age}</td>
                                            </tr>
                                            <tr>
                                                <th>Address</th>
                                                <td>{formData.address}</td>
                                                <th>Doctor</th>
                                                <td>{formData.doctor}</td>
                                                <th>Doctor Contact</th>
                                                <td>{formData.doctor_contact}</td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                                <th>Sample Collected At</th>
                                                <td>{formData.sample_collected_at}</td>
                                                <th>Sample Collected On</th>
                                                <td>{formData.sample_collected_on}</td>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Height <small>[in cm]</small></th>
                                                <td>{formData.patient_height}</td>
                                                <th>Weight <small>[in kgs]</small></th>
                                                <td>{formData.patient_weight}</td>
                                                <th>BMI</th>
                                                <td>{formData.patient_bmi}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="test-details">
                                        <p className="title">Test Details</p>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Test Name</th>
                                                    <th>Result</th>
                                                    <th>Reference Range</th>
                                                    <th>Flag</th>
                                                    <th>Unit</th>
                                                    <th>Sample Nos</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {testsData &&
                                                    testsData.map((row) => (
                                                        <tr>
                                                            <td>{row.lims_test.name}</td>
                                                            <td>{row.lims_test.type == 'select' ? capitalize(row.value) : row.value}</td>
                                                            <td>{this.getReferenceRange(row.lims_test.lower_limit, row.lims_test.upper_limit)}</td>
                                                            <td>{capitalize(row.flag)}</td>
                                                            <td>{row.lims_test.unit_of_measurement ? row.lims_test.unit_of_measurement : "-"}</td>
                                                            <td>{row.sample_nos}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer>
                            <div className="flex">
                                <div className="powererby">
                                    <img src="/images/daisy-logo.png" alt="logo" />
                                    <p>{Helper.getCurrentYear()} &copy; Powered by Daisy-Health</p>
                                </div>
                                <div className="toll-free">
                                    <p>Toll Free No. <span>{Config.TOLL_FREE_NO}</span> </p>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PrintCompletedReportPage;