import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as CompletedReportAction from "../../actions/limsCompletedReportAction";
import DataGrid from "../../components/DataGrid";
import _ from "lodash";
import * as loaderActions from '../../actions/loaderAction';
import ReferralService from "../../services/referralService";
import Notification from "../../utils/notification";
import { hasRole } from '../../utils/auth';
import moment from "moment";
import SimpleReactValidator from 'simple-react-validator';

class CompletedPatientReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: { sortField: "created_at", sortOrder: "desc" },
      reportId: [],
      all_checked: false,
      disabled: true,
      tags: {
        patient_name: "",
        referral_date: "",
      },
      filter: {
        patient_name: "",
        referral_date: "",
      },
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.props.paginate({ sortBy: this.state.sortBy });
  }

  columns = () => {
    return [
      {
        dataField: "select_to_send_mail",
        text: "Select To Send Mail",
        formatter: this.checkBoxFormatter,
        csvExport: false,
        hidden: hasRole(this.props.selected_profile, ["pathology", "data-entry-clerk", "pathologist", "pathology-receptionist", "pathology-phlebotomist"]) ? false : true
      },
      {
        dataField: "created_at",
        text: "Date Received",
      },
      {
        dataField: "name",
        text: "Patient Name",
      },
      {
        dataField: "doctor_name",
        text: "Referred Doctor",
      },
      {
        dataField: "sample_collected_at",
        text: "Sample Collected At",
      },
      {
        dataField: "verified1.name",
        text: "First Verified",
      },
      {
        dataField: "verified2.name",
        text: "Second Verified",
      },
      {
        dataField: "status",
        text: "Status",
        formatter: this.statusFormatter,
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: this.actionFormatter,
        csvExport: false,
        hidden: hasRole(this.props.selected_profile, ["pathology-manager"]) ? true : false
      },
    ];
  };

  actionFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <Link to={"/completed-patient-report/" + row.id} title="View"><span className="icon file"></span></Link>
      </React.Fragment>
    );
  };

  checkBoxFormatter = (cell, row) => {
    if (cell !== "allowed") {
      return (
        <React.Fragment>
          <input
            type="checkbox"
            id={"checkbox" + row.id}
            name="report_id"
            value={row.id}
            onClick={this.handleCheckboxChange}
            checked={this.state.reportId.includes(row.id) ? true : false}
          />
          <label htmlFor={"checkbox" + row.id}></label>
        </React.Fragment>
      );
    }
  };

  handleCheckboxChange = (e) => {
    if (e.target.name === "report_id") {
      if (e.target.checked) {
        this.setState({
          reportId: this.state.reportId.concat(parseInt(e.target.value)),
        });
      } else {
        const reportId = this.state.reportId.filter(function (item) {
          return item !== parseInt(e.target.value);
        });
        this.setState({ reportId: reportId });
      }
    }
  };

  statusFormatter = (row) => {
    if (_.isEmpty(row)) {
      return "NA";
    } else {
      return _.capitalize(row);
    }
  };

  sendMail = (e, user) => {
    this.props.loaderShow();
    e.preventDefault();
    let referralMails = { 'referral_ids': this.state.reportId ? this.state.reportId : [] };
    ReferralService.sendReferralMail(referralMails, user).then(resp => {
      this.setState({ reportId: [] });
      this.props.loaderHide();
      Notification.show("success", { message: 'Mail Sent' });
    }).catch(error => {
      this.setState({ reportId: [] });
      this.props.loaderHide();
      Notification.show("error", error.response.data);
    });
  }

  sendMailComponent = () => {
    let disabledValue = (this.state.reportId && (this.state.reportId).length) ? false : true;
    return (
      <>
        <button
          type="button"
          onClick={(e) => this.sendMail(e, 'doctor')}
          className="btn btn-primary"
          disabled={disabledValue}
        >
          Send Mail To Doctor
        </button>
        <button
          type="button"
          onClick={(e) => this.sendMail(e, 'patient')}
          className="btn btn-primary m-2"
          disabled={disabledValue}
        >
          Send Mail To Patient
        </button>
      </>
    );
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText, filter }) => {
    if (type === "search") {
      page = 1;
    }

    let sortBy = sortField ? { sortField, sortOrder } : this.state.sortBy;
    filter = filter ? filter : this.state.filter;

    this.props.paginate({ page, sizePerPage, sortBy, searchText, filter });
  };

  removeFilter = (e) => {
    const filter = this.state.filter;
    const tags = this.state.tags;

    filter[e.target.dataset.tag] = "";
    tags[e.target.dataset.tag] = "";

    this.setState({ filter: filter, tags: tags });
    this.props.paginate({ sortBy: this.state.sortBy, filter });
  };

  renderTags(tags) {
    let options;
    return Object.keys(tags).map((tag) => {
      if (tags[tag] !== "" && !_.isEmpty(tags[tag])) {
        options = (
          <span className="search-list">
            {tags[tag]}{" "}
            <span
              className="removesearch"
              data-tag={tag}
              onClick={this.removeFilter.bind(this)}
            >
              {" "}
              x{" "}
            </span>{" "}
          </span>
        );
      } else {
        return false;
      }
      return options;
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    window['searchToggle']();

    this.setState({
      tags: {
        patient_name: this.state.filter.patient_name,
        referral_date: this.state.filter.referral_date,
      }
    });
    this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
  };

  handleChange = (e) => {
    const filter = this.state.filter;
    filter[e.target.name] = e.target.value ? e.target.value : undefined;
    this.setState({ filter });
  };

  resetSearch = () => {
    this.setState({
      filter: {
        patient_name: "",
        referral_date: "",
      },
      tags: {
        patient_name: "",
        referral_date: "",
      },
    });

    window["searchToggle"]();

    this.props.paginate({ sortBy: this.state.sortBy });
  };

  filterComponent = (tags) => {
    return (
      <div className="search-wrapper">
        <label>Search</label>
        <div className="search-box clearfix">
          {tags && this.renderTags(tags)}
          <span className="search-box-icon"></span>
          <div className="search-container">
            <form onSubmit={this.handleSubmit}>
              <ul>
                <li>
                  <span>Patient Name</span>
                  <input
                    type="text"
                    value={this.state.filter.patient_name}
                    onChange={this.handleChange}
                    name="patient_name"
                    autoComplete="off"
                  ></input>
                  {this.validator.message('patient_name', this.state.filter.patient_name, 'alpha_num_space')}

                </li>
                <li>
                  <span>Date Received</span>
                  <input
                    type="date"
                    value={this.state.filter.referral_date}
                    onChange={this.handleChange}
                    name="referral_date"
                    autoComplete="off"
                    max={moment().format('YYYY-MM-DD')}
                  ></input>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={this.resetSearch}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  };

  handleExportCSV = () => {
    ReferralService.exportCompletedReferrals({ sortBy: this.state.sortBy, filter: this.state.filter }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "CompletedReport.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  render() {
    let { data, meta } = this.props.lims_completed_report;
    return (
      <React.Fragment>
        <Helmet>
          <title>Completed Patient Report</title>
        </Helmet>
        <div className="row p-3">
          <div className="col-md-12">
            <h4 className="page-title">Completed Patient Report</h4>
          </div>
          {hasRole(this.props.selected_profile, ["pathology", "data-entry-clerk", "pathologist", "pathology-receptionist", "pathology-phlebotomist"]) &&
            <div className="col-md-12">
              <div className="text-right">{this.sendMailComponent()}</div>
              {this.filterComponent(this.state.tags)}
            </div>}
        </div>
        <div className="content-wrapper">
          {hasRole(this.props.selected_profile, ["pathology", "pathologist", "pathology-manager"]) &&
            <button type="button" className="btn btn-primary" onClick={this.handleExportCSV}>Export CSV</button>
          }
          <DataGrid
            columns={this.columns()}
            data={data}
            meta={meta}
            handleTableChange={this.handleTableChange}
            noDataIndication="No Record Found"
            selectRow={this.selectRow}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selected_profile: state.selected_user_profile,
  lims_completed_report: state.lims_completed_report
});

const mapActionsToProps = {
  paginate: CompletedReportAction.list,
  loaderShow: loaderActions.loaderShow,
  loaderHide: loaderActions.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(CompletedPatientReport);
