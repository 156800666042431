import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import LimsTestService from '../../services/limsTestService';
import { pathologyLimsTestAdd } from '../../actions/limsTestAction';
import Button from 'react-bootstrap/Button';

class EditPatientPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            category: '',
            lower_limit: '',
            upper_limit: '',
            unit_of_measurement: '',
            alternate_result: '',
            tests: [],
            selectedCategory: '',
        };

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        LimsTestService.listAll().then(resp => {
            this.setState({
                tests: _.groupBy(resp.data.data, 'category')
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    onChange = async (event) => {
        const { name, value } = event.target;
        if (value === '') {
            await this.setState({
                [name]: value,
                selectedCategory: null
            });
            return;
        }
        await this.setState({ [name]: value });
        if (name === 'category') {
            let data = _.filter(this.state.tests, function (test, index) {
                return index === value
            });

            const uniqueTests = _.uniqBy(data[0], 'name');

            this.setState({
                selectedCategory: [uniqueTests]
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const postData = {
            name: this.state.name,
            category: this.state.category,
            lower_limit: this.state.lower_limit,
            upper_limit: this.state.upper_limit,
            unit_of_measurement: this.state.unit_of_measurement,
            alternate_result: this.state.alternate_result,

        };
        this.props.pathologyLimsTestAdd(postData, this.props.history);
    }

    render() {
        this.validator.purgeFields();
        return (<div>
            <Helmet>
                <title>Add Lims Test Limits</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <div className='page-title'>
                        <span>Add Lims Test Limits</span>
                    </div>
                    <div className="content-wrapper edit-patient">
                        <div>
                            <div className="row">
                                {this.state.tests &&
                                    <div className="col-sm-8">
                                        <fieldset className="form-group">
                                            <label htmlFor="category">Test Category <span>*</span></label>
                                            <select className="form-control" value={this.state.category} id="category" name="category" onChange={this.onChange}>
                                                <option value="">Select Category</option>
                                                {this.state.tests && Object.keys(this.state.tests).map((key, index) => (
                                                    <option key={index}>
                                                        {key}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.validator.message('category', this.state.category, 'required')}
                                        </fieldset>
                                    </div>
                                }
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="name">Test Name <span>*</span></label>
                                        <select className="form-control" value={this.state.name} id="name" name="name" onChange={this.onChange}>
                                            <option value="">Select Name</option>
                                            {this.state.selectedCategory && this.state.selectedCategory[0].map((test) => {
                                                return <option key={test.id} value={test.name}>{test.name}</option>
                                            })}
                                        </select>
                                        {this.validator.message('name', this.state.name, 'required')}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="lower_limit">Lower Limit</label>
                                        <input type="text" className="form-control" value={this.state.lower_limit ? this.state.lower_limit : ''} id="lower_limit" name="lower_limit" onChange={this.onChange} />
                                        {this.validator.message('lower_limit', this.state.lower_limit, 'numeric')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="upper_limit">Upper Limit</label>
                                        <input type="text" className="form-control" value={this.state.upper_limit ? this.state.upper_limit : ''} id="upper_limit" name="upper_limit" onChange={this.onChange} />
                                        {this.validator.message('upper_limit', this.state.upper_limit, 'numeric')}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="unit_of_measurement">Unit of Measurement</label>
                                        <input type="text" className="form-control" value={this.state.unit_of_measurement ? this.state.unit_of_measurement : ''} id="unit_of_measurement" name="unit_of_measurement" onChange={this.onChange} />
                                        {this.validator.message('unit_of_measurement', this.state.unit_of_measurement, 'string')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="alternate_result">Alternate Result</label>
                                        <input type="text" className="form-control" value={this.state.alternate_result ? this.state.alternate_result : ''} id="alternate_result" name="alternate_result" onChange={this.onChange} />
                                        {this.validator.message('alternate_result', this.state.alternate_result, 'string')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-24">
                                    <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                                    <Link to="/pathology-lims-tests"><Button variant="secondary">Cancel</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert,
});

const mapActionsToProps = {
    pathologyLimsTestAdd: pathologyLimsTestAdd,
};

export default connect(mapStateToProps, mapActionsToProps)(EditPatientPage);
