import React from "react";
import Notification from '../utils/notification';
import PrescriptionService from '../services/prescriptionService';
import LimsPatientFeeCollectionModal from "./LimsPatientFeeCollectionModal";

class LimsPatientCollectFeeButton extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			showCollectFeeModal: this.props.showCollectFeeModal ? this.props.showCollectFeeModal : false,
		}
		this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.showCollectFeeModal = this.showCollectFeeModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
		this.props.showCollectFeeModal && this.showCollectFeeModal()
	}

	getPatientFeeInfo = () => {
		PrescriptionService.getPatientFeeInfo(this.props.patientId, "lims").then(resp => {
			this.setState({ savedPatientFeeInfo:resp.data })
		}).catch(error => {
			Notification.show('error', error.response.data)
		})
    }

	onSuccessRedirect() {
        this.setState({ showCollectFeeModal: false });
	}
	
	showCollectFeeModal() {
		this.getPatientFeeInfo();
        this.setState({ showCollectFeeModal: true });
	}
	
	handleClose() {
        this.setState({ showCollectFeeModal: false });
	}

    render() {

        return (<React.Fragment>
			<button className="btn btn-outline-primary ml-2" onClick={e => this.showCollectFeeModal()}>{this.props.title}</button>
			<LimsPatientFeeCollectionModal
				onSuccess={this.onSuccessRedirect}
				show={this.state.showCollectFeeModal}
				handleClose={this.handleClose}
				patientId={this.props.patientId}
				patientFeeInfo={this.state.savedPatientFeeInfo}
			/>
        </React.Fragment>)
    }
}

export default LimsPatientCollectFeeButton;