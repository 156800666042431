import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class LimsTestService {
    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/lims-test/prices-list"), requestOptions);
    }

    static create(data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/lims-test/prices'), JSON.stringify(data), requestOptions);
    }

    static get(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/lims-test/prices/' + id), requestOptions);
    }

    static update(id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/lims-test/prices/' + id), JSON.stringify(data), requestOptions);
    }

    static delete(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.delete(apiRoute('/v1/lims-test/prices/' + id), requestOptions);
    }

    static listAll() {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/lims-test-list'), requestOptions);
    }

    static limsList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/lims-test/list"), requestOptions);
    }

    static fetchPathologyLimsTest(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/pathology/limstests/' + id), requestOptions);

    }

    static pathologyLimsTestUpdate(id, postData) {
        let paramsdata = JSON.stringify(postData);
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.put(apiRoute('/v1/pathology/limstests/' + id), paramsdata, requestOptions);
    }
    
    static pathologyLimsTestAdd(statedata) {
        let paramsdata = JSON.stringify(statedata);

        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/pathology/limstests-limits'), paramsdata, requestOptions);
    }

    static listActiveCategories() {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/lims-categories'), requestOptions);
    }

    static limsMasterTestList({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/lims-master-tests"), requestOptions);
    }

    static downloadPathologyLimsTest() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/lims-test/download'), {}, requestOptions);
    }

    static updatePathologyLimsTest(statedata) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/lims-test/bulk-update'), statedata, requestOptions);
    }

    static downloadPathologyLimsTestPrice() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/lims-test/prices-list/download'), {}, requestOptions);
    }

    static updatePathologyLimsTestPrice(statedata) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute('/v1/lims-test/prices-list/bulk-update'), statedata, requestOptions);
    }
}


export default LimsTestService;
