import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import _ from 'lodash';
import { hasRole } from '../utils/auth';
import DataGrid from '../components/DataGrid';
import * as UserActivityAction from '../actions/userActivityAction'
import * as Config from '../config/index';
import * as Helper from '../utils/helpers';
import ActivityLogFilter from '../components/ActivityLogFilter';
import UserActivityService from '../services/userActivityService';
import Notification from '../utils/notification'

class UserActivityLogPage extends React.Component {
    constructor(props) {
        super(props);

        const allowRoles = ['doctor', 'pharmacy', 'radiology', 'pathology', 'pathology-manager', 'pathologist', 'associate-doctor', 'pharmacy-manager', 'covid-center-owner', "airline-owner", "medical-practice-manager"];

        this.state = {
            allowRoles: allowRoles,
            filter: {
                role_slug: '',
                start_date: "",
                end_date: "",
                events: ''
            }
        }
    }

    columns = () => {
        let columns = [{
            dataField: 'timestamp',
            text: 'Timestamp',
            formatter: this.dateTimeFormatter,
            sort: false
        }, {
            dataField: 'actor_name',
            text: 'Staff Name',
        }, {
            dataField: 'event_label',
            text: 'Action',
            sort: false
        }, {
            dataField: 'ip_address',
            text: 'IP address',
            sort: false
        }];

        if (hasRole(this.props.selected_user_profile, ["covid-center-owner", "airline-owner"])) {
            columns.splice(2, 0, { dataField: 'entity_name', text: 'Patient Name' });
        }
        return columns;
    }

    dateTimeFormatter = cell => {
        return Helper.getDateTimeFormat(cell, 'DD-MM-YYYY HH:mm:ss')
    }

    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    renderRoleSlug = () => {
        const { service_provider_type } = this.props.selected_user_profile.service_provider;
        return (Config.roles[service_provider_type].map(function (key, index) {
            return <option value={key.slug} key={index}>
                {key.name}
            </option>;
        }));
    }

    handleChange = e => {
        let { filter } = this.state;
        filter[e.target.name] = e.target.value;
        this.setState({ filter: filter })
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: filter });
    }

    handleFilter = data => {
        let { filter } = this.state

        Object.keys(data).forEach((key, _index) => {
            filter[key] = data[key]
        });

        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: filter });
        this.setState({
            filter: filter
        })
    }

    handleExport = () => {
        UserActivityService.export({ filter: this.state.filter }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "ActivityLog.csv")
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    render() {
        const { data, meta } = this.props.user_activities
        const { selected_user_profile } = this.props;
        const hasRoles = ['doctor', 'pharmacy', 'pathology', 'pathology-manager', 'pathologist', 'radiology', 'covid-center-owner', "airline-owner"]
        return (
            <React.Fragment>
                <Helmet>
                    <title>Activity Logs</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">{hasRole(selected_user_profile, this.state.allowRoles) ? "Staff Activity Log" : "Activity Log"}</h4>
                        {hasRole(selected_user_profile, this.state.allowRoles) && <div className="content-wrapper add-user-section">
                            <div className="select-location">
                                <label>Select Staff</label>
                                <select className="form-control" name="role_slug" onChange={this.handleChange}>
                                    <option value="">Select Staff</option>
                                    <option value={selected_user_profile.role_slug}> {hasRole(selected_user_profile, hasRoles) ? 'Self' : _.capitalize(selected_user_profile.service_provider.service_provider_type)}</option>
                                    {this.renderRoleSlug()}
                                </select>
                            </div>
                        </div>}
                    </div>
                    <div className="col-md-24">
                        {hasRole(selected_user_profile, this.state.allowRoles) && <button type="button" className="btn btn-secondary" onClick={this.handleExport}>Export</button>}
                        {hasRole(selected_user_profile, this.state.allowRoles) &&
                            <ActivityLogFilter
                                handleFilter={this.handleFilter}
                                handleExport={this.handleExport}
                                selected_user_profile={selected_user_profile} />}
                    </div>
                </div>
                <div className="content-wrapper">
                    {data && <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                        noDataIndication="No Record Found"

                    />}
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    selected_user_profile: state.selected_user_profile,
    user_activities: state.user_activities
});

const mapActionsToProps = ({
    paginate: UserActivityAction.userActivities
})
export default connect(mapStateToProps, mapActionsToProps)(UserActivityLogPage)