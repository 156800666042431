import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notification from '../utils/notification';
import PrescriptionService from '../services/prescriptionService';
import LimsTestService from '../services/limsTestService';
import CurrencyService from '../services/currencyService';
import { isEmpty, find } from 'lodash';
import { getDateTimeFormat } from '../utils/helpers';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

class LimsPatientFeeCollectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            payment_insurance_amount: "0",
            payment_cash_amount: "0",
            payment_card_amount: "0",
            payment_online_amount: "0",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            limsTests: "",
            selectedLimsTests: [{ id: "", name: "", price: 0 }],
            currencies: "",
            currency: {},
            defaultCurrency: 1,
            balance: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show == true) {
            this.getLimsTestList();
            this.getCurrencyList();
        }
    }

    getLimsTestList = () => {
        LimsTestService.listAll().then(resp => {
            this.setState({
                limsTests: resp.data.data,
            });
        }).catch(error => {
            this.setState({
                buttonText: 'Submit'
            });
            Notification.show('error', error.response.data)
        })
    }

    getCurrencyList = () => {
        CurrencyService.listAll().then(resp => {
            this.setState({
                currencies: resp.data.data,
            }, () => {
                this.setDefaultCurrency();
            });
        }).catch(error => {
            this.setState({
                buttonText: 'Submit'
            });
            Notification.show('error', error.response.data)
        })
    }

    setDefaultCurrency = () => {
        let currency = this.state.currencies.filter((currency) => currency.id == this.state.defaultCurrency);
        this.setState({ currency: currency[0] });
    }

    handleSelectCurrency = (e) => {
        let currency = this.state.currencies.filter((currency) => currency.id == e.target.value)
        this.setState({ currency: currency[0] }, () => {
            this.changePrice();
        });
    };

    changePrice = () => {
            let limsTests = this.state.selectedLimsTests.map(limsTest => {
                if (limsTest.id != "") {
                    let limsTestValue = this.state.limsTests.filter((p) => p.id == limsTest.id)
                    let currencyPrice = limsTestValue[0].prices.filter((price) => price.currency.id == this.state.currency.id)
                    let newPrice = currencyPrice[0].price;
                    return { id: limsTest.id, name: limsTest.name, price: newPrice }
                }
                else {
                    return limsTest;
                }
            });
            this.setState({ selectedLimsTests: limsTests }, () => {
                this.handleTotalFees();
            });
    };

    handleSelectLimsTest = (e, idx) => {
        const currency = this.state.currency.id;
        const value = e.value;
        let selectedLimsTests = this.state.selectedLimsTests;
        if (value > 0) {
            let limsTest = find(this.state.limsTests, { 'id': value });
            var p_price = limsTest.prices.filter((price) => price.currency.id == currency)
            if(isEmpty(p_price)){
                Notification.show('error', { message: 'Price doesn\'t exist, select different test/currency.' })
            }
            limsTest = !isEmpty(p_price) ? { id: limsTest.id, name: limsTest.name, price: p_price[0].price } : { id: '', name: '', price: 0 }
            selectedLimsTests[idx] = limsTest;
        } else {
            selectedLimsTests[idx] = { id: "", name: "", price: 0 };
        }
        this.setState({ selectedLimsTests: selectedLimsTests }, () => {
            this.handleTotalFees();
        })
    }
    feeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.setTotalBalance();
        });
        if (e.target.value === 'paid') {
            this.setState({
                show: true,
                buttonText: 'Submit',
            });
        } else if (e.target.value === 'unpaid') {
            this.setState({
                show: false,
                buttonText: 'Send',
            });
        }
    }

    setTotalBalance = () => {
        let payment_insurance_amount = this.state.payment_insurance_amount ? parseFloat(this.state.payment_insurance_amount) : 0;
        let payment_cash_amount = this.state.payment_cash_amount ? parseFloat(this.state.payment_cash_amount) : 0;
        let payment_card_amount = this.state.payment_card_amount ? parseFloat(this.state.payment_card_amount) : 0;
        let payment_online_amount = this.state.payment_online_amount ? parseFloat(this.state.payment_online_amount) : 0;
        this.setState({
            'balance': (this.state.total_payment - (payment_insurance_amount + payment_cash_amount + payment_card_amount + payment_online_amount)).toFixed(2)
        });
    }

    handleTotalFees = () => {
        let limsTestFee;

        limsTestFee = this.state.selectedLimsTests.reduce((total, obj) => parseFloat(obj.price) + parseFloat(total), 0)

        this.setState({
            total_payment: limsTestFee.toFixed(2)
        }, () => {
            this.setTotalBalance();
         })
    }

    handleClose = () => {
        this.props.handleClose();
        this.validator.hideMessages();
        this.setState({
            status: "",
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: "",
            payment_online_amount: "",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            currency: "",
            selectedLimsTests: [{ id: "", name: "", price: 0 }]
        });
    }

    handleSubmit = (patient_id, e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let limsTestData = this.state.selectedLimsTests.filter((limsTest) => limsTest.id != "")

        const data = {
            payment_insurance_amount: this.state.payment_insurance_amount ? this.state.payment_insurance_amount : null,
            payment_cash_amount: this.state.payment_cash_amount ? this.state.payment_cash_amount : null,
            payment_card_amount: this.state.payment_card_amount ? this.state.payment_card_amount : null,
            payment_online_amount: this.state.payment_online_amount ? this.state.payment_online_amount : null,
            status: this.state.status,
            payment_remark: this.state.payment_remark ? this.state.payment_remark : null,
            metadata: limsTestData,
            currency_id: this.state.currency ? this.state.currency.id : null,
            type: "lims",
        }
        let message = this.state.status === 'paid' ? 'Payment done.' : 'Sent to receptionist/nurse'
        PrescriptionService.createPatientFeeInfo(patient_id, data).then(resp => {
            Notification.show('success', { message: message });
            this.props.onSuccess(this.props);
            this.handleClose()
        }).catch(error => {
            this.setState({
                buttonText: 'Send'
            });
            Notification.show('error', error.response.data)
        })
    }

    handleAddMoreLimsTest = () => {
        this.setState({
            selectedLimsTests: this.state.selectedLimsTests.concat({
                id: '',
                name: '',
                price: 0
            })
        });
    }

    handleRemoveLimsTest = (event) => {
        this.validator.hideMessages();
        const idx = Number(event.target.dataset["id"]);
        const selectedLimsTests = this.state.selectedLimsTests.filter((s, sidx) => idx !== sidx);
        this.setState({ selectedLimsTests: selectedLimsTests }, () => {
            this.handleTotalFees()
        });
    };

    render() {
        this.validator.purgeFields();
        let { limsTests } = this.state
        let options = []
        limsTests && limsTests.map( limsTest => {
            options.push({ 'value': limsTest.id, 'label': limsTest.name })
        })
        return (
            <React.Fragment>
                <Modal
                    size='xl' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>Fee Collection</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <ul className="fee_collection_form">
                                <li>Select Currency: <span>
                                    <select name="currency" className="form-control" onChange={this.handleSelectCurrency} id="currency">
                                        <option value="0" disabled>Select Currency</option>
                                        {this.state.currencies && this.state.currencies.map(currency => (
                                            <option key={currency.id} value={currency.id}>{currency.name}</option>
                                        ))}
                                    </select>
                                    {this.validator.message('Currency', this.state.currency, 'required')}
                                </span>
                                </li> <br />

                                <div>
                                    {this.state.selectedLimsTests.map((selectedLimsTest, idx) => (
                                        <div className='w-100' key={idx}>
                                            <li>Select Test: <span>
                                                <Select className="basic-single" name="limsTestName" options={options}
                                                    value={selectedLimsTest.id ? options.find(obj => selectedLimsTest.id == obj.value) : ''}
                                                    onChange={e => this.handleSelectLimsTest(e, idx)}>
                                                </Select>
                                                {this.validator.message('Test', selectedLimsTest.id, 'required')}
                                            </span>
                                            </li>
                                            <li>Test Cost: <span>{selectedLimsTest.price ? ((this.state.currency ? (this.state.currency.name + ' ' + this.state.currency.sign + ' ') : ' ') + selectedLimsTest.price) : ""}</span></li>
                                            {idx !== 0 && <a href className="cancel" data-id={idx} onClick={this.handleRemoveLimsTest} > </a>}
                                        </div>
                                    ))
                                    }

                                    <li className='w-100'>
                                        <button className="btn btn-primary float-right" type="button" onClick={this.handleAddMoreLimsTest}>Add More</button>
                                    </li>
                                </div>

                                <li>Total to be collected: <span>{this.state.total_payment}</span></li>

                                <li>Status: <span>
                                    <input type="radio" id="paid" name="status" value="paid" className="form-control" onChange={this.feeHandler} />
                                    <label htmlFor="paid">Collect now</label>
                                    </span>
                                    {this.validator.message('Status', this.state.status, 'required')}
                                </li>

                                {this.state.show && <li>From Insurance: <span><input type="number" name="payment_insurance_amount" className="form-control" onChange={this.feeHandler} value={this.state.payment_insurance_amount}/></span>
                                    {this.validator.message('From Insurance', this.state.payment_insurance_amount, 'required|numeric|min:0,num|max:10')}
                                </li>}

                                {this.state.show && <li>From Card: <span><input type="number" name="payment_card_amount" className="form-control" onChange={this.feeHandler} value={this.state.payment_card_amount}/></span>
                                    {this.validator.message('From Card', this.state.payment_card_amount, 'required|numeric|min:0,num|max:10')}
                                </li>}

                                {this.state.show && <li>From Cash: <span><input type="number" name="payment_cash_amount" className="form-control" onChange={this.feeHandler} value={this.state.payment_cash_amount}/></span>
                                    {this.validator.message('From Cash', this.state.payment_cash_amount, 'required|numeric|min:0,num|max:10')}
                                </li>}

                                {this.state.show && <li>From Online: <span><input type="number" name="payment_online_amount" className="form-control" onChange={this.feeHandler} value={this.state.payment_online_amount}/></span>
                                    {this.validator.message('From Online', this.state.payment_online_amount, 'required|numeric|min:0,num|max:10')}
                                </li>}

                                {this.state.show && <li>Balance: {this.state.balance}</li>}

                                {this.state.show && <li className="full-width">Remark: <span>
                                    <textarea name="payment_remark" className="form-control" onChange={this.feeHandler} /></span>
                                    {this.validator.message('Remark', this.state.payment_remark, 'alpha_num_dash_space')}
                                </li>}

                                <li>{this.validator.message('Balance', this.state.balance, 'min:0,num', {messages: {
                                        min: 'Collected amount should not be exceed the Total amount.',
                                        },  className: 'srv-validation-message-custom'})}</li>

                            </ul>
                        </Form>
                        <div className='c-scroll'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date and Time</th>
                                        <th>Collected By</th>
                                        <th>Currency</th>
                                        <th>Test Fee</th>
                                        <th>Insurance</th>
                                        <th>Card</th>
                                        <th>Cash</th>
                                        <th>Online</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.patientFeeInfo && !isEmpty(this.props.patientFeeInfo.data) ? this.props.patientFeeInfo.data.map((row, index) =>
                                        <tr key={index}>
                                            <td>{getDateTimeFormat(row.created_at)}</td>
                                            <td>{row.collected_by}</td>
                                            <td>{row.currency.name}</td>
                                            <td>{row.lims_test_fee && row.lims_test_fee}</td>
                                            <td>{row.from_insurance}</td>
                                            <td>{row.payment_card_amount}</td>
                                            <td>{row.cash_collected}</td>
                                            <td>{row.payment_online_amount}</td>
                                            <td>{row.balance}</td>
                                        </tr>
                                    ) : <tr><td colSpan={8}>No Record Found</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={e => this.handleSubmit(this.props.patientId, e)}>
                            {this.state.buttonText}
                        </Button>
                        <Button variant="secondary" disabled={this.state.isSubmitDisabled} onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default LimsPatientFeeCollectionModal
