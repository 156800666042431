import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import _ from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import * as LoaderAction from '../../actions/loaderAction';
import * as PatientAction from '../../actions/patientAction';
import * as PatientActivityAction from '../../actions/patientActivityLog';

class ListPatientPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: {
                username: ""
            },
            tags: {
                username: ""
            }
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.paginate({ sortBy: this.state.sortBy });
    }

    componentWillUnmount() {
        this.props.resetPaginate();
    }

    renderArrayString = (data) => {
        if (data) {
            const dataArray = Object.entries(data);
            return dataArray.map(([key, value]) => `${key}: ${value}`).join(', ');
        } else {
            return "";
        }
    };

    columns = () => {
        return [
            {
                dataField: "username",
                text: "User Name"
            },
            {
                dataField: 'updated_field',
                text: 'Updated Fields'
            },
            {
                dataField: "old_info",
                text: "Old Info",
                formatter: (cell) => this.renderArrayString(cell)
            },
            {
                dataField: "current_info",
                text: "Current Info",
                formatter: (cell) => this.renderArrayString(cell)
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                formatter: this.dateFormatter
            }
        ];
    };

    dateFormatter = (cell) => {
        if (cell) {
            return Helper.getDateFormat(cell);
        }
        return '--'
    }

    handleChange(e) {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        if (sortField === 'updated_at')
            sortField = 'updated_at';
        const filter = this.state.filter;

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        window['searchToggle']();

        this.setState({
            tags: {
                username: this.state.filter.username,
            }
        });

        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    resetSearch = () => {
        this.setState({
            filter: {
                username: ''
            },
            tags: {
                username: ''
            }
        });

        window['searchToggle']();

        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;

        filter[e.target.dataset.tag] = '';
        tags[e.target.dataset.tag] = '';

        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    render() {
        const { data, meta, isLoading } = this.props.patient_list;
        let tags = this.state.tags;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Activity Log</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Activity Log</h4>
                        {/* <div className="search-wrapper">
                            <label>Search</label>
                            <div className="search-box clearfix">
                                {(Object.keys(tags)).map((tag) => {
                                    if (tag !== 'doctor_id' && tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                                        return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                                    }
                                    return '';
                                })
                                }
                                <span className="search-box-icon" ref={this.searchBox}></span>

                                <div className="search-container">
                                    <form onSubmit={this.handleSubmit}>
                                        <ul>
                                            <li>
                                                <span>User Name</span>
                                                <input type="text" value={this.state.filter.username} onChange={this.handleChange} name="username"></input>
                                                {this.validator.message('username', this.state.filter.username, 'alpha_num_space')}
                                            </li>                                          
                                            <li>
                                                <button type="button" onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                                                <button type="submit" className="btn btn-primary">Search</button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        loading={isLoading}
                        columns={this.columns()}
                        noDataIndication="No Record Found"
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>               
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    patient_list: state.patient_list,
});

const mapActionsToProps = {
    paginate: PatientActivityAction.patientActivityLog,
    resetPaginate: PatientAction.reset,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};

export default connect(mapStateToProps, mapActionsToProps)(ListPatientPage);
