import React from 'react';
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import { Link } from 'react-router-dom';
import { getLimsTestPriceList } from '../../actions/limsTestAction';
import { connect } from 'react-redux';
import { getDateFormat } from '../../utils/helpers'
import Notification from '../../utils/notification';
import LimsTestService from '../../services/limsTestService';
import LimsTestPriceModal from '../../components/Lims/LimsTestPriceModal';
import DeleteLimsTestPriceModal from '../../components/Lims/DeleteLimsTestPriceModal'
import { isEmpty } from 'lodash';

class ListLimsTestPricePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            showAddModal: false,
            limsTest: "",
            showAddEditModal: false,
            showDeleteModal: false,
            id: 0
        }
    }
    componentDidMount() {
        this.getLimsTestPriceList();
    }

    getLimsTestPriceList = () => {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    columns = () => {
        return [
            {
                dataField: "id",
                text: "Id",
                hidden: true,
            },
            {
                dataField: "created_at",
                text: "Date Added",
                formatter: (cell, row) => getDateFormat(cell)
            },
            {
                dataField: "name",
                text: "Test Name"
            },
            {
                dataField: "price_jmd",
                text: "Price in JMD",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'JMD')
            },
            {
                dataField: "price_usd",
                text: "Price in USD",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'USD')
            },
            {
                dataField: "price_cad",
                text: "Price in CAD",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'CAD')
            },
            {
                dataField: "price_gbp",
                text: "Price in GBP",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'GBP')
            },
            {
                dataField: "price_eur",
                text: "Price in EUR",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'EUR')
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    priceFormatter = (row, currency) => {
        if( !isEmpty(row.prices) ){
            let data = row.prices.filter( (items) => items.currency.name === currency )
            return data[0] ? data[0].price : ''
        }
        return ''
    }

    actionFormatter = (cell, row) => {
        if( !isEmpty(row.prices) ){
            return <>
                <a onClick={ () => this.handleModal(row)} title="Edit">
                    <span className="edit-icon"></span>
                </a>
                
                {/* <a className='ml-4' onClick={() => this.handleDeleteModal(row.id)} title="Delete">
                    <span className="delete-icon"></span>
                </a> */}
            </>
        }
        return ''
    }

    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal, id })
    }

    handleDelete = (id) => {
        let message = 'Lims Test Price Deleted';
        LimsTestService.delete(id).then(resp => {
            Notification.show('success', { message: message });
            this.getLimsTestPriceList()
            this.handleDeleteModal()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleModal = (row) => {
        this.handleShow()
        if(row === "add"){
            this.setState({ showAddModal: true});
        }else{
            this.setState({ limsTest: row, showEditModal: true});
        }
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, });
    }

	onSuccessRedirect = () => {
        this.getLimsTestPriceList()
        this.handleClose()
	}
	
	handleClose = () => {
        this.setState({ showAddEditModal: false, limsTest: "" });
        this.handleHideModal()
	}
	
	handleShow = () => {
        this.setState({ showAddEditModal: true });
	}

    handleHideModal = () => {
        this.setState({ showAddModal: false, showEditModal: false});
    }

    render() {
        let { data, meta } = this.props.limsTestPriceList
        return (
            <React.Fragment>
                <Helmet>
                    <title>LIMS Test Price</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-24">
                        <div className='page-title'>
                            <span>LIMS Test Price</span>
                            <Link to="/pathology/lims-test/prices/upload" className="btn btn-outline-primary">UPLOAD</Link>
                            <button onClick={() => this.handleModal("add")} className="float-right btn btn-outline-primary">Add LIMS Test Price</button>
                        </div>
                        <div className="content-wrapper">
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                noDataIndication="No Record Found"
                                pagination={true}
                            />
                        </div>
                    </div>

                {this.state.showEditModal && <LimsTestPriceModal
                    show={this.state.showAddEditModal}
                    onSuccess={this.onSuccessRedirect}
                    handleClose={this.handleClose}
                    buttonText={"Update"}
                    limsTestPrice={this.state.limsTest}
                />}
                {this.state.showAddModal && <LimsTestPriceModal
                    show={this.state.showAddEditModal}
                    onSuccess={this.onSuccessRedirect}
                    handleClose={this.handleClose}
                    buttonText={"Submit"}
                />}
                <DeleteLimsTestPriceModal showModal={this.state.showDeleteModal}
                handleDelete={this.handleDelete}
                id={this.state.id}
                handleClose={this.handleDeleteModal} />
                </div>

                
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    limsTestPriceList: state.lims_test_list,
});

const mapActionsToProps = {
    paginate: getLimsTestPriceList
};
export default connect(mapStateToProps, mapActionsToProps)(ListLimsTestPricePage)