import React from 'react';
import _ from "lodash";
import DataGrid from "../../components/DataGrid";
import { getMasterLimsTestList } from '../../actions/limsTestAction';
import { connect } from 'react-redux';
import LimsTestService from '../../services/limsTestService';

class MasterLimsTests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            sortBy: { sortField: "created_at", sortOrder: "desc" },
            tags: {
                category: "",
                name: "",
            },
            filter: {
                category: "",
                name: "",
            },
        }
    }
    componentDidMount() {
        this.getMasterLimsTestList();
        this.limsCategories()
    }

    getMasterLimsTestList = () => {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    limsCategories = () => {
        LimsTestService.listActiveCategories()
            .then(resp => {
                let data = resp.data;
                this.setState({ categories: data.data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                }
            });
    }

    columns = () => {
        return [{
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {
            dataField: 'category',
            text: 'Category'
        },
        {
            dataField: 'name',
            text: 'Name'
        },
        {
            dataField: 'lower_limit',
            text: 'Lower Limit'
        },
        {
            dataField: 'upper_limit',
            text: 'Upper Limit'
        },
        {
            dataField: 'unit_of_measurement',
            text: 'Unit of Measurement'
        },
        {
            dataField: 'alternate_result',
            text: 'Alternate Result'
        }];
    };

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;

        filter[e.target.dataset.tag] = "";
        tags[e.target.dataset.tag] = "";

        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ sortBy: this.state.sortBy, filter });
    };

    renderTags(tags) {
        let options;
        return Object.keys(tags).map((tag) => {
            if (tags[tag] !== "" && !_.isEmpty(tags[tag])) {
                options = (
                    <span className="search-list">
                        {tags[tag]}{" "}
                        <span
                            className="removesearch"
                            data-tag={tag}
                            onClick={this.removeFilter.bind(this)}
                        >
                            {" "}
                            x{" "}
                        </span>{" "}
                    </span>
                );
            } else {
                return false;
            }
            return options;
        });
    }

    resetSearch = () => {
        this.setState({
            filter: {
                category: "",
                name: "",
            },
            tags: {
                category: "",
                name: "",
            },
        });

        window["searchToggle"]();

        this.props.paginate({ sortBy: this.state.sortBy });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        window['searchToggle']();

        this.setState({
            tags: {
                category: this.state.filter.category,
                name: this.state.filter.name,
            }
        });
        this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
    };

    handleChange = (e) => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    };

    filterComponent = (tags) => {
        return (
            <div className="search-wrapper">
                <label>Search</label>
                <div className="search-box clearfix" style={{ border: "1px solid #b9b9b9" }}>
                    {tags && this.renderTags(tags)}
                    <span className="search-box-icon"></span>
                    <div className="search-container">
                        <form onSubmit={this.handleSubmit}>
                            <ul>
                                <li>
                                    <span>Category</span>
                                    <select id="category" name="category" value={this.state.filter.category} onChange={this.handleChange} >
                                        <option value="" >Select Category</option>
                                        {this.state.categories && this.state.categories.map(category => (
                                            <option key={category.id} value={category.name} >{category.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <span>Test Name</span>
                                    <input
                                        type="text"
                                        value={this.state.filter.name}
                                        onChange={this.handleChange}
                                        name="name"
                                        autoComplete="off"
                                    ></input>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={this.resetSearch}
                                        className="btn btn-secondary"
                                    >
                                        Reset
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Search
                                    </button>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        let { data, meta } = this.props.limsTestList
        return (
            <React.Fragment>
                <div className="row">

                    <div className="col-sm-24">
                        {this.filterComponent(this.state.tags)}

                        <div className='clear-both'>
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                noDataIndication="No Record Found"
                                pagination={true}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    limsTestList: state.lims_master_test_list,
});

const mapActionsToProps = {
    paginate: getMasterLimsTestList
};
export default connect(mapStateToProps, mapActionsToProps)(MasterLimsTests)