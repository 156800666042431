import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { hasRole, hasProvider } from '../../utils/auth';
import DoctorAddQueueButton from '../../components/DoctorAddQueueButton';
import SearchPatient from "../../components/SearchPatient";
import PrescriptionService from '../../services/prescriptionService';
import Notification from '../../utils/notification';
import ScheduleAppointmentModal from '../../components/Slot/ScheduleAppointmentModal';
import * as loaderAction from '../../actions/loaderAction';
import AppointmentService from '../../services/appointmentService';
import DataGrid from '../../components/DataGrid';
import * as patientAction from '../../actions/patientAction';
import * as Helper from "../../utils/helpers";

class SearchPatientPage extends React.Component {
    columns = () => {
        return [{
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'patient_code',
            text: 'Patient Id',
        }, {
            dataField: 'first_name',
            text: 'First Name',
            sort: true
        }, {
            dataField: 'middle_name',
            text: 'Middle Name',
            sort: true
        }, {
            dataField: 'last_name',
            text: 'Last Name',
            sort: true
        }, {
            dataField: 'maiden_name',
            text: "Mother's Maiden Name"
        }, {
            dataField: 'date_of_birth',
            text: 'Date Of Birth'
        }, {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };
    columnsPatient = () => {
        return [{
            dataField: 'patient_id',
            text: 'Patient Id',
            hidden: true,
            csvExport: false
        }, {
            dataField: 'patient_code',
            text: 'Patient Code',
        }, {
            dataField: 'full_name',
            text: 'Name'
        }, {
            dataField: 'date_of_birth',
            text: 'DOB',
            formatter: cell => Helper.getDateFormat(cell),
            csvFormatter: cell => Helper.getDateFormat(cell)
        }, {
            dataField: 'address',
            text: 'Address',
            formatter: cell => Helper.fullAddressStr(cell),
            csvFormatter: cell => Helper.fullAddressStr(cell)
        }, {
            dataField: 'contact_number',
            text: 'Phone'
        }, {
            dataField: 'email',
            text: "Email"
        }, {
            dataField: 'insurance_type',
            text: 'Insurance Type',
            formatter: cell => Helper.patientInsurance(cell),
            csvFormatter: cell => Helper.patientInsurance(cell)
        }
        ];
    };

    constructor(props) {
        super(props);

        this.state = {
            card_number: '',
            patient_code: '',
            access_code: '',
            showHide: false
        };
    }
    createDraftPrescription = (patientId) => {
        PrescriptionService.createDraftPrescription({ "patient_id": patientId }).then(resp => {
            this.props.history.push("/patients/" + patientId + "/prescriptions/" + resp.data.prescription_id)
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }
    actionFormatter = (_cell, row) => {
        let { user } = this.props;

        if (hasRole(user, ['doctor'])) {
            return (<React.Fragment>
                <Link to={"/patients/" + row.id} className="btn btn-secondary">View Patient</Link>
                <button className="btn btn-primary" onClick={() => this.createDraftPrescription(row.id)} type="submit">
                    Add Prescription
                </button>
                <Link to={"/patient/" + row.id + "/edit"} className="btn btn-secondary">Edit patient</Link>
            </React.Fragment>);
        }

        if (hasRole(user, ['doctor-nurse'])) {
            return (<React.Fragment>
                <Link to={"/patients/" + row.id} className="btn btn-secondary">View Patient</Link>
                <button className="btn btn-primary" onClick={() => this.createDraftPrescription(row.id)} type="submit">
                    Add Prescription
                </button>
                <DoctorAddQueueButton patientId={row.id} history={this.props.history} />
            </React.Fragment>);
        }

        if (hasRole(user, ['doctor-receptionist'])) {
            return (<React.Fragment>
                <Link to={"/patients/" + row.id} className="btn btn-secondary">View Patient</Link>
                <DoctorAddQueueButton patientId={row.id} history={this.props.history} />
            </React.Fragment>);
        }

        return "";
    }

    handlePatientChange = (patient_id) => {
        const { service_provider } = this.props.selected_user_profile
        if (hasProvider(service_provider, ['doctor', 'pathology'])) {
            this.props.history.push('/patients/' + patient_id);
        } else if (hasProvider(service_provider, ['covid-center'])) {
            this.props.history.push('/covid-centers/patients/' + patient_id);
        }
    }

    handleModalShowHide = () => {
        this.setState({ showHide: !this.state.showHide })
    }
    handleAppointmentSubmit = (data) => {
        this.props.showLoader(this.state);
        let appointmentData = {
            "from": data.from,
            "to": data.to,
            "patient_name": data.patient_name,
            "patient_contact": data.patient_contact,
            "slot_id": data.slot_id,
            "doctor_id": data.doctor_profile_id,
            "type": "other"
        }
        AppointmentService.createAppointment(appointmentData).then(resp => {
            this.props.hideLoader();
            Notification.show('success', { 'message': 'Appointment has been scheduled.' });
            this.setState({ showHide: false });
        }).catch(err => {
            this.props.hideLoader();
            Notification.show('error', err.response.data)
        })
    }

    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    render() {
        let { selected_user_profile } = this.props;
        const { data, meta } = this.props.patient_list;
        return <div>
            <Helmet>
                <title>Search Patients</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Search Patient</h4>
                    <div className="content-wrapper">
                        <div className="row">
                            <SearchPatient
                                handlePatientSearch={this.handlePatientChange}
                                history={this.props.history}
                            ></SearchPatient>
                            {!hasRole(selected_user_profile, ["nondhdoctor"]) && <Link to={"/patients"} className="btn btn-secondary revisit-btn">Revisit Patient</Link>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-lg-end">
                {hasRole(selected_user_profile, ["doctor", "associate-doctor", "doctor-receptionist", "doctor-nurse", "medical-practice-manager"]) && <Link to="#" className="add-new-patient" onClick={this.handleModalShowHide}>Schedule Appointment</Link>}

                {!hasRole(selected_user_profile, ["data-entry-clerk", "pathology-manager","pathologist"]) &&
                <Link to={{
                    pathname: "/patients/create",
                    state: {
                        first_name: this.state.first_name,
                        middle_name: this.state.middle_name,
                        last_name: this.state.last_name,
                        mother_maiden_name: this.state.mother_maiden_name,
                        date_of_birth: this.state.date_of_birth
                    }
                }} className="add-new-patient">Add New Patient</Link> }

                {this.state.showHide && <ScheduleAppointmentModal
                    handleModalShowHide={this.handleModalShowHide}
                    showHide={this.state.showHide}
                    title="Schedule Appointment"
                    handleSubmit={this.handleAppointmentSubmit} />}
            </div>
            <div className="content-wrapper">
                <DataGrid
                    columns={this.columnsPatient()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication="No Record Found"
                    export_csv={hasRole(selected_user_profile, ["doctor", "pathology", "pathologist", "pathology-manager"])}
                />
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    patient_list: state.patient_list
});

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide,
    paginate: patientAction.listPatients
};

export default connect(mapStateToProps, mapActionsToProps)(SearchPatientPage);