import React from 'react';
import { Modal, Form, Row, Col, Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";

class QueueDoctorSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctors: this.props.doctors ? this.props.doctors: '',
            user_location_id: '',
            doctor_profile_id: ''
        }
        this.validator = new SimpleReactValidator();
    }
    componentWillReceiveProps(props) {
        this.setState({ doctors: props.doctors })
    }
    handleChange = (evt) => this.setState({ [evt.target.name]: evt.target.value })
    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.handleSubmit(this.state)
    }

    handleModalShow = () => {
        this.props.handleModalShow()
        this.handleClearState()
        this.validator.hideMessages()
    }
    handleClearState = () => {
        this.setState({
            doctor_profile_id: ''
        });
    }
    render() {
        console.log('cur state=',this.state)
        console.log('cur props=',this.props)
        return <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.props.show} backdrop="static" keyboard={false} onHide={this.handleModalShow}>
            <Modal.Header closeButton >
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="schedule-appmnt-popup">
                    <Form inline>
                        <Row>
                            <Col md={24}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={12}>Select Doctor: *</Form.Label>
                                    <Col sm={12}>
                                    <Form.Control as="select" onChange={this.handleChange} name="doctor_profile_id" value={this.state.doctor_profile_id}>
                                        <option value="">Select Doctor</option>
                                        {this.state.doctors && this.state.doctors.map(doctor => (
                                            <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
                                        ))}
                                    </Form.Control>
                                    {this.validator.message("doctor_profile_id", this.state.doctor_profile_id, "required")}

                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={this.handleSubmit}>
                    Submit
                </Button>
                <Button variant="outline-primary" onClick={this.handleModalShow}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

export default connect(mapStateToProps, null)(QueueDoctorSelectModal)