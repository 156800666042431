import React from 'react';
import FileDownloader from "../components/Report/FileDownloader";

class DownloadAndPrintReport extends React.Component {

  constructor(props) {
		super(props);
		this.state = {
			pdfUrl: this.props.pdfUrl,
      		isloading: false
		}
		
	}

  render() {
    return (
	<>
		<div className="user-popup-icon">
			<img src="/images/reports-upload-today.png" alt="" />
			<h4>Reports</h4>
		</div>
		<ul className="form-listing">
			<li className="button-wrapper">
				<FileDownloader fileUrl={this.props.pdfUrl} />
				<a className="btn btn-secondary" href={this.props.pdfUrl} target="_blank" rel="noreferrer">Print Report</a>
			</li>
		</ul>
	</>
    );
  }
}

export default DownloadAndPrintReport;