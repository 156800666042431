import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class ImmunizationService {
    static getPatientsImmunization(patient_id, key) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/patients/" + patient_id + "/immunizations?type=" + key), requestOptions);
    }

    static createPatientsImmunization(patient_id, data) {
        const postData = JSON.stringify(data);
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.post(apiRoute("/v1/patients/" + patient_id + "/immunizations"), postData, requestOptions);
    }

    static list({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute("/v1/immunizations/patients"), requestOptions);
    }

    static downloadPdf(patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/patients/" + patient_id + "/immunizations/cards"), requestOptions);
    }

    static downloadOtherImmunization(patient_id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute("/v1/patients/" + patient_id + "/immunizations/other"), requestOptions);
    }
}
export default ImmunizationService