import React from "react";
import { Modal } from 'react-bootstrap';

class AdditionalInformationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                // Set initial form data based on the props
                last_fasting: (props.getData && props.getData.last_fasting) || "",
                last_bp: (props.getData && props.getData.last_bp) || "",
                familyHistory: (props.getData && props.getData.familyHistory) || [],
                personalHistory: (props.getData && props.getData.personalHistory) || [],
                smokeStatus: (props.getData && props.getData.smokeStatus) || "",
                activityStatus: (props.getData && props.getData.activityStatus) || "",
                alcoholStatus: (props.getData && props.getData.alcoholStatus) || "",
                riskAssessment_diabetes: (props.getData && props.getData.riskAssessment_diabetes) || "",
                riskAssessment_hypertension: (props.getData && props.getData.riskAssessment_hypertension) || ""
            },
        };
    }

    // Define your family history options as an array of objects
    familyHistoryOptions = [
        { id: 'diabetes1', label: 'Diabetes', value: 'diabetes' },
        { id: 'hypertension', label: 'Hypertension', value: 'hypertension' },
        { id: 'noneOfTheAbove', label: 'None of the Above', value: 'None of the above' }
    ];

    // Define your personal history options as an array of objects
    personalHistoryOptions = [
        { id: 'heartDisease', label: 'Heart Disease/Stroke', value: 'heart disease/stroke' },
        { id: 'sleepDisorderedBreathing', label: 'Sleep Disordered Breathing', value: 'sleep disordered breathing' },
        { id: 'overweightObese', label: 'Overweight/Obese', value: 'overweight/obese' },
        { id: 'highCholesterol', label: 'High Cholesterol', value: 'high cholesterol' },
        { id: 'diabetesPregnancy', label: 'A History of Diabetes in Pregnancy', value: 'a history of diabetes in pregnancy' },
        { id: 'diabetes', label: 'Diabetes', value: 'diabetes' },
        { id: 'kidneyDisease', label: 'Kidney Disease', value: 'kidney disease' },
        { id: 'highBloodPressure', label: 'High Blood Pressure', value: 'high blood pressure' },
        { id: 'insulinResistance', label: 'Signs of Insulin Resistance', value: 'signs of insulin resistance' },
        { id: 'noneOfTheAbove1', label: 'None of the Above', value: 'None of the above' }
    ];

    renderFamilyHistoryCheckbox(option) {
        const { formData } = this.state;
        return (
            <li key={option.id}>
                <input
                    type="checkbox"
                    id={option.id}
                    name="familyHistory"
                    value={option.value}
                    className="form-control"

                    checked={formData.familyHistory.includes(option.value)}
                />
                <label htmlFor={option.id}>{option.label}</label>
            </li>

        );
    }
    renderPersonalHistoryCheckbox(option) {
        const { formData } = this.state;
        return (
            <li key={option.id}>
                <input
                    type="checkbox"
                    id={option.id}
                    name="personalHistory"
                    value={option.value}
                    className="form-control"

                    checked={formData.personalHistory.includes(option.value)}
                />
                <label htmlFor={option.id}>{option.label}</label>
            </li>
        );
    }

    render() {
        const { formData } = this.state;

        return (
            <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.props.handleAdditionalInformation} className="original-prescription">
                <Modal.Header closeButton>
                    <h6>Additional Information</h6>
                </Modal.Header>
                <Modal.Body className="c-pointer-event-none">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-24">
                                <div className="content-wrapper add-lab-report">
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-24 text-center">
                                                <h5>Screening History</h5>
                                                <hr />
                                            </div>
                                            <fieldset className="form-group col-md-24">
                                                <div className="row">
                                                    <div className="col-sm-24">
                                                        <label>Last Fasting Blood Glucose / Oral Glucose Tolerance Test</label>
                                                    </div>
                                                    <div className="col-sm-24">
                                                        <ul className="additaniol-form-wrapper">
                                                            <li>
                                                                <input type="radio" id="screeningHistory_lt1" name="last_fasting" value="< 1 years ago" className="form-control" checked={formData.last_fasting === "< 1 years ago"} />
                                                                <label htmlFor="screeningHistory_lt1">&lt; 1 years ago</label>
                                                            </li>
                                                            <li><input type="radio" id="screeningHistory_gt2" name="last_fasting" value="> 2 years ago" className="form-control" checked={formData.last_fasting === "> 2 years ago"}
                                                            />
                                                                <label htmlFor="screeningHistory_gt2">&gt; 2 years ago</label></li>
                                                            <li><input type="radio" id="screeningHistory_1" name="last_fasting" value="1 years ago" className="form-control" checked={formData.last_fasting === "1 years ago"}
                                                            />
                                                                <label htmlFor="screeningHistory_1">1 years ago</label></li>
                                                            <li><input type="radio" id="screeningHistory_cantRecall" name="last_fasting" value="Can't recall" className="form-control" checked={formData.last_fasting === "Can't recall"}
                                                            />
                                                                <label htmlFor="screeningHistory_cantRecall">Can't recall</label></li>
                                                            <li><input type="radio" id="screeningHistory_2" name="last_fasting" value="2 years ago" className="form-control" checked={formData.last_fasting === "2 years ago"}
                                                            />
                                                                <label htmlFor="screeningHistory_2">2 years ago</label></li>
                                                            <li><input type="radio" id="screeningHistory_never" name="last_fasting" value="Never" className="form-control" checked={formData.last_fasting === "Never"}
                                                            />
                                                                <label htmlFor="screeningHistory_never">Never</label></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset className="form-group col-md-24">
                                                <div className="row">
                                                    <div className="col-sm-24">
                                                        <label>Last Blood Pressure Management</label>
                                                    </div>
                                                    <div className="col-sm-24">
                                                        <ul className="additaniol-form-wrapper">
                                                            <li> <input type="radio" id="screeningHistory_bp_lt1" name="last_bp" value="< 1 years ago" className="form-control" checked={formData.last_bp === "< 1 years ago"}
                                                            />
                                                                <label htmlFor="screeningHistory_bp_lt1">&lt; 1 years ago</label>
                                                            </li>
                                                            <li><input type="radio" id="screeningHistory_bp_gt2" name="last_bp" value="> 2 years ago" className="form-control" checked={formData.last_bp === "> 2 years ago"}
                                                            />
                                                                <label htmlFor="screeningHistory_bp_gt2">&gt; 2 years ago</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="screeningHistory_bp_1" name="last_bp" value="1 years ago" className="form-control" checked={formData.last_bp === "1 years ago"}
                                                                />
                                                                <label htmlFor="screeningHistory_bp_1">1 years ago</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="screeningHistory_bp_cantRecall" name="last_bp" value="Can't recall" className="form-control" checked={formData.last_bp === "Can't recall"}
                                                                />
                                                                <label htmlFor="screeningHistory_bp_cantRecall">Can't recall</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="screeningHistory_bp_2" name="last_bp" value="2 years ago" className="form-control" checked={formData.last_bp === "2 years ago"}
                                                                />
                                                                <label htmlFor="screeningHistory_bp_2">2 years ago</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="screeningHistory_bp_never" name="last_bp" value="Never" className="form-control" checked={formData.last_bp === "Never"}
                                                                />
                                                                <label htmlFor="screeningHistory_bp_never">Never</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-24 text-center">
                                                <h5>Family History</h5>
                                                <hr />
                                            </div>
                                            <div className="col-sm-24">
                                                <fieldset className="form-group">
                                                    <div>
                                                        <label>Select All That Apply</label>
                                                        <ul className="additaniol-form-wrapper">
                                                            {this.familyHistoryOptions.map(option => this.renderFamilyHistoryCheckbox(option))}
                                                        </ul>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-24 text-center">
                                                <h5>Personal History</h5>
                                                <hr />
                                            </div>
                                            <div className="col-sm-24">
                                                <fieldset className="form-group">
                                                    <div>
                                                        <label>Select All That Apply</label>
                                                        <ul className="additaniol-form-wrapper">
                                                            {this.personalHistoryOptions.map(option => this.renderPersonalHistoryCheckbox(option))}
                                                        </ul>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-24 text-center">
                                                <h5>Other Risk Behaviours</h5>
                                                <hr />
                                            </div>
                                            <div className="col-md-24">
                                                <fieldset className="form-group">
                                                    <div>
                                                        <label>Do you ever smoke?</label>
                                                        <div className="col-md-24">
                                                            <ul className="additaniol-form-wrapper">
                                                                <li><input type="radio" id="smokeYes" name="smokeStatus" value="Yes - Currently" className="form-control" checked={formData.smokeStatus === "Yes - Currently"}
                                                                />
                                                                    <label htmlFor="smokeYes">Yes - Currently</label></li>
                                                                <li><input type="radio" id="smokePast" name="smokeStatus" value="In the Past" className="form-control" checked={formData.smokeStatus === "In the Past"}
                                                                />
                                                                    <label htmlFor="smokePast">In the Past</label></li>
                                                                <li> <input type="radio" id="smokeNever" name="smokeStatus" value="Never" className="form-control" checked={formData.smokeStatus === "Never"}
                                                                />
                                                                    <label htmlFor="smokeNever">Never</label></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <fieldset className="form-group">
                                                    <div>
                                                        <label>Are you physically active?</label>
                                                        <div className="col-md-24">
                                                            <ul className="additaniol-form-wrapper">
                                                                <li><input type="radio" id="activeYes" name="activityStatus" value="Yes - Currently" className="form-control" checked={formData.activityStatus === "Yes - Currently"}
                                                                />
                                                                    <label htmlFor="activeYes">Yes - Currently</label></li>
                                                                <li><input type="radio" id="activePast" name="activityStatus" value="In the Past" className="form-control" checked={formData.activityStatus === "In the Past"}
                                                                />
                                                                    <label htmlFor="activePast">In the Past</label></li>
                                                                <li><input type="radio" id="activeNever" name="activityStatus" value="Never" className="form-control" checked={formData.activityStatus === "Never"}
                                                                />
                                                                    <label htmlFor="activeNever">Never</label></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <fieldset className="form-group">
                                                    <div>
                                                        <label>How many alcoholic drinks do you have in a day?</label>
                                                        <div className="col-md-24">
                                                            <ul className="additaniol-form-wrapper">
                                                                <li><input type="radio" id="alcohol1orLess" name="alcoholStatus" value="1 or less" className="form-control" checked={formData.alcoholStatus === "1 or less"}
                                                                />
                                                                    <label htmlFor="alcohol1orLess">1 or less</label></li>
                                                                <li><input type="radio" id="alcohol2" name="alcoholStatus" value="2" className="form-control" checked={formData.alcoholStatus === "2"}
                                                                />
                                                                    <label htmlFor="alcohol2">2</label></li>
                                                                <li><input type="radio" id="alcoholMoreThan2" name="alcoholStatus" value="More than 2" className="form-control" checked={formData.alcoholStatus === "More than 2"}
                                                                />
                                                                    <label htmlFor="alcoholMoreThan2">More than 2</label></li>
                                                                <li><input type="radio" id="alcoholNone" name="alcoholStatus" value="I do not drink alcohol" className="form-control" checked={formData.alcoholStatus === "I do not drink alcohol"}
                                                                />
                                                                    <label htmlFor="alcoholNone">I do not drink alcohol</label></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-24 text-center">
                                                <h5>Other Risk Behaviours</h5>
                                                <hr />
                                            </div>
                                            <div className="col-sm-24">
                                                <div className="row">
                                                    <div className="col-sm-24 text-center">
                                                        <table className="risk-assessment-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {/* <input type="radio" id="low" name="riskAssessment" value="Low" className="form-control" />
                                                                  <label htmlFor="low">Low</label> */}
                                                                    </td>
                                                                    <td>
                                                                        <label htmlFor="average_risk">Average Risk</label>
                                                                    </td>
                                                                    <td>
                                                                        <label htmlFor="high_risk">High Risk</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor="dia_low">Diabetes</label>
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" id="dia_average" name="riskAssessment_diabetes" value="Average" className="form-control" checked={formData.riskAssessment_diabetes === "Average"} />
                                                                        <label htmlFor="dia_average">Average</label>
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" id="dia_high" name="riskAssessment_diabetes" value="High" className="form-control" checked={formData.riskAssessment_diabetes === "High"} />
                                                                        <label htmlFor="dia_high">High</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor="hyper_low">Hypertension/CVD</label>
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" id="hyper_average" name="riskAssessment_hypertension" value="Average" className="form-control" checked={formData.riskAssessment_hypertension === "Average"} />
                                                                        <label htmlFor="hyper_average">Average</label>
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" id="hyper_high" name="riskAssessment_hypertension" value="High" className="form-control" checked={formData.riskAssessment_hypertension === "High"} />
                                                                        <label htmlFor="hyper_high">High</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >
        );
    }
}

export default AdditionalInformationView;
