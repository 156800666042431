import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getDateFormat, getAgeString } from "../../utils/helpers";
import { capitalize, groupBy, isEmpty, has, map } from 'lodash';
import { connect } from "react-redux";
import { hasRole } from "../../utils/auth";
import VitalBlock from '../../components/Prescription/Vital/ViewVital';
import ViewHistoryBlock from '../../components/Prescription/History/ViewHistoryAccordion';
import ViewExamination from '../../components/Prescription/Examination/ViewExamination';
import ShowMedicineBlock from '../../components/Prescription/ShowMedicineBlock';
import ShowRadiologyBlock from '../../components/Prescription/ShowRadiologyBlock';
import ShowPathologyBlock from '../../components/Prescription/ShowPathologyBlock';
import * as ShowCptIcd from '../../components/ShowCptIcd';
import PrescriptionService from '../../services/prescriptionService';
import Notification from '../../utils/notification';
import ShareWithDoctor from "../../components/Prescription/ShareWithDoctor";
import { SPECIALIZATION } from "../../config";
import * as LoaderAction from '../../actions/loaderAction';
import PinModal from '../../components/Signature/PinModal';
import CancelModal from '../../components/CancelModal';
import ViewBodyChart from '@Components/Prescription/BodyChart/ViewBodyChart';
class ViewPrescriptionNew extends React.Component {
    constructor(props) {
        super(props);
        let { params } = this.props.match;
        this.state = {
            prescription: '',
            prescriptionId: params.prescriptionId,
            icd_cpts: {},
            permission_section: {
                section_symptoms: 'deny',
                section_examination: 'deny',
                // section_diagnosis: 'deny',
                section_medicines: 'deny',
                section_pathology_tests: 'deny',
                section_radiology_tests: 'deny',
                section_remarks: 'deny',
                section_icd: 'deny',
                section_history: 'deny'
            },
            share_with_doctor: {},
            showPinModal: false,
            showCancelModal: false,
            body_charts: [],
        }
    }
    handleCancelClose = () => {
        this.setState({ showCancelModal: false })
    }

    handleCancelRedirect = () => {
        this.setState({ showCancelModal: false })
        this.props.history.push("/patients/search");
    }
    showCancelModal = () => {
        this.setState({ showCancelModal: true })
    }
    _handleChange = (evt) => {
        const { permission_section } = this.state;

        let fieldName = evt.target.name;

        if (evt.target.type === 'checkbox') {
            if (evt.target.checked) {
                permission_section[fieldName] = 'allow';
            } else {
                permission_section[fieldName] = 'deny';
            }
        }
        if (fieldName === 'doctor_specialization') {
            this.setState({
                share_with_doctor: {
                    doctor_id: '',
                    doctor_name: '',
                    service_provider_id: '',
                    doctor_specialization: evt.target.value
                }
            });
        }
    }
    //Share with doctor
    handleShareWithDoctor = (data) => {
        if (!isEmpty(data.specialization)) {
            this.setState({
                share_with_doctor: {
                    doctor_id: data.doctor_id,
                    doctor_name: data.doctor_name,
                    doctor_specialization: this.state.share_with_doctor.doctor_specialization,
                }
            });
        }
    }
    componentDidMount() {
        this.props.showLoader();
        PrescriptionService.view(this.state.prescriptionId).then(response => {
            let { data } = response;
            let prescription_body_chart_data = [];
            if (data.assessment && data.assessment.icd_cpts) {
                const prescription_icd_data = groupBy(data.assessment.icd_cpts, 'icd_code')
                let modified = [];
                Object.keys(prescription_icd_data).forEach((key) => {
                    prescription_icd_data[key].forEach((icd, _index) => (
                        modified.push({
                            selectedIcd: {
                                icd_code: icd.icd_code,
                                full_description: icd.icd_description
                            },
                            icdCrosswalks: [{
                                cpt_code: icd.cpt_code,
                                full_description: icd.cpt_description
                            }]
                        })
                    ))
                });
                this.setState({
                    icd_cpts: modified,
                    prescription: response.data
                })
                this.props.hideLoader();
            }
            if (data.body_charts) {
                prescription_body_chart_data = groupBy(data.body_charts, 'image_slug');
                this.setState({
                    body_charts: prescription_body_chart_data,
                })
            }

        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        });
    }
    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }
    handleSubmit = e => {
        e.preventDefault();
        let key; let values = [];
        for (key in this.state.permission_section) {
            if (this.state.permission_section.hasOwnProperty(key)) {
                if (this.state.permission_section[key] === 'allow') {
                    values.push(this.state.permission_section[key])
                }
            }
        }
        if (!isEmpty(this.state.share_with_doctor.doctor_specialization) || !isEmpty(values)) {
            if (isEmpty(this.state.share_with_doctor.doctor_name)) {
                Notification.show('error', { message: 'Share With Doctor Name Required' });
                return false;
            } else {
                if (isEmpty(values)) {
                    Notification.show('error', { message: 'select atleast one section for permission' });
                    return false;
                } else if (isEmpty(this.state.share_with_doctor.doctor_specialization)) {
                    Notification.show('error', { message: 'select specialization' });
                    return false;
                }
            }
        }
        let data = {
            patient_id: this.state.prescription.patient_id,
            permission_section: this.state.permission_section ? this.state.permission_section : null,
            share_with_doctor: this.state.share_with_doctor ? this.state.share_with_doctor : null,
        }
        this.props.showLoader();
        PrescriptionService.createPrescriptionSummary(this.state.prescriptionId, data).then(response => {
            this.props.hideLoader();
            if (!isEmpty(response) && !isEmpty(this.state.prescriptionId)) {
                Notification.show('success', { message: 'Prescription has been saved successfully' });
                this.showPinModal();
            }
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data);
        })
    }
    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }
    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.props.history.push("/prescriptions/" + this.state.prescriptionId);
    }
    render() {
        let { prescription } = this.state;
        let { recommendation } = prescription;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Prescription</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title no-heading">
                            <Link onClick={() => this.props.history.goBack()} to="#">&#8249; Back</Link>
                            <span className="prescription-details">Prescription Id: <i>{prescription.prescription_id}</i></span>
                            <span className="date">Prescription Date <i>{getDateFormat(prescription.prescription_date)}</i></span>
                        </h4>
                        <h4 className="page-title">Prescription
                            <div>
                            </div>
                        </h4>
                        {has(prescription, 'patient_info') && <div className="content-wrapper padding-bottom-10">
                            <h6>Personal Info</h6>
                            <ul className="prescription-patient-details">
                                <li>Name: <span>{prescription.patient_info.full_name}</span></li>
                                <li>Date of Birth: <span>{getDateFormat(prescription.patient_info.date_of_birth)} ({getAgeString(prescription.patient_info.date_of_birth)})</span></li>
                                <li>Gender: <span>{capitalize(prescription.patient_info.gender)}</span></li>
                            </ul>
                        </div>}
                        {/* vital */}
                        <div className="content-wrapper padding-bottom-10">
                            <h6>Vitals</h6>
                            {has(prescription, 'vital') && <VitalBlock vital={prescription.vital} patient_info={prescription.patient_info} />}
                        </div>
                        {/* history */}
                        {prescription && (!isEmpty(prescription.personal_history) || !isEmpty(prescription.family_history) || !isEmpty(prescription.surgical_history) || !isEmpty(prescription.obstetrics_gynaecology) || (prescription.current_medicine && !isEmpty(prescription.current_medicine.prescription_drugs))) && <div className="content-wrapper padding-bottom-10">
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                <React.Fragment><input type="checkbox" id="section_history" name="section_history" onChange={this._handleChange} />
                                    <label htmlFor="section_history"></label></React.Fragment>
                            }
                            <h6>History</h6>
                            {<ViewHistoryBlock
                                prescriptionId={this.state.prescriptionId}
                                patient_gender={prescription && prescription.patient_info.gender}
                                personal_history={prescription.personal_history}
                                family_history={prescription.family_history}
                                surgical_history={prescription.surgical_history}
                                obstetrics_gynaecology={prescription.obstetrics_gynaecology}
                                current_medicine={prescription.current_medicine} />}
                        </div>}
                        {/* present condition */}
                        {has(prescription, 'present_condition') && <div className="content-wrapper padding-bottom-10">
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                <React.Fragment><input type="checkbox" id="section_symptoms" name="section_symptoms" onChange={this._handleChange} />
                                    <label htmlFor="section_symptoms"></label></React.Fragment>
                            }
                            <h6>Presenting Condition</h6>
                            <p className='c-text-pre-wrap'>{prescription.present_condition && prescription.present_condition.symptoms ? prescription.present_condition.symptoms : '--'}</p>
                        </div>}
                        {/* examination */}
                        {has(prescription, 'examination') && (prescription.examination_note || !isEmpty(prescription.examination.review_of_system) || !isEmpty(prescription.examination.investigation) || !isEmpty(prescription.examination.examination)) && <div className="content-wrapper padding-bottom-10">
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                <React.Fragment><input type="checkbox" id="section_examination" name="section_examination" onChange={this._handleChange} />
                                    <label htmlFor="section_examination"></label></React.Fragment>
                            }
                            <h6>Examination</h6>
                            <ViewExamination examination={prescription.examination} examination_note={prescription.examination_note} investigation={prescription.investigation} />
                            {/* <div className="content-wrapper padding-bottom-10 margin-bottom-none">
                                <h6 className="inner_heading">Examination Note</h6>
                                <p>{prescription.examination_note ? prescription.examination_note : '--'}</p>
                            </div> */}
                        </div>}
                        {/* assessment */}
                        {has(prescription, 'assessment') && (!isEmpty(this.state.icd_cpts) || prescription.assessment.remark || prescription.assessment.treatmentplan || (!isEmpty(this.state.body_charts))) && <div className="content-wrapper padding-bottom-10">
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                <React.Fragment><input type="checkbox" id="section_icd" name="section_icd" onChange={this._handleChange} />
                                    <label htmlFor="section_icd"></label></React.Fragment>
                            }
                            <h6>Assessment</h6>
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", 'junior-doctor', 'medical-practice-manager']) && !isEmpty(this.state.icd_cpts) &&
                                <div className="content-wrapper padding-bottom-10">
                                    <h6>ICD CPT</h6>
                                    <ul className="prescription-patient-details medicine-details">
                                        <div className="content-wrapper">
                                            <ul className="doctor-tests">
                                                {this.state.icd_cpts.map((icd, index) => (<ShowCptIcd.ShowIcd icd={icd} idx={index} disable={true} key={index} />))}
                                            </ul>
                                        </div>
                                    </ul>
                                </div>}
                            <div className="content-wrapper padding-bottom-10">
                                <h6>Assessment Remark</h6>
                                <p>{prescription.assessment.remark ? prescription.assessment.remark : '--'}</p>
                            </div>
                            <div className="content-wrapper padding-bottom-10 margin-bottom-none">
                                <h6 className="inner_heading">Treatment Plan</h6>
                                <p className='c-text-pre-wrap'>{prescription.assessment.treatmentplan ? prescription.assessment.treatmentplan : '--'}</p>
                            </div>
                        </div>}
                        {/* body_charts */}
                        {has(prescription, 'body_charts') && (!isEmpty(this.state.body_charts)) && <div className="content-wrapper padding-bottom-10">
                            <h6>Body Chart</h6>
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", 'junior-doctor', 'medical-practice-manager']) &&
                                <ViewBodyChart
                                    prescriptionId={this.state.prescriptionId}
                                    body_charts={prescription && this.state.body_charts && this.state.body_charts}
                                />
                            }
                        </div>}
                        {/* body_chart_uploads */}
                        {has(prescription, 'body_chart_uploads') && (!isEmpty(prescription.body_chart_uploads)) && <div className="content-wrapper padding-bottom-10">
                            <h6>Body Chart Uploaded Images</h6>
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", 'junior-doctor', 'medical-practice-manager']) &&
                                (map(prescription.body_chart_uploads, (image, index) => (
                                    <div class="image-upload col-md-24 p-3 border-top anatomy-view float-left">
                                        <div className="col-md-12 float-left">
                                            <ul class="prescription-patient-details"><li>Description: <span>{image.description}</span></li></ul>
                                        </div>
                                        <div className="col-md-12 float-left text-right">
                                            <img src={image.url} height="150px" />
                                        </div>
                                    </div>
                                )))
                            }
                        </div>}
                        {/* recommendation */}
                        {has(prescription, 'recommendation') && (recommendation.prescription_medicines || recommendation.prescription_pathology_tests || recommendation.prescription_radiology_tests) && <div className="content-wrapper padding-bottom-10">
                            <h6>Prescription and Recommendation</h6>
                            {/* Medication info */}
                            {recommendation.prescription_medicines && <div className="content-wrapper padding-bottom-10">
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                    <React.Fragment><input type="checkbox" id="section_medicines" name="section_medicines" onChange={this._handleChange} />
                                        <label htmlFor="section_medicines"></label></React.Fragment>}
                                <h6>Prescribed Medication</h6>
                                {recommendation.prescription_medicines.prescription_medicine_item.map((item, idx) => (
                                    <ShowMedicineBlock medicine={item} key={idx} />
                                ))}
                            </div>}
                            {/* pathology info */}
                            {recommendation.prescription_pathology_tests && <div className="content-wrapper padding-bottom-10">
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                    <React.Fragment><input type="checkbox" id="section_pathology_tests" name="section_pathology_tests" onChange={this._handleChange} />
                                        <label htmlFor="section_pathology_tests"></label></React.Fragment>}
                                <h6>Lab Test</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    <div className="content-wrapper">
                                        <ul className="doctor-tests">
                                            {recommendation.prescription_pathology_tests.prescription_pathology_test_items.map((item, idx) => (
                                                <ShowPathologyBlock pathology={item} key={idx} />
                                            ))}
                                        </ul>
                                    </div>
                                </ul>
                            </div>}
                            {/* radiology info */}
                            {recommendation.prescription_radiology_tests && <div className="content-wrapper padding-bottom-10">
                                {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                    <React.Fragment><input type="checkbox" id="section_radiology_tests" name="section_radiology_tests" onChange={this._handleChange} />
                                        <label htmlFor="section_radiology_tests"></label></React.Fragment>}
                                <h6>Radiology Test</h6>
                                <ul className="prescription-patient-details medicine-details">
                                    <div className="content-wrapper">
                                        <ul className="doctor-tests">
                                            {recommendation.prescription_radiology_tests.prescription_radiology_test_items.map((item, idx) => (
                                                <ShowRadiologyBlock radiology={item} key={idx} />
                                            ))}
                                        </ul>
                                    </div>
                                </ul>

                            </div>}
                        </div>}
                        {has(prescription, 'personal_note') && this.props.selected_user_profile.user_profile_id === prescription.created_by.user_profile_id && <div className="content-wrapper padding-bottom-10">
                            <h6>Personal Note</h6>
                            <p>{prescription.personal_note ? prescription.personal_note : '--'}</p>
                        </div>}
                        {/* remark block */}
                        <div className="content-wrapper padding-bottom-10">
                            {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) &&
                                <React.Fragment><input type="checkbox" id="section_remarks" name="section_remarks" onChange={this._handleChange} />
                                    <label htmlFor="section_remarks"></label></React.Fragment>
                            }
                            <h6>Remark</h6>
                            <p>{has(prescription, 'recommendation') && prescription.recommendation.remarks ? prescription.recommendation.remarks : '--'}</p>
                        </div>
                        {/* refer block */}
                        {has(prescription, 'recommendation') && prescription.recommendation.refer_to && (this.props.selected_user_profile.user_profile_id === prescription.created_by.user_profile_id || this.props.selected_user_profile.user_id === prescription.recommendation.refer_to.user_id) && <div className="content-wrapper padding-bottom-10">
                            <h6>Refer To Doctor</h6>
                            <p><span>Doctor Specialization: </span>{prescription.recommendation.refer_to.specialization}</p>
                            <p><span>Doctor Name: </span>{prescription.recommendation.refer_to.full_name}</p>
                            <p><span>Refer Remark: </span>{prescription.recommendation.refer_to.remark}</p>
                        </div>}
                        {/* share with */}
                        <div className="content-wrapper padding-bottom-10">
                            <h6>Share With</h6>
                            <ul className="share-with">
                                <li>Share With:</li>
                                <li>
                                    <select className="form-control margin-bottom" onChange={this._handleChange} value={this.state.share_with_doctor.doctor_specialization} name="doctor_specialization">
                                        <option value="" defaultValue>Select Specialization</option>
                                        {Object.keys(SPECIALIZATION).map(function (key, index) {
                                            return <option className="travelcompany-input" key={index} value={SPECIALIZATION[key]}>
                                                {SPECIALIZATION[key]}
                                            </option>;
                                        })}
                                    </select>
                                </li>
                                <li>
                                    <ShareWithDoctor
                                        handleShareWith={this.handleShareWithDoctor}
                                        share_with_doctor={this.state.share_with_doctor}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor', "paramedic-doctor"]) && <div className="button-wrapper align-right">
                    <button type="submit" className="btn btn-primary" onClick={e => this.handleSubmit(e)}>Submit</button>
                    <button type="cancel" className="btn btn-default btn-outline-primary" onClick={this.showCancelModal}>Cancel</button>
                </div>}
                {this.state.showPinModal &&
                    <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.prescriptionId} type="prescription" onSuccess={this.onSuccessRedirect} />
                }
                {this.state.showCancelModal &&
                    <CancelModal showModal={this.state.showCancelModal} handleRedirect={this.handleCancelRedirect} handleClose={this.handleCancelClose} />
                }
            </React.Fragment >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        selected_user_profile: state.selected_user_profile
    };
}
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(ViewPrescriptionNew)
