import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import authReducer from './authReducer';
import alert from './alertReducer';
import patient from './patientReducer';
import pharmacy_list from './pharmacyListReducer';
import patient_list from './patientListReducer';
import medicine_list from './medicineListReducer';
import prescription from './prescriptionReducer';
import prescriptionOrder from './prescriptionOrderReducer';
import selectedDoctor from './selectedDoctorReducer';
import patient_data from './patientDataReducer';
import loaderReducer from './loaderReducer';
import forget_password from './forgetPasswordReducer';
import change_password from './changePasswordReducer';
import reset_password from './resetPasswordReducer';
import new_password from './newPasswordReducer';
import commission from './commissionReducer';
import profile from './editProfileReducer';
import selectedUserProfileReducer from './selectedUserProfileReducer';
import user_management from './userManagementReducer';
import toggle_popup from './togglePopupReducer';
import prescription_patho_radio_test from './patientPathoRadioReducer';
import change_prescription from './changePrescriptionReducer';
import user_notification from './userNotificationReducer';
import offline_prescription from './offlinePrescriptionReducer';
import dashboard_info from './dashboardReducer';
import user_account from './userAccountReducer';
import price_list from './priceListReducer';
import reissue_card from './cardReducer';
import user_activities from './userActivityReducer';
import pending_signature_prescription_list from './pendingSignaturePrescriptionListReducer';
import immunization from './immunizationReducer';
import medical_certificate from './medicalCertificateReducer';
import appointment from './appointmentReducer';
import service_provider_locations from './serviceProviderLocationReducer';
import ambulance_list from './ambulanceListReducer';
import ambulance_fetch from './ambulanceFetchReducer';
import hospital_patient_list from './hospitalPatientListReducer';
import patient_historic_data from './patientHistoricDataReducer';
import covid_reports from './covidReportReducer';
import urgent_care from './urgentCareReducer';
import urgent_care_approval from './urgentCareApprovalReducer';
import procedure_list from './procedureReducer';
import data_management from './dataManagementReducer';
import profile_edit_queue from './profileEditQueueReducer';
import pre_registered_patient from './preRegisteredPatientReducer';
import temp_doc_reports from './tempDocReportReducer';
import lims_test_list from './limsTestReducer';
import lims_master_test_list from './limsMasterTestReducer';
import pathology_lims from './pathologyLimsReducer'
import lims_processing_report from './limsProcessingReportReducer';
import lims_pending_report from './limsPendingReportReducer';
import lims_new_report from './limsNewReportReducer';
import lims_completed_report from './limsCompletedReportReducer';
import lims_done_report from './limsDoneReportReducer';
import lims_printed_report from './limsPrintedReportReducer';
import doctors_list from './doctorsUserReducer';


export default history =>
  combineReducers({
    router: connectRouter(history),
    loader: loaderReducer,
    session: authReducer,
    selected_user_profile: selectedUserProfileReducer,
    alert,
    patient,
    patient_list,
    pharmacy_list,
    medicine_list,
    prescription,
    patient_data,
    forget_password,
    change_password,
    reset_password,
    new_password,
    prescription_order: prescriptionOrder,
    selected_doctor: selectedDoctor,
    commission,
    profile,
    user_management,
    toggle_popup,
    prescription_patho_radio_test,
    change_prescription,
    user_notification,
    offline_prescription,
    dashboard_info,
    user_account,
    price_list,
    user_activities,
    reissue_card,
    immunization,
    pending_signature_prescription_list,
    medical_certificate,
    appointment,
    service_provider_locations,
    ambulance_list,
    ambulance_fetch,
    hospital_patient_list,
    patient_historic_data,
    covid_reports,
    urgent_care,
    urgent_care_approval,
    procedure_list,
    data_management,
    profile_edit_queue,
    pre_registered_patient,
    temp_doc_reports,
    lims_test_list,
    lims_master_test_list,
    pathology_lims,
    lims_processing_report,
    lims_pending_report,
    lims_new_report,
    lims_completed_report,
    lims_printed_report,
    lims_done_report,
    doctors_list
  });
