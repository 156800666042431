import React from 'react';
import { Helmet } from "react-helmet";
import DataGrid from "../components/DataGrid";
import { list } from '../actions/preRegisteredPatientAction';
import { connect } from 'react-redux';
import _ from "lodash";
import { Button } from 'react-bootstrap';

class ListPreRegisteredPatientPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            showAddModal: false,
            procedure: "",
            showAddEditModal: false,
            showDeleteModal: false,
            id: 0
        }
    }
    componentDidMount() {
        this.getProcedureList();
    }

    getProcedureList = () => {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    columns = () => {
        return [
            {
                dataField: "id",
                text: "Id",
            },
            {
                dataField: "full_name",
                text: "Full Name"
            },
            {
                dataField: "gender",
                text: "Gender",
                formatter: this.genderFormatter
            },
            {
                dataField: "contact_number",
                text: "Contact Number"
            },
            {
                dataField: "service_provider_name.name",
                text: "Servce Provider"
            },
            {
                dataField: "service_location_name.name",
                text: "Location"
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    genderFormatter = (cell) => {
        return _.capitalize(cell);
    }

    actionFormatter = (cell, row) => {
        if(row.id){
            return <div><a title="Edit">
                    <Button variant="primary" className='ml-2 height-30' onClick={e => this.addPatientHandle(row)}>
                        Add Patient
                    </Button>
                    </a> 
               </div>
                
        }
        return ''
    }

        addPatientHandle(row) {
        this.props.history.push({
            pathname: '/patients/create',
            preRegisteredPatient: row
        });
    }

    handleModal = (row) => {
        this.handleShow()
        if(row === "add"){
            this.setState({ showAddModal: true});
        }else{
            this.setState({ procedure: row, showEditModal: true});
        }
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, });
    }

	onSuccessRedirect = () => {
        this.getProcedureList()
        this.handleClose()
	}
	
	handleClose = () => {
        this.setState({ showAddEditModal: false, procedure: "" });
        this.handleHideModal()
	}
	
	handleShow = () => {
        this.setState({ showAddEditModal: true });
	}

    handleHideModal = () => {
        this.setState({ showAddModal: false, showEditModal: false});
    }

    render() {
        let { data, meta } = this.props.preRegisteredPatient
        return (
            <React.Fragment>
                <Helmet>
                    <title>Pre Registered Patient List</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-24">
                        <div className='page-title'>
                            <span>Pre Registered Patient List</span>
                        </div>
                        <div className="content-wrapper">
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                noDataIndication="No Record Found"
                                pagination={true}
                            />
                        </div>
                    </div>
                </div>

                
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    preRegisteredPatient: state.pre_registered_patient,
});

const mapActionsToProps = {
    paginate: list
};
export default connect(mapStateToProps, mapActionsToProps)(ListPreRegisteredPatientPage)