import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class TempDocOtpService {
    static generateOtp(patientId) {
        const requestOptions = {
            headers: getApiHeader(),
        };
        return axios.get(apiRoute('/v1/generate-otp?patient_id=' + patientId), requestOptions);
    }

    static verifyOtp(patientId, referralId, doctorOtp, patientOtp) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute(`/v1/verify-otp?patient_id=${patientId}&referral_id=${referralId}&nondh_doctor_otp=${doctorOtp}&patient_otp=${patientOtp}`), requestOptions);
    }
}

export default TempDocOtpService;