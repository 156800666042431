import React from 'react';
import * as GeneralComponents from './OverallExamination/General';
import * as RespiratoryComponents from './OverallExamination/Respiratory';
import * as AbdomenComponents from './OverallExamination/Abdomen';
import * as CardiovascularComponents from './OverallExamination/Cardiovascular';
import * as PsychiatricComponents from './OverallExamination/Psychiatric';
import * as MuscleComponents from './OverallExamination/Muscle';
import * as NervousSystemComponents from './OverallExamination/NervousSystem';
import * as EyesComponents from './OverallExamination/Eyes';
import * as EarsComponents from './OverallExamination/Ears';
import * as NoseComponents from './OverallExamination/Nose';
import * as ThroatComponents from './OverallExamination/Throat';
import * as SkinComponents from './OverallExamination/Skin';
import * as GenitaliaComponents from './OverallExamination/Genitalia';
import * as ProstateComponents from './OverallExamination/Prostate';
import * as BreastComponents from './OverallExamination/Breast';
import { find, pull, isEmpty, pullAllBy, findIndex, includes } from 'lodash';

class AddOverallExamination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disable_psychiatric_comment: false,
        }
    }
    handleOverallExaminationChange = (e, category, problem=null) => {
        if(problem){
            this.handleProblemCommentChange(e, category, problem)
        }else{
            let { examination } = this.props;
            let existingData = find(examination, { 'category': category });
            if (e.target.name === 'comments') {
                existingData ? existingData['comments'] = e.target.value : examination.push({ 'category': category, 'comments': e.target.value, 'problems': [], 'problems_comment': [] })
            } else if (e.target.checked) {
                (existingData) ? existingData['problems'].push(e.target.name) : examination.push({ 'category': category, 'problems': [e.target.name], 'comments': '', 'problems_comment': [] });
                if (this.hasTarget(e.target.name) && existingData){
                    existingData['comments'] = '';
                    existingData.problems_comment && existingData.problems_comment.forEach( row => row.comment='' );
                }
            } else {
                if (e.target.name !== 'comments') {
                    pull(existingData['problems'], e.target.name);
                    if (isEmpty(existingData['problems'])){
                        pullAllBy(examination, [{ 'category': category }], 'category');
                    }
                }
            }
            this.props.handleOverallExamination(examination)
        }
    }
    handleProblemCommentChange = (e, category, problem) => {
        let { examination } = this.props;
        let existingData = find(examination, { 'category': category });
        if(e.target.name === 'comment'){
            if(existingData === undefined){
                examination.push({ 'category': category, 'comments': '', 'problems': [], 'problems_comment': [{ 'problem': problem, 'comment': e.target.value }] })
            }else if(existingData.problems_comment === null){
                existingData.problems_comment = [{ 'problem': problem, 'comment': e.target.value }];
            }else if (existingData) {
                let index = findIndex(existingData.problems_comment, row => row.problem === problem)
                index >= 0 ? existingData.problems_comment[index].comment = e.target.value : 
                existingData['problems_comment'].push({ 'problem': problem, 'comment': e.target.value })
            }
        }
        this.props.handleOverallExamination(examination)
    }
    hasTarget = (selectedTarget) => {
        let targets = ['general-normal', 'respiratory-normal', 'abdomen-normal', 'cardiovascular-normal', 'muscle-joints-bones-normal', 'nervous-system-normal', 'eyes-normal', 'ears-normal', 'nose-normal', 'throat-normal', 'skin-normal', 'genitalia-normal', 'prostate-normal', 'breast-normal']
        return targets.some(target => selectedTarget === target);
    }
    getCategory = (category) => {
        let { examination } = this.props;
        return find(examination, { 'category': category });
    }
    checkIfProblemExist = (category, problem) => {
        let existingData = this.getCategory(category);
        let existElement = existingData && Array.isArray(existingData.problems) && existingData.problems.find(element => element === problem)
        return existElement;
    }
    getCategoryComment = (category, problem=null) => {
        let existingData = this.getCategory(category);
        if(problem && existingData){
            let problem_comment = find(existingData.problems_comment, {'problem': problem})
            return problem_comment && problem_comment.comment;
        }
        return existingData && existingData.comments;
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {/* general block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <GeneralComponents.General
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist}
                                />
                            </div>
                            <div className="form-group full">
                                <GeneralComponents.MM
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist}
                                />
                            </div>
                            <div className="form-group full">
                                <GeneralComponents.LN
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist}
                                />
                            </div>
                            <GeneralComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_general_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                    {/* respiratory block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <RespiratoryComponents.Respiratory
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <RespiratoryComponents.ChestExpansion
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <RespiratoryComponents.AirEntry
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <RespiratoryComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_respiratory_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist} />
                        </div>
                    </div>
                    {/* abdomen block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <AbdomenComponents.Abdomen
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <AbdomenComponents.AbdomenOptions
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <AbdomenComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_abdomen_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* cardiovascular block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <CardiovascularComponents.Cardiovascular
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <CardiovascularComponents.Pulse
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <CardiovascularComponents.HS
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <CardiovascularComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_cardiovascular_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                    {/* psychiatric block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <h6>Psychiatric</h6>
                            </div>
                            <div className="form-group full">
                                <PsychiatricComponents.OTTP
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist}
                                />
                            </div>
                            <div className="form-group full">
                                <PsychiatricComponents.Memory
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <PsychiatricComponents.Speech
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <PsychiatricComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                disabled={this.state.disable_psychiatric_comment}
                                getCategoryComment={this.getCategoryComment}
                            />
                        </div>
                    </div>
                    {/* muscle/joints block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <MuscleComponents.Muscle
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <MuscleComponents.MuscleOptions
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <MuscleComponents.Movement
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <MuscleComponents.Power
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <MuscleComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_muscle_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* nervous system */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <NervousSystemComponents.NervousSystem
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <NervousSystemComponents.Gait
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <NervousSystemComponents.Reflex
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <NervousSystemComponents.Power
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <NervousSystemComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_nervous_system_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                    {/* eyes system */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <EyesComponents.Eyes
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <EyesComponents.EyesOptions
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <EyesComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_eyes_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                    {/* Ear system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <EarsComponents.Ears
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <EarsComponents.EarDrum
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <EarsComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_ears_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                    {/* nose system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <NoseComponents.Nose
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <NoseComponents.NoseOptions
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <NoseComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_nose_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist}
                            />
                        </div>
                    </div>
                    {/* throat system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <ThroatComponents.Throat
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ThroatComponents.Erythema
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ThroatComponents.Tonsils
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <ThroatComponents.Comment
                                handleChange={this.handleOverallExaminationChange}
                                // disabled={this.state.disable_throat_comment}
                                getCategoryComment={this.getCategoryComment}
                                checkIfProblemExist={this.checkIfProblemExist} />
                        </div>
                    </div>
                    {/* skin system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <SkinComponents.Skin
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Lesions
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Rashes
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Scars
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Bruising
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Texture
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Color
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Moisture
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Hair
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Nails
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Sensitivity
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <SkinComponents.Comment
                                    handleChange={this.handleOverallExaminationChange}
                                    getCategoryComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                        </div>
                    </div>

                    {/* genitalia system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <GenitaliaComponents.Genitalia
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.AbnormalGrowthOrLumps
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.SkinChange
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.Discharge
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.Sensitivity
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.SoresOrLesions
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.Swelling
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.Testicles
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <GenitaliaComponents.Comment
                                    handleChange={this.handleOverallExaminationChange}
                                    getCategoryComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                        </div>
                    </div>

                    {/* prostate system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <ProstateComponents.Prostate
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.ExternalGenitalia
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.DigitalRectalExam
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.Consistency
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.Sensitivity
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.UrinarySymptoms
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.PSABloodTest
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <ProstateComponents.Comment
                                    handleChange={this.handleOverallExaminationChange}
                                    getCategoryComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                        </div>
                    </div>

                    {/* breast system block */}
                    <div className="col-md-24">
                        <div className="examination-wrapper">
                            <div className="heading">
                                <BreastComponents.Breast
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.ExternalExamination
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.Palpation
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.Consistency
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.Sensitivity
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.NippleDischarge
                                    handleChange={this.handleOverallExaminationChange}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.MammogramOrUltrasound
                                    handleChange={this.handleOverallExaminationChange}
                                    getProblemComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                            <div className="form-group full">
                                <BreastComponents.Comment
                                    handleChange={this.handleOverallExaminationChange}
                                    getCategoryComment={this.getCategoryComment}
                                    checkIfProblemExist={this.checkIfProblemExist} />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="examination-wrapper">
                    <label className="heading-comment">Other Examination:  </label>
                    <textarea className="form-control" name="other_examination_note" onChange={this.props.handleNoteChanges} value={this.props.other_examination_note ? this.props.other_examination_note : ''}></textarea>
                </div>
                <div className="examination-wrapper">
                    <label className="heading-comment">Examination:  </label>
                    <textarea className="form-control" name="examination_note" onChange={this.props.handleNoteChanges} value={this.props.examination_note ? this.props.examination_note : ''}></textarea>
                </div>

                <div className="examination-wrapper">
                    <label className="heading-comment">Investigation:  </label>
                    <textarea className="form-control" name="investigation" onChange={this.props.handleInvestigationChanges} 
                    value={this.props.investigation ? this.props.investigation : ''}></textarea>
                  
                </div>

            </React.Fragment>
        )
    }
}
export default AddOverallExamination