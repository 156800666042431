import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SearchPatient from "../../components/SearchPatient";
import * as loaderAction from '../../actions/loaderAction';
import DataGrid from '../../components/DataGrid';
import * as Helper from "../../utils/helpers";
import PatientService from '../../services/patientService';

class SearchPatientPage extends React.Component {
    columnsPatient = () => {
        return [{
            dataField: 'patient_id',
            text: 'Patient Id',
            hidden: true
        }, {
            dataField: 'full_name',
            text: 'Patient Name'
        }, {
            dataField: 'date_of_birth',
            text: 'Patient DOB',
            formatter: cell => Helper.getDateFormat(cell),
            csvFormatter: cell => Helper.getDateFormat(cell)
        }, {
            dataField: 'contact_number',
            text: 'Patient Contact No'
        }, {
            dataField: 'updated_at',
            text: 'Patient Visited on',
            formatter: cell => Helper.getDateFormat(cell),
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: this.actionFormatter
        }
        ];
    };

    constructor(props) {
        super(props);

        this.state = {
            patient: ''
        };
    }
    
    actionFormatter = (cell, row) => {
        return (
            <>
                <Link to={"/report-list/" + row.patient_id} className="btn btn-secondary">View Report</Link>
            </>
        )
    }

    handlePatientChange = (patient_id) => {
        PatientService.get(patient_id).then(response => {
            let { data } = response;
            let arr=[];
            arr.push(data);
            this.setState({
                patient: arr,
            });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        });
    }

    render() {
        return <div>
            <Helmet>
                <title>Search Patients</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Search Patient</h4>
                    <div className="content-wrapper">
                        <div className="row">
                            <SearchPatient
                                handlePatientSearch={this.handlePatientChange}
                                handlePatientId={this.actionFormatter}
                            ></SearchPatient>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
                <DataGrid
                    noDataIndication="No Record Found"
                    columns={this.columnsPatient()}
                    data={this.state.patient}
                /> 
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(mapStateToProps, mapActionsToProps)(SearchPatientPage);