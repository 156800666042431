import React, { Component } from "react";
import { connect } from "react-redux";
import TestDetails from "../../components/Report/TestDetails";
import PatientCompletedReportDetails from "../../components/Report/PatientCompletedReportDetails";
import { Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";
import ReferralService from "../../services/referralService";
import Notification from "../../utils/notification";
import moment from "moment";
import { getAgeString } from "../../utils/helpers";
import * as loaderActions from '../../actions/loaderAction';
import { hasRole } from '../../utils/auth';

class CompletedReportDetailPage extends Component {
  constructor(props) {
    super(props);
    const params = this.props.match.params;
    this.state = {
      formData: {
        name: "",
        phone: "",
        doctor: "",
        sample_collected_at: "",
        address: "",
        age: "",
        patient_height: "",
        patient_weight: "",
        patient_bmi: "",
        doctor_contact: "",
        sample_collected_on: "",
        created_on: "",
        uploaded_referral: null,
        lab_id: "",
        comments: "",
      },
      testsData: [],
      serviceProviderName: "",
      serviceProviderAddress: "",
      testDetailsValues: [],
      id: params.id,
      isloading: false,
      downloadUrl : '',
    };
    this.validator = new SimpleReactValidator();
    this.testDetailsRef = React.createRef();
  }

  componentDidMount() {
    this.props.loaderShow();
    const id = this.state.id;
    const today_date = moment().format("YYYY-MM-DD");

    ReferralService
      .getReferrals(id)
      .then((resp) => {
        const refData = resp.data;
        let address = refData.address;
        address =
          (address.address_line_1 ? address.address_line_1 + ", " : "") +
          (address.address_line_2 ? address.address_line_2 + ", " : "") +
          (address.address_line_3 ? address.address_line_3 + ", " : "") +
          (address.parish ? address.parish + ", " : "") +
          (address.city ? address.city + ", " : "") +
          (address.pincode ? address.pincode : "");
        this.setState({
          formData: {
            name: refData.name,
            phone: refData.contact_number,
            doctor: refData.doctor_name,
            sample_collected_at: refData.sample_collected_at,
            address: address,
            age: getAgeString(refData.date_of_birth, today_date),
            patient_weight: refData.patient_weight,
            patient_height: refData.patient_height,
            patient_bmi: refData.patient_bmi,
            doctor_contact: refData.doctor_contact_number,
            sample_collected_on: moment(refData.sample_collected_on).format(
              "YYYY-MM-DD"
            ),
            created_on: moment(refData.verified2.verified_at).format(
              "YYYY-MM-DD"
            ),
            uploaded_referral: (refData.uploaded_referral) ? refData.uploaded_referral : null,
            lab_id: refData.lab_id,
            comments: refData.comments,
          },
          testsData: refData.referral_tests,
          serviceProviderName: refData.service_provider.name,
          serviceProviderAddress: refData.service_provider.address
        });
        this.props.loaderHide();
      })
      .catch((error) => {
        Notification.show("error", error.response.data);
        this.props.loaderHide();
      });

      ReferralService
      .getReferralsDownload(id)
      .then((resp) => {
      this.setState({
          downloadUrl : resp.data.download_url
      })
      })
      .catch((error) => {
      Notification.show("error", error.response.data);
      this.props.loaderHide();
      });

  }

  handleTestDetailsChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        return { ...row, ['value']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  handleSampleNosDetailsChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        return { ...row, ['sample_nos']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  handleCheckboxChange(e, name) {
    this.setState({ [name]: e.target.checked });
  }

  sendMail = (e, user) => {
    this.props.loaderShow();
    e.preventDefault();
    const params = this.props.match.params;
    let referralMails = { 'referral_ids': params.id ? [params.id] : [] };
    ReferralService.sendReferralMail(referralMails, user).then(resp => {
      this.setState({ reportId: [] });
      this.props.loaderHide();
      Notification.show("success", { message: 'Mail Sent' });
    }).catch(error => {
      this.setState({ reportId: [] });
      this.props.loaderHide();
      Notification.show("error", error.response.data);
    });
  }

  getPdfLink = (id) => {
		this.setState({
			isloading: true
		});
		ReferralService.getReportDownload(id).then(resp => {
			this.setState({
				isloading: false
			});
			window.open(resp.data.url);
      ReferralService.printReport(id).then(resp => {
      }).catch(error => {
          Notification.show('error', error.response)
      })
		}).catch(error => {
			this.setState({
				isloading: false
			});
			Notification.show('error',console.log(error.response.message));
		});
	}

  render() {
    return (<>
      {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
      <div>
        <div>
          <Helmet>
            <title>Completed Report</title>
          </Helmet>
          <div className="row">
            <div className="col-md-24">
              <h4 className="page-title">Completed Report</h4>
              <div className="content-wrapper add-lab-report">
                <PatientCompletedReportDetails formData={this.state.formData} downloadUrl={this.state.downloadUrl}/>
                <br />
                <TestDetails
                  ref={this.testDetailsRef}
                  onTestDetailsChange={this.handleTestDetailsChange}
                  onSampleNosDetailsChange={this.handleSampleNosDetailsChange}
                  testsData={this.state.testsData}
                  disabledValue={true}
                />
                <br />
                {hasRole(this.props.selected_profile, ["pathology", "pathologist", "pathology-receptionist", "pathology-phlebotomist"]) &&
                  <>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={(e) => this.getPdfLink(this.state.id)}
                  >
                    PRINT
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={(e) => this.sendMail(e, 'doctor')}
                  >
                    EMAIL TO DOCTOR
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={(e) => this.sendMail(e, 'patient')}
                  >
                    EMAIL TO PATIENT
                  </Button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
  }
}

const mapStateToProps = (state) => ({
  selected_profile: state.selected_user_profile,
});

const mapActionsToProps = {
  loaderShow: loaderActions.loaderShow,
  loaderHide: loaderActions.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(CompletedReportDetailPage);