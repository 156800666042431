import React, { Component } from 'react';
import { connect } from "react-redux";
import TestDetails from '../../components/Report/TestDetails';
import { Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import SimpleReactValidator from "simple-react-validator";
import ReferralService from "../../services/referralService";
import Notification from "../../../src/utils/notification";
import moment from "moment";
import _ from "lodash";
import { getAgeString } from "../../../src/utils/helpers";
import * as loaderActions from '../../actions/loaderAction';
import { hasRole } from '../../utils/auth';
import RoleSerivce from '../../services/roleService';
import FileDownloader from "../../components/Report/FileDownloader";
import PatientReportDetailsForMedTech from "../../components/Report/PatientReportDetailsForMedTech";

class LabReportMedTech extends Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            verify1: false,
            verify2: false,
            testDetailsValues: [],
            formData: {
                name: "",
                phone: "",
                doctor: "",
                address: "",
                age: "",
                doctor_contact: "",
                uploaded_referral: null,
                lab_id: "",
                comments: "",
            },
            testsData: [],
            id: params.id,
            verify1_label: '',
            verify2_label: '',
            error: '',
            downloadUrl: ''
        };
        this.validator = new SimpleReactValidator();
        this.testDetailsRef = React.createRef();
        this.referralDetailsRef = React.createRef();
    }

    componentDidMount() {
        this.props.loaderShow();
        const id = this.state.id;
        const today_date = moment().format("YYYY-MM-DD");

        ReferralService
            .getReferrals(id)
            .then((resp) => {
                const refData = resp.data;
                let address = refData.address;
                address =
                    (address.address_line_1 ? address.address_line_1 + ", " : "") +
                    (address.address_line_2 ? address.address_line_2 + ", " : "") +
                    (address.address_line_3 ? address.address_line_3 + ", " : "") +
                    (address.parish ? address.parish + ", " : "") +
                    (address.city ? address.city + ", " : "") +
                    (address.pincode ? address.pincode : "");
                this.setState({
                    formData: {
                        name: refData.name,
                        phone: refData.contact_number,
                        doctor: refData.doctor_name,
                        sample_collected_at: refData.sample_collected_at,
                        address: address,
                        age: getAgeString(refData.date_of_birth, today_date),
                        patient_weight: refData.patient_weight,
                        patient_height: refData.patient_height,
                        patient_bmi: refData.patient_bmi,
                        doctor_contact: refData.doctor_contact_number,
                        doctor_email: refData.doctor_email,
                        sample_collected_on: moment(refData.sample_collected_on).format(
                            "YYYY-MM-DD"
                        ),
                        uploaded_referral: (refData.uploaded_referral) ? refData.uploaded_referral : null,
                        lab_id: refData.lab_id,
                        comments: refData.comments,
                    },
                    verify1: (refData.verified1.verified === true) ? true : false,
                    verify1_label: (refData.verified1.verified === true) ? (refData.verified1.name + ' - ' + RoleSerivce.getRoleNameBySlug(refData.verified1.role)
                    ) : '',
                    verify2_label: (refData.verified2.verified === true) ? (refData.verified2.name + ' - ' + RoleSerivce.getRoleNameBySlug(refData.verified2.role)
                    ) : '',
                    verify1: (refData.verified1.verified === true) ? (true) : false,
                    verify2: (refData.verified2.verified === true) ? (true) : false,
                    testsData: refData.referral_tests,
                });
                this.props.loaderHide();
            })
            .catch((error) => {
                this.props.loaderHide();
                Notification.show("error", error.response.data);
            });

        ReferralService
            .getReferralsDownload(id)
            .then((resp) => {
                this.setState({
                    downloadUrl: resp.data.download_url
                })
            })
            .catch((error) => {
                Notification.show("error", error.response.data);
                this.props.loaderHide();
            });

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };

    handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        this.setState({
            [name]: checked
        });
    };

    handleSubmit = (e) => {
        // Handle submit button click
        this.props.loaderShow();
        e.preventDefault();

        if (this.referralDetailsRef.current) {
            if (!this.referralDetailsRef.current.isValid()) {
                this.props.loaderHide();
                return false;
            }
        }

        if (this.testDetailsRef.current) {
            if (!this.testDetailsRef.current.isValid()) {
                this.props.loaderHide();
                return false;
            }
        }
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            this.props.loaderHide();
            return false;
        }

        let requestData = {
            doctor_name: this.state.formData.doctor,
            doctor_contact_number: this.state.formData.doctor_contact,
            doctor_email: this.state.formData.doctor_email,
            patient_height: this.state.formData.patient_height,
            patient_weight: this.state.formData.patient_weight,
            patient_bmi: this.state.formData.patient_bmi,
            verify: this.props.selected_profile.role_slug === 'pathology-med-tech'
                ? this.state.verify1
                : this.state.verify2,
            tests: this.state.testsData.map((test) => ({
                id: test.id,
                test_id: test.test_id,
                value: test.value,
                sample_nos: test.sample_nos,
                flag: test.flag,
            })),
            lab_id: this.state.formData.lab_id,
            comments: this.state.formData.comments,
        };
        ReferralService.verification(this.state.id, requestData, this.props.selected_profile.role_slug).then(resp => {
            if (requestData.verify === false) {
                Notification.show('warning', { message: 'Data Updated, Please verify the report' });
            } else {
                Notification.show('success', { message: 'Report has been verified' });
            }
            this.props.loaderHide();
            {
                hasRole(this.props.selected_profile, ['pathology-med-tech']) &&
                    this.props.history.push('/processing-patient-report');
            }
            {
                hasRole(this.props.selected_profile, ['pathology-lab-tech']) &&
                    this.props.history.push('/pending-patient-report');
            }

        }).catch(error => {
            Notification.show('error', error.response.message);
            this.props.loaderHide();
        })
    };

    handleSampleNosDetailsChange = (value, rowId) => {
        const updatedData = this.state.testsData.map((row) => {
            if (row.id === rowId) {
                return { ...row, ['sample_nos']: value };
            }
            return row;
        });
        this.setState({
            testsData: updatedData,
        });
    };

    getFlagValue = (lower_limit, upper_limit, val) => {
        if (val == null || isNaN(val) || (val == 0 && lower_limit == null && upper_limit == null)) {
            return null;
        }

        let lowerLimit = parseFloat(lower_limit);
        let upperLimit = parseFloat(upper_limit);
        let value = parseFloat(val);
        let flag = "";

        if (value == 0) {
            flag = "low";
        }
        else {
            if ((lowerLimit == 0 || lowerLimit === null) && upperLimit) {
                if (value > upperLimit) {
                    flag = "high";
                } else {
                    flag = "normal";
                }
            } else if ((upperLimit == 0 || upperLimit === null) && lowerLimit) {
                if (value <= lowerLimit) {
                    flag = "low";
                } else {
                    flag = "normal";
                }
            } else if (!lowerLimit && !upperLimit) {
                flag = "";
            } else {
                if (value >= lowerLimit && value <= upperLimit) {
                    flag = "normal";
                } else if (value <= lowerLimit) {
                    flag = "low";
                } else if (value > upper_limit) {
                    flag = "high";
                }
            }
        }
        return flag;
    };

    handleOnBlurTestDetails = (value, rowId) => {
        const updatedData = this.state.testsData.map((row) => {
            if (row.id === rowId && !row.flag) {
                let flag = this.getFlagValue(row.lims_test.lower_limit, row.lims_test.upper_limit, value);
                return { ...row, ['flag']: flag };
            }
            return row;
        });
        this.setState({
            testsData: updatedData,
        });
    };

    onTestFlagChange = (value, rowId) => {
        const updatedData = this.state.testsData.map((row) => {
            if (row.id === rowId) {
                return { ...row, ['flag']: value };
            }
            return row;
        });
        this.setState({
            testsData: updatedData,
        });
    };

    handleTestDetailsChange = (value, rowId) => {
        const updatedData = this.state.testsData.map((row) => {
            if (row.id === rowId) {
                if (_.isEmpty(value)) {
                    return { ...row, ['value']: value, ['flag']: '' };
                }
                if (!row.flag && row.lims_test.type == "select") {
                    return { ...row, ['value']: value, ['flag']: value };
                }
                return { ...row, ['value']: value };
            }
            return row;
        });
        this.setState({
            testsData: updatedData,
        });
    };

    handleFirstSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
    }

    handleReferralDetailsChange = (name, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }), () => {
            if (['patient_height', 'patient_weight'].includes(name)) {
                let height = parseFloat(this.state.formData.patient_height);
                let weight = parseFloat(this.state.formData.patient_weight);
                let bmi = '';
                if (height && weight)
                    bmi = parseFloat(((weight) / (height * height * 0.0001)).toFixed(2));
                this.setState((prevState) => ({
                    formData: {
                        ...prevState.formData,
                        patient_bmi: bmi,
                    },
                }));
            }
        });
    };

    render() {
        const { verify1, verify2, formData, downloadUrl } = this.state;
        return (
            <div>
                <style>
                    {`
                    .verify {
                        margin-left: -15px !important;
                    }
                    .edit_button {
                        background-color: #1d518a24;
                        color: #1d518a;
                    }
                    `}
                </style>
                <div>
                    <Helmet>
                        <title>Lab Report({RoleSerivce.getRoleNameBySlug(this.props.selected_profile.role_slug)})</title>
                    </Helmet>
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title">Report ({RoleSerivce.getRoleNameBySlug(this.props.selected_profile.role_slug)})</h4>
                            <div className="content-wrapper add-lab-report">
                                <PatientReportDetailsForMedTech ref={this.referralDetailsRef} formData={formData} downloadUrl={downloadUrl} onReferralDetailsChange={this.handleReferralDetailsChange} disabledValue={hasRole(this.props.selected_profile, ['pathology-med-tech', 'pathology-lab-tech']) ? false : true} />
                                <br />
                                {<Form>
                                    <TestDetails ref={this.testDetailsRef} onTestDetailsChange={this.handleTestDetailsChange}
                                        handleOnBlurTestDetails={this.handleOnBlurTestDetails}
                                        onSampleNosDetailsChange={this.handleSampleNosDetailsChange}
                                        testsData={this.state.testsData}
                                        disabledValue={hasRole(this.props.selected_profile, ['pathology-med-tech', 'pathology-lab-tech']) ? false : true}
                                        onTestFlagChange={this.onTestFlagChange}
                                    />
                                    <br />
                                    <br />
                                    {hasRole(this.props.selected_profile, ['pathology-med-tech']) &&
                                        <Form.Check
                                            inline
                                            // label="Verify1"
                                            label={"Verify"}
                                            type="checkbox"
                                            checked={verify1}
                                            name="verify1"
                                            id="verify1"
                                            onChange={this.handleCheckboxChange}
                                            className="verify"
                                        />}
                                    {hasRole(this.props.selected_profile, ['pathology-lab-tech']) &&
                                        <>
                                            <Form.Check
                                                inline
                                                label={this.state.verify1_label}
                                                type="checkbox"
                                                checked={verify1}
                                                name="verify1"
                                                id="verify1"
                                                onChange={this.handleCheckboxChange}
                                                className="verify"
                                                disabled
                                            />
                                            <Form.Check
                                                inline
                                                label={"Verify  " + this.state.verify2_label}
                                                type="checkbox"
                                                checked={verify2}
                                                name="verify2"
                                                id="verify2"
                                                onChange={this.handleCheckboxChange}
                                                className="verify"
                                            />
                                        </>
                                    }
                                    <br />
                                    {this.state.error && <span style={{ color: 'red' }}>{this.state.error}</span>}
                                    <br />
                                    {hasRole(this.props.selected_profile, ['pathology-med-tech', 'pathology-lab-tech']) &&
                                        <Button type="submit" className="submit_button" variant="primary" onClick={this.handleSubmit} size="sm">Submit</Button>
                                    }</Form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selected_profile: state.selected_user_profile,
});

const mapActionsToProps = {
    loaderShow: loaderActions.loaderShow,
    loaderHide: loaderActions.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(LabReportMedTech);
