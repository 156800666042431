import axios from 'axios';
import { apiRoute, createPaginateQueryString, getApiHeader } from '../utils/helpers';

class TempDocReportService {
    static verifiedlist({ page, sizePerPage, sortBy}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({page, sizePerPage, sortBy})
        };

        return axios.get(apiRoute("/v1/reports"), requestOptions);
    }

    static list({ page, sizePerPage, sortBy, patientId}) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({page, sizePerPage, sortBy, patientId})
        };

        return axios.get(apiRoute(`/v1/patient-reports?patient_id=${patientId}`), requestOptions);
    }

    static getReportDownload(reportId){
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute(`/v1/pathology/lims-referrals/${reportId}/report-download`), requestOptions);
    }
}

export default TempDocReportService;