import moment from 'moment';
import calculateAge from 'calculate-age'
import _ from 'lodash';
import * as authUtils from './auth';
import * as Config from '../config';

export function config(key, value = null) {
    return process.env['REACT_APP_' + key];
}

export function apiRoute($url) {
    return config('API_URL') + $url;
}

export function getApiHeader(extraHeader = {}, checkAuth = true) {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    if (checkAuth) {
        let accessToken = authUtils.getSessionToken();
        if (accessToken && accessToken.access_token) {
            headers.Authorization = 'Bearer ' + accessToken.access_token;
        }
    }

    return { ...headers, ...extraHeader };
}

export function getToken() {
    let accessToken = authUtils.getSessionToken();
    if (accessToken && accessToken.access_token) {
        return accessToken.access_token;
    }
}

export function createPaginateQueryString({ page = 1, sizePerPage = 25, sortBy = null, searchText = '', filter = null }) {
    let params = {
        page: page,
        limit: sizePerPage,
    };

    if (sortBy && sortBy.sortField) {
        let field = sortBy.sortField;
        let order = sortBy.sortOrder ? sortBy.sortOrder : 'asc';
        params.sort = field + ':' + order;
    }

    if (searchText) {
        params.search = searchText
    }

    if (filter) {
        Object.keys(filter).forEach(function (key) {
            params[key] = filter[key]
        });
    }

    return params;
}

export function getAgeString($birthDate, $toDate = null) {
    if ($birthDate) {
        try {
            return new calculateAge($birthDate, $toDate).getString()
        } catch (ex) {
            console.log(ex)
        }
    }
}

export function fullNameStr(user) {
    if (user) {
        return user.name_title + ' ' + user.first_name + ' ' + user.last_name;
    }

    return '';
}

export function fullAddressStr(address) {

    if (!_.isEmpty(address)) {
        let addressArr = []

        addressArr.push(address.address_line_1);
        addressArr.push(address.address_line_2 ? address.address_line_2 : '');
        addressArr.push(address.address_line_3 ? address.address_line_3 : '');
        addressArr.push(address.parish ? address.parish : '');
        addressArr.push(address.city ? address.city : '');
        addressArr.push(address.pincode ? address.pincode : '');

        addressArr = _.filter(addressArr);

        return addressArr.join(', ');
    }

    return '';
}

export function getDurationType($type = '') {
    const durationType = {
        'day': '/7',
        'week': '/52',
        'month': '/12',
    };

    if ($type) {
        return durationType[$type];
    }

    return '';
}

export function getDosageType($type = '') {
    const dosageType = {
        'od': 'OD',
        'bd': 'BD/BID',
        'tid': 'TID',
        'qid': 'QID',
        'sos': 'SOS',
    };

    if ($type) {
        return dosageType[$type];
    }

    return '';
}

export function getStatus(status, role = null) {
    if (!status) {
        return "";
    }

    if (status === "prescribed") {
        return "Not Filled";
    } else if (status === "partially-fulfilled") {
        return "Partially Filled";
    } else if (status === "fulfilled") {
        return (role === "pathologist" || role === "radiologist") ? "Completed" : "Filled"
    } else if (status === "refused") {
        return "Refused";
    } else if (status === "conducted") {
        return "Conducted";
    } else if (status === "signature_pending") {
        return "Signature Pending";
    } else if (status === "cancelled") {
        return "Cancelled";
    } else {
        return status;
    }
}

export function getDateFormat(timestamp, format = 'DD MMM YYYY') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function getTimeFormat(timestamp, format = 'hh:mm a') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function getLocatTimezone(timestamp, format = 'hh:mm a') {
    if (!timestamp) {
        return "";
    }
    return moment.utc(timestamp).local().format(format);
}

export function getDateTimeFormat(timestamp, format = 'DD-MM-YYYY HH:mm') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function debugCard(value) {
    let pattern = new RegExp("^(%[^%;\\?]+\\?)?(;[0-9\\:<>\\=]+\\?)?([+;][0-9\\:<>\\=]+\\?)?");
    let match = pattern.exec(value);

    if (!match) return null;

    let cardData = {
        type: "generic",
        line1: match[1] ? match[1].slice(1, -1) : "",
        line2: match[2] ? match[2].slice(1, -1) : "",
        line3: match[3] ? match[3].slice(1, -1) : ""
    };

    return cardData;
}

export function getCardObj(value) {
    let splitted = value.split('^', 3);

    if (splitted[0] && splitted[1] && splitted[2]) {
        return {
            user_code: splitted[0],
            card_number: splitted[1],
            hash: splitted[2],
        };
    }

    return false;
}

export function getCurrentYear() {
    return new Date().getFullYear();
}

export function createFilterStringQuery(filter) {
    const params = {}
    if (filter) {
        Object.keys(filter).forEach(function (key) {
            params[key] = filter[key]
        });
    }

    return params;
}

export function patientInsurance(insurance_types) {

    let insurance = insurance_types && insurance_types.map(value => {
        return _.has(Config.INSURANCE, value) ? Config.INSURANCE[value] : ''
    })
    return _.filter(insurance).join(', ')
}

export function patientStatus(status) {
    if (!status) {
        return "";
    }

    if (status === "alive") {
        return "Alive";
    } else if (status === "dead") {
        return "Dead";
    } else if (status === "dead-in-transit") {
        return "Dead in Transit"
    } else {
        return status;
    }
}

export function personNameRegex() {
    return "^[a-zA-Z]+([',. -]+[a-zA-Z]+)*[a-zA-Z]$";
}


let interval;

export function setAutoSaveWithInterval(selected_user_profile, callback, delay = 60) {
    if (!_.isEmpty(selected_user_profile.service_provider)) {
        interval = setInterval(() => {
            callback()
        }, delay * 1000);
    }
}

export function removeAutoSaveWithInterval() {
    clearInterval(interval);
}    

export function redirectToChat(notify_by_id = null) {
    notify_by_id ? window.open( config('CHAT_URL') + '/auth/'+ getToken() + '/' + notify_by_id) : window.open( config('CHAT_URL') + '/auth/'+ getToken());
}

export function nationalIdRegex() {
    return '^[a-zA-Z0-9]+(([\', -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$';
}