import React, { Component } from "react";
import FileDownloader from "./FileDownloader";
import { hasRole } from '../../utils/auth';
import { connect } from "react-redux";

class PatientReportDetails extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { formData, downloadUrl } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-24">
            <div className="content-wrapper add-lab-report">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="form-control"
                        value={formData.name}
                        onChange={this.handleChange}
                        disabled={true}

                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        className="form-control"
                        value={formData.phone}
                        onChange={this.handleChange}
                        disabled={true}

                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Doctor</label>
                      <input
                        type="text"
                        name="doctor"
                        id="doctor"
                        placeholder="Doctor"
                        className="form-control"
                        value={formData.doctor}
                        onChange={this.handleChange}
                        disabled={true}

                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Sample Collected At</label>
                      <select
                        className="form-control"
                        value={formData.sample_collected_at}
                        id="sample_collected_at"
                        name="sample_collected_at"
                        onChange={this.handleChange}
                        disabled={true}
                      >
                        <option value="" disabled={true}>
                          Select
                        </option>
                        <option value={formData.sample_collected_at}>{formData.sample_collected_at}</option>
                      </select>
                    </fieldset>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        className="form-control"
                        value={formData.address}
                        onChange={this.handleChange}
                        disabled={true}

                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Age</label>
                      <input
                        type="text"
                        name="age"
                        id="age"
                        placeholder="Age"
                        className="form-control"
                        value={formData.age}
                        onChange={this.handleChange}
                        disabled={true}

                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Doctor Contact</label>

                      <input
                        type="text"
                        name="doctor_contact"
                        id="doctor_contact"
                        placeholder="Doctor Contact"
                        className="form-control"
                        value={formData.doctor_contact}
                        onChange={this.handleChange}
                        disabled={true}
                      />
                    </fieldset>
                  </div>

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Doctor Email</label>

                      <input
                        type="text"
                        name="doctor_email"
                        id="doctor_email"
                        placeholder="Doctor Email"
                        className="form-control"
                        value={formData.doctor_email}
                        onChange={this.handleChange}
                        disabled={true}
                      />
                    </fieldset>
                  </div>

                </div>

                <div className="row">

                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Sample Collected On</label>
                      <input
                        type="date"
                        name="sample_collected_on"
                        id="sample_collected_on"
                        className="form-control"
                        value={formData.sample_collected_on}
                        onChange={this.handleChange}
                        disabled={true}

                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Height <small>[in cm]</small></label>
                      <input
                        type="text"
                        name="height"
                        id="height"
                        className="form-control"
                        value={formData.patient_height}
                        disabled={true}

                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Weight <small>[in kgs]</small></label>
                      <input
                        type="text"
                        name="weight"
                        id="weight"
                        className="form-control"
                        value={formData.patient_weight}
                        disabled={true}

                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>BMI</label>
                      <input
                        type="text"
                        name="bmi"
                        id="bmi"
                        className="form-control"
                        value={formData.patient_bmi}
                        disabled={true}

                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <fieldset className="form-group">
                      <label>Lab ID</label>
                      <input
                        type="text"
                        name="lab_id"
                        id="lab_id"
                        className="form-control"
                        value={formData.lab_id}
                        disabled={true}

                      />
                    </fieldset>
                  </div>
                  {hasRole(this.props.selected_profile, ['pathology-med-tech','pathology-lab-tech']) &&
                    <div className="col-sm-6">
                      <fieldset className="form-group">
                        <label>Comments</label>
                        <textarea
                          type="text"
                          name="comments"
                          id="comments"
                          className="form-control"
                          value={formData.comments}
                          disabled={true}

                        />
                      </fieldset>
                    </div>
                  }
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Uploaded Referral</label>
                      <br />
                      {formData.uploaded_referral && <FileDownloader fileUrl={downloadUrl} /> || <span>No Attachment Found</span>}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  selected_profile: state.selected_user_profile,
});

export default connect(mapStateToProps)(PatientReportDetails);
