import React, { Component } from "react";
import { connect } from "react-redux";
import TestDetails from "../../components/Report/TestDetails";
import PatientCompletedReportDetails from "../../components/Report/PatientCompletedReportDetails";
import { Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";
import ReferralService from "../../services/referralService";
import Notification from "../../utils/notification";
import moment from "moment";
import { getAgeString } from "../../utils/helpers";
import * as loaderActions from '../../actions/loaderAction';
import { hasRole } from '../../utils/auth';

class LimsReportDetailPage extends Component {
  constructor(props) {
    super(props);
    const params = this.props.match.params;
    this.state = {
      formData: {
        name: "",
        phone: "",
        doctor: "",
        sample_collected_at: "",
        address: "",
        age: "",
        patient_height: "",
        patient_weight: "",
        patient_bmi: "",
        doctor_contact: "",
        sample_collected_on: "",
        created_on: "",
        uploaded_referral: null,
      },
      testsData: [],
      serviceProviderName: "",
      serviceProviderAddress: "",
      testDetailsValues: [],
      id: params.id,
      isloading: false,
      downloadUrl: '',
    };
    this.validator = new SimpleReactValidator();
    this.testDetailsRef = React.createRef();
  }

  componentDidMount() {
    const id = this.state.id;
    this.getReferrals(id);
    this.getReferralsDownload(id);
  }

  getReferrals = (id) => {
    this.props.loaderShow();
    const today_date = moment().format("YYYY-MM-DD");

    ReferralService
      .getPatientReferral(id)
      .then((resp) => {
        const refData = resp.data;
        let address = refData.address;
        address =
          (address.address_line_1 ? address.address_line_1 + ", " : "") +
          (address.address_line_2 ? address.address_line_2 + ", " : "") +
          (address.address_line_3 ? address.address_line_3 + ", " : "") +
          (address.parish ? address.parish + ", " : "") +
          (address.city ? address.city + ", " : "") +
          (address.pincode ? address.pincode : "");
        this.setState({
          formData: {
            name: refData.name,
            phone: refData.contact_number,
            doctor: refData.doctor_name,
            sample_collected_at: refData.sample_collected_at,
            address: address,
            age: getAgeString(refData.date_of_birth, today_date),
            patient_weight: refData.patient_weight,
            patient_height: refData.patient_height,
            patient_bmi: refData.patient_bmi,
            doctor_contact: refData.doctor_contact_number,
            sample_collected_on: moment(refData.sample_collected_on).format(
              "YYYY-MM-DD"
            ),
            created_on: moment(refData.verified2.verified_at).format(
              "YYYY-MM-DD"
            ),
            uploaded_referral: (refData.uploaded_referral) ? refData.uploaded_referral : null,
          },
          testsData: refData.referral_tests,
          serviceProviderName: refData.service_provider.name,
          serviceProviderAddress: refData.service_provider.address
        });
        this.props.loaderHide();
      })
      .catch((error) => {
        Notification.show("error", error.response.data);
        this.props.loaderHide();
      });
  }

  getReferralsDownload = (id) => {
    ReferralService
      .getPatientReferralsDownload(id)
      .then((resp) => {
        this.setState({
          downloadUrl: resp.data.download_url
        })
      })
      .catch((error) => {
        Notification.show("error", error.response.data);
        this.props.loaderHide();
      });
  }

  getPdfLink = (id) => {
		this.setState({
			isloading: true
		});
		ReferralService.getReportDownload(id).then(resp => {
			this.setState({
				isloading: false
			});
			window.open(resp.data.url);     
		}).catch(error => {
			this.setState({
				isloading: false
			});
			Notification.show('error', error.response.data);
		});
	}
  handleTestDetailsChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        return { ...row, ['value']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  handleCheckboxChange(e, name) {
    this.setState({ [name]: e.target.checked });
  }

  handleSampleNosDetailsChange = (value, rowId) => {
  };

  render() {
    return (<>
      {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
      <div>
        <div>
          <Helmet>
            <title>Report</title>
          </Helmet>
          <div className="row">
            <div className="col-md-24">
              <h4 className="page-title">Report</h4>
              <div className="content-wrapper add-lab-report">
                <PatientCompletedReportDetails formData={this.state.formData} downloadUrl={this.state.downloadUrl} />
                <br />
                <TestDetails
                  ref={this.testDetailsRef}
                  onTestDetailsChange={this.handleTestDetailsChange}
                  testsData={this.state.testsData}
                  disabledValue={true}
                  onSampleNosDetailsChange={this.handleSampleNosDetailsChange}
                />
                <br />
                {hasRole(this.props.selected_profile, ["doctor"]) &&
                  <>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={(e) => this.getPdfLink(this.state.id)}
                  >
                    PRINT
                  </Button>                 
                  </>
                  }               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
  }
}

const mapStateToProps = (state) => ({
  selected_profile: state.selected_user_profile,
});

const mapActionsToProps = {
  loaderShow: loaderActions.loaderShow,
  loaderHide: loaderActions.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(LimsReportDetailPage);