import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form, Row, Col } from 'react-bootstrap';
import Notification from '../../utils/notification';
import LimsTestService from '../../services/limsTestService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { isEmpty, find, _ } from 'lodash';

class LimsTestPriceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            name: "",
            buttonText: "Submit",
            isSubmitDisabled: true,
            show: false,
            isloading: false,
            price_jmd: "0",
            price_usd: "0",
            price_cad: "0",
            price_gbp: "0",
            price_eur: "0",
            selectedTest: [{ id: "", name: "", prices: [], category:"" }],
            selectedTests: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }
    componentDidMount () {
        this.setLimsTestPriceList(this.props);
        this.getLimsTestList();
    }

    getLimsTestList = () => {
        LimsTestService.listAll().then(resp => {
            let data1 = resp.data.data;
            const groupedTests = data1.reduce((acc, test) => {
                if (!acc[test.category]) {
                    acc[test.category] = [];
                }
                acc[test.category].push(test);
                return acc;
            }, {});

            let limsData = [];
            Object.keys(groupedTests).map(function (key, index) {
                let groupTests = groupedTests[key];
                if(groupTests.length >1){
                        const groupedTests1 = groupTests.reduce((acc, test) => {
                            if (!acc[test.name]) {
                                acc[test.name] = [];
                            }
                            acc[test.name].push(test);
                            return acc;
                        }, {});
                    Object.keys(groupedTests1).map(function (key, index) {
                        if(groupedTests1[key].length >1){
                            let findData = groupedTests1[key].find(d1 => d1.new === true)
                            if(findData) {
                                limsData.push(findData);
                            }
                        }else{
                            limsData.push(groupedTests1[key][0]);
                        }
                    });
                }else{
                    limsData.push(groupedTests[key][0]);
                }
            })
            this.setState({
                limsTestPrices: limsData,
                isloading: false
            });
        }).catch(error => {
            this.setState({
                isloading: false,
                buttonText: 'Submit'
            });
            Notification.show('error', error.response.data)
        })
    }

    setLimsTestPriceList = (props) => {
        let limsTestPrice = props.limsTestPrice
        let selectedTest = [];
        selectedTest[0] = limsTestPrice;
        this.setState({  
            selectedTest: limsTestPrice ? selectedTest : [{ id: "", name: "", prices: [], category:"" }],
            buttonText: props.buttonText ? props.buttonText : "Submit",
            isSubmitDisabled: true,
            show: props.show ? props.show : false,
            price_jmd: limsTestPrice ? this.getPrice('JMD') : "0",
            price_usd: limsTestPrice ? this.getPrice('USD') : "0",
            price_cad: limsTestPrice ? this.getPrice('CAD') : "0",
            price_gbp: limsTestPrice ? this.getPrice('GBP') : "0",
            price_eur: limsTestPrice ? this.getPrice('EUR') : "0",
        });
    }

    getPrice = (currency) => {
        let row = this.props.limsTestPrice
        if(row.id){
            let data = row.prices.filter( (items) => items.currency.name === currency )
            return data[0] ? data[0].price : ''
        }
        return ''
    }

    handleChange = e => {
        if(isEmpty(e.target)){
            this.handleSelectTest(e);
        }else{
            this.setState({ [e.target.name]: e.target.value, isSubmitDisabled: false });
        }
        this.setState({ isSubmitDisabled: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const data = {
            id: this.state.selectedTest[0].id ? this.state.selectedTest[0].id : null,
            price_jmd: this.state.price_jmd ? this.state.price_jmd : 0,
            price_usd: this.state.price_usd ? this.state.price_usd : 0,
            price_cad: this.state.price_cad ? this.state.price_cad : 0,
            price_gbp: this.state.price_gbp ? this.state.price_gbp : 0,
            price_eur: this.state.price_eur ? this.state.price_eur : 0,
        }
        this.setState({
            isloading: true
        });

        if(this.state.buttonText === "Update") {
            let message = 'Lims Test Price Updated';

            LimsTestService.update(this.props.limsTestPrice.id, data).then(resp => {
                Notification.show('success', { message: message });
                this.clearState()
                this.props.onSuccess();
            }).catch(error => {
                this.setState({
                    isloading: false,
                    buttonText: 'Update'
                });
                Notification.show('error', error.response.data)
            })
        } else {
            let message = 'Lims Test Price Added';
            LimsTestService.create(data).then(resp => {
                Notification.show('success', { message: message });
                this.clearState()
                this.props.onSuccess();
            }).catch(error => {
                this.setState({
                    isloading: false,
                    buttonText: 'Submit'
                });
                Notification.show('error', error.response.data)
            })
        }
    }

    handleClose = () => {
        this.props.handleClose()
        this.validator.hideMessages();
    }

    clearState = () => {
        this.setState({
            name: "",
            isloading: false,
            price_jmd: "0",
            price_usd: "0",
            price_cad: "0",
            price_gbp: "0",
            price_eur: "0",
        })
    }

    handleSelectTest = (e, idx=null) => {
        const value = e.value;
        let selectedTest = this.state.selectedTest;
        if (value > 0) {
            let limsTestPrice = find(this.state.limsTestPrices, { 'id': value });
            selectedTest[0] = limsTestPrice;

            this.setState({ selectedTest: selectedTest })
        }
    }
    render() {
        let { limsTestPrices, selectedTest } = this.state
        let options = []

        limsTestPrices && limsTestPrices.map( limsTestPrice => {
            options.push({ 'value': limsTestPrice.id, 'label': limsTestPrice.name })
        })
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Modal
                    size='md' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>{this.state.buttonText === "Update" ? "Update" : "Add"} LIMS Test Price</h6>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        LIMS Test Name: *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Select className="basic-single" name="name" options={options}
                                            value={selectedTest[0].id ? options.find(obj => selectedTest[0].id == obj.value) : ''}
                                            onChange={this.handleChange}>
                                        </Select>
                                        {this.validator.message('Lims Test Name', this.state.selectedTest[0].name, 'required')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Price (In JMD): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Price (In JMD)" name="price_jmd" value={this.state.price_jmd} onChange={this.handleChange}/>
                                        {this.validator.message('jmd Price', this.state.price_jmd, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Price (In USD): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Price (In USD)" name="price_usd" value={this.state.price_usd} onChange={this.handleChange}/>
                                        {this.validator.message('usd Price', this.state.price_usd, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Price (In CAD): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Price (In CAD)" name="price_cad" value={this.state.price_cad} onChange={this.handleChange}/>
                                        {this.validator.message('cad Price', this.state.price_cad, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Price (In GBP): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Price (In GBP)" name="price_gbp" value={this.state.price_gbp} onChange={this.handleChange}/>
                                        {this.validator.message('gbp Price', this.state.price_gbp, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col md={24}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={8}>
                                        Price (In EUR): *
                                        </Form.Label>
                                        <Col sm={16}>
                                        <Form.Control type="number" placeholder="Price (In EUR)" name="price_eur" value={this.state.price_eur} onChange={this.handleChange}/>
                                        {this.validator.message('eur Price', this.state.price_eur, 'required|numeric|min:0,num|max:10')}
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={this.handleSubmit}>
                            {this.state.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default LimsTestPriceModal