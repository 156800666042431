import React from 'react';
import { BLOODGROUP } from '../../../../config';
import { isEmpty } from 'lodash';
import PatientBloodPressureTooltip from '../../PatientBloodPressureTooltip';
import PatientBMITooltip from '../../PatientBMITooltip';
import PatientPulseOxTooltip from '../../PatientPulseOxTooltip';
import PatientPulseRateTooltip from '../../PatientPulseRateTooltip';
import PatientRgbTooltip from '../../PatientRgbTooltip';
import PatientTemperatureTooltip from '../../PatientTemperatureTooltip';
import PatientHightTooltip from '../../PatientHeightTooltip';
import PatientWeightTooltip from '../../../PatientWeightTooltip';
import { hasRole } from '../../../../utils/auth';

export const Allergy = ({ allergy, handleChange }) => {
    return (
        <React.Fragment><span className="section-heading">Allergies:</span> <p>
            <textarea className="form-control" name="patient_allergy" value={allergy ? allergy : ''} onChange={handleChange} placeholder="Type allergy"></textarea>
        </p>
        </React.Fragment>
    )
}

export const Comorbidities = ({ comorbidities, handleChange }) => {
    return (
        <React.Fragment><span className="section-heading">Comorbidities:</span> <p>
            <textarea className="form-control" name="patient_comorbidities" value={comorbidities ? comorbidities : ''} onChange={handleChange} placeholder="Type here"></textarea>
        </p>
        </React.Fragment>
    )
}

export const BloodGroup = ({ blood_group, handleChange, selected_user_profile }) => {
    return (
        <React.Fragment>Blood Group: <span>
            {!isEmpty(blood_group) && blood_group}
            {isEmpty(blood_group) && <select className="form-control" value={blood_group} id="patient_blood_group" name="patient_blood_group" onChange={handleChange} disabled={selected_user_profile.role_slug !== 'doctor' ? true : false}>
                <option value="">Unknown</option>
                {Object.keys(BLOODGROUP).map(function (key) {
                    return <option key={key} className="travelcompany-input" value={BLOODGROUP[key]}>
                        {BLOODGROUP[key]}
                    </option>;
                })}
            </select>}
        </span>
        </React.Fragment>
    )
}

export const BloodPressure = ({ patientId, maxBloodPressure, minBloodPressure, handleChange }) => {
    return (
        <React.Fragment>Blood Pressure: <span><input type="number" name="patient_blood_pressure_max" className="form-control" value={maxBloodPressure ? maxBloodPressure : ''} min="0" autoComplete="off" onChange={handleChange} /></span><span> / </span>
            <span><input type="number" name="patient_blood_pressure_min" className="form-control" value={minBloodPressure ? minBloodPressure : ''} min="0" autoComplete="off" onChange={handleChange} /></span> <span>mm of Hg</span>
            <PatientBloodPressureTooltip patientId={patientId} />
        </React.Fragment>
    )
}

export const BMI = ({ patientId, bmi }) => {
    return (
        <React.Fragment>BMI: <span>
            {bmi ? bmi : '--'} </span> <PatientBMITooltip patientId={patientId} />
        </React.Fragment>
    )
}

export const Category = ({ bmiCategory, handleChange, age, gender, genderOther }) => {
    return (
        <React.Fragment>            
            <span>
                { (age>=0 && age <= 19 && gender && gender == "other") ?
                <select className="form-control mr-1 custom-width-120 custom-text-align-left" name="patient_gender_other" onChange={handleChange} value={genderOther}>
                    <option value="" defaultValue>Select Gender</option>
                    <option value="female">Girl</option>
                    <option value="male">Boy</option>
                </select>
                : '' }
            </span>
                Category:   
                <span> {bmiCategory ? bmiCategory : '--'} </span>
        </React.Fragment>
    )
}

export const Height = ({ patientId, height, handleChange, handleBlur }) => {
    return (
        <React.Fragment>
            Height: <span><input type="number" name="patient_height" className="form-control" value={height ? parseFloat(height) : ''} min="0" autoComplete="off" onChange={handleChange} onBlur={handleBlur} /></span> <span>cm</span>
            <PatientHightTooltip patientId={patientId} />
        </React.Fragment>

    )
}

export const PulseOx = ({ patientId, pulse_ox, handleChange, validator }) => {
    return (
        <React.Fragment>Pulse Ox: <span><input type="number" name="patient_pulse_ox" className="form-control" value={pulse_ox ? (pulse_ox) : ''} min="0" autoComplete="off" onChange={handleChange} /></span> <span>SpO2</span>
            <PatientPulseOxTooltip patientId={patientId} />
            {validator.message('Pulse Ox', pulse_ox, 'numeric')}
        </React.Fragment>
    )
}

export const RespiratoryRate = ({ patientId, respiratory_rate, handleChange, validator }) => {
    return (
        <React.Fragment>Respiratory Rate: <span><input type="number" name="patient_respiratory_rate" className="form-control" value={respiratory_rate ? (respiratory_rate) : ''} min="0" autoComplete="off" onChange={handleChange} /></span> <span>BPM</span>
            <PatientPulseRateTooltip patientId={patientId} type="respiratory-rates" />
            {validator.message('Respiratory Rate', respiratory_rate, 'numeric')}
        </React.Fragment>
    )
}

export const PulseRate = ({ patientId, pulse_rate, handleChange, validator }) => {
    return (
        <React.Fragment>Pulse Rate: <span><input type="number" name="patient_pulse_rate" className="form-control" value={pulse_rate ? (pulse_rate) : ''} min="0" autoComplete="off" onChange={handleChange} /></span> <span>BPM</span>
            <PatientPulseRateTooltip patientId={patientId} type="pulse-rates" />
            {validator.message('Pulse Rate', pulse_rate, 'numeric')}
        </React.Fragment>
    )
}

export const Rbg = ({ patientId, rbg, handleChange, validator }) => {
    return (
        <React.Fragment>GMR: <span><input type="number" name="patient_rbg" className="form-control" value={rbg || ''} min="0" autoComplete="off" onChange={handleChange} /></span>
            <PatientRgbTooltip patientId={patientId} />
            {validator.message('rbg', rbg, 'numeric')}
        </React.Fragment>
    )
}

export const Temperature = ({ patientId, temperature, handleChange }) => {
    return (
        <React.Fragment>Temperature: <span><input type="number" name="patient_temperature" className="form-control" value={temperature ? temperature : ''} min="0" autoComplete="off" onChange={handleChange} /></span> <span>F</span>
            <PatientTemperatureTooltip patientId={patientId} />
        </React.Fragment>
    )
}

export const Weight = ({ patientId, weight, handleChange, handleBlur }) => {
    return (
        <React.Fragment>Weight: <span><input type="number" name="patient_weight" className="form-control" value={weight ? weight : ''} min="0" autoComplete="off" onChange={handleChange} onBlur={handleBlur} /></span> <span>kgs</span>
            <PatientWeightTooltip patientId={patientId} />
        </React.Fragment>
    )
}

export const Urinalysis = ({ urinalysis, handleChange }) => {
    return (
        <React.Fragment><span className="section-heading">Urinalysis:</span> <p>
            <input className="form-control" name="patient_urinalysis" value={urinalysis ? urinalysis : ''} onChange={handleChange} placeholder="Urinalysis" />
        </p>
        </React.Fragment>
    )
}

export const NurseRemark = ({ nurse_remark, handleChange, selected_user_profile }) => {
    return (
        <React.Fragment><span className="section-heading">Nurse Remark:</span> <p>
            <input className="form-control" name="nurse_remark" value={nurse_remark ? nurse_remark : ''} onChange={handleChange} placeholder="Nurse Remark" disabled={!hasRole(selected_user_profile, ["doctor-nurse"]) ? 'disabled' : ''} />
        </p>
        </React.Fragment>
    )
}

