export const RESET_ALL = 'RESET_ALL';

export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_WARNING = 'ALERT_WARNING';

export const SHOW_PAGE_LOADER = 'SHOW_PAGE_LOADER';
export const HIDE_PAGE_LOADER = 'HIDE_PAGE_LOADER';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const PROFILE_RESET = 'PROFILE_RESET';
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST';
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS';
export const PATIENT_LIST_FAILED = 'PATIENT_LIST_FAILED';
export const PATIENT_LIST_RESET = 'PATIENT_LIST_RESET';

export const PHARMACY_LIST_REQUEST = 'PHARMACY_LIST_REQUEST';
export const PHARMACY_LIST_SUCCESS = 'PHARMACY_LIST_SUCCESS';
export const PHARMACY_LIST_FAILED = 'PHARMACY_LIST_FAILED';

export const MEDICINE_LIST_REQUEST = 'MEDICINE_LIST_REQUEST';
export const MEDICINE_LIST_SUCCESS = 'MEDICINE_LIST_SUCCESS';
export const MEDICINE_LIST_FAILED = 'MEDICINE_LIST_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE';

export const ADD_PATIENT = 'ADD_PATIENT';
export const FETCH_PATIENT = 'FETCH_PATIENT';
export const RESET_PATIENT = 'RESET_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';

// export const VERIFY_PERSCRIPTION = 'VERIFY_PERSCRIPTION';
export const DOCTOR_SELECT = 'DOCTOR_SELECT';

export const RESET_PERSCRIPTION = 'RESET_PERSCRIPTION';
export const FETCH_PERSCRIPTION = 'FETCH_PERSCRIPTION';
export const LIST_PERSCRIPTION = 'LIST_PERSCRIPTION';
export const FILTER_PERSCRIPTION = 'FILTER_PERSCRIPTION';
export const CLEAR_LIST_PERSCRIPTION = 'CLEAR_LIST_PERSCRIPTION';
export const APPROVE_PERSCRIPTION = 'APPROVE_PERSCRIPTION';
export const LIST_PRESCRIPTION_REQUEST = 'LIST_PRESCRIPTION_REQUEST';

export const RESET_PERSCRIPTION_ORDER = 'RESET_PERSCRIPTION_ORDER';
export const VERIFY_PERSCRIPTION_ORDER = 'VERIFY_PERSCRIPTION_ORDER';
export const FILTER_PERSCRIPTION_ORDER = 'FILTER_PERSCRIPTION_ORDER';
export const LIST_PERSCRIPTION_ORDER = 'LIST_PERSCRIPTION_ORDER';
export const FETCH_PERSCRIPTION_ORDER = 'FETCH_PERSCRIPTION_ORDER';

export const FETCH_PATIENT_WEIGHT_DATA = 'FETCH_PATIENT_WEIGHT_DATA';
export const FETCH_PATIENT_BLOOD_PRESSURE_DATA = 'FETCH_PATIENT_BLOOD_PRESSURE_DATA';

export const SEARCH_PATIENT = 'SEARCH_PATIENT';

export const REQUEST_COMMISSIONS = "REQUEST_COMMISSIONS";
export const LIST_COMMISSIONS = "LIST_COMMISSIONS";

export const CLEAR_PROFILE_SELECT = 'CLEAR_PROFILE_SELECT';
export const PROFILE_SELECT = 'PROFILE_SELECT';

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const USER_MANAGEMENT_LIST_REQUEST = 'USER_MANAGEMENT_LIST_REQUEST';
export const USER_MANAGEMENT_LIST_SUCCESS = 'USER_MANAGEMENT_LIST_SUCCESS';
export const USER_MANAGEMENT_LIST_FAILED = 'USER_MANAGEMENT_LIST_FAILED';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';


export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export const PATIENT_PATHOLOGY_TEST_LIST_REQUEST = 'PATIENT_PATHOLOGY_TEST_LIST_REQUEST';
export const PATIENT_PATHOLOGY_TEST_LIST_SUCCESS = 'PATIENT_PATHOLOGY_TEST_LIST_SUCCESS';
export const PATIENT_PATHOLOGY_TEST_LIST_FAILED = 'PATIENT_PATHOLOGY_TEST_LIST_FAILED';

export const PATIENT_RADIOLOGY_TEST_LIST_REQUEST = 'PATIENT_RADIOLOGY_TEST_LIST_REQUEST';
export const PATIENT_RADIOLOGY_TEST_LIST_SUCCESS = 'PATIENT_RADIOLOGY_TEST_LIST_SUCCESS';
export const PATIENT_RADIOLOGY_TEST_LIST_FAILED = 'PATIENT_RADIOLOGY_TEST_LIST_FAILED';

export const LIST_PATHOLOGY_ORDER_SUCCESS = 'LIST_PATHOLOGY_ORDER_SUCCESS';
export const LIST_PATHOLOGY_ORDER_REQUEST = 'LIST_PATHOLOGY_ORDER_REQUEST';
export const LIST_PATHOLOGY_ORDER_FAILED = 'LIST_PATHOLOGY_ORDER_FAILED';

export const LIST_RADIOLOGY_ORDER_REQUEST = 'LIST_RADIOLOGY_ORDER_REQUEST';
export const LIST_RADIOLOGY_ORDER_SUCCESS = 'LIST_RADIOLOGY_ORDER_SUCCESS';
export const LIST_RADIOLOGY_ORDER_FAILED = 'LIST_RADIOLOGY_ORDER_FAILED';

export const LIST_RESET = 'LIST_RESET';

export const FETCH_CHANGED_PRESCRIPTION_REQUEST = 'FETCH_CHANGED_PRESCRIPTION_REQUEST';
export const FETCH_CHANGED_PRESCRIPTION_SUCCESS = 'FETCH_CHANGED_PRESCRIPTION_SUCCESS';
export const FETCH_CHANGED_PRESCRIPTION_FAILED = 'FETCH_CHANGED_PRESCRIPTION_FAILED';
export const CLEAR_CHANGED_PRESCRIPTION = 'CLEAR_CHANGED_PRESCRIPTION';

export const LIST_USER_NOTIFICATION_REQUEST = 'LIST_USER_NOTIFICATION_REQUEST';
export const LIST_USER_NOTIFICATION_SUCCESS = 'LIST_USER_NOTIFICATION_SUCCESS';
export const LIST_USER_NOTIFICATION_FAILED = 'LIST_USER_NOTIFICATION_FAILED';
export const CLEAR_USER_NOTIFICATION_LIST = 'CLEAR_USER_NOTIFICATION_LIST';

export const FETCHED_ID = 'FETCHED_ID';
export const FETCH_OFFLINE_PRESCRIPTION = 'FETCH_OFFLINE_PRESCRIPTION';
export const LIST_INVOICES = 'LIST_INVOICES';
export const LIST_STATEMENTS = 'LIST_STATEMENTS';

export const DOCTOR_DASHBOARD_FILTERS = 'DOCTOR_DASHBOARD_FILTERS';
export const PHARMACY_DASHBOARD_FILTERS = 'PHARMACY_DASHBOARD_FILTERS';
export const PATHOLOGY_DASHBOARD_FILTERS = 'PATHOLOGY_DASHBOARD_FILTERS';
export const RADIOLOGY_DASHBOARD_FILTERS = 'RADIOLOGY_DASHBOARD_FILTERS';

export const LIST_PRESCRIPTION_FEES_REQUEST = 'LIST_PRESCRIPTION_FEES_REQUEST';
export const LIST_PRESCRIPTION_FEES_SUCCESS = 'LIST_PRESCRIPTION_FEES_SUCCESS';
export const LIST_PRESCRIPTION_FEES_FAILED = 'LIST_PRESCRIPTION_FEES_FAILED';

export const PRICE_LIST_REQUEST = 'PRICE_LIST_REQUEST';
export const PRICE_LIST_SUCCESS = 'PRICE_LIST_SUCCESS';
export const PRICE_LIST_FAILED = 'PRICE_LIST_FAILED';

export const PENDING_CARD_LIST = 'PENDING_CARD_LIST';
export const HISTORY_LIST = 'HISTORY_LIST';
export const USER_ACTIVITY_LOG = 'USER_ACTIVITY_LOG';

export const PENDING_SIGNATURE_PRESCRIPTION_LIST_REQUEST = 'PENDING_SIGNATURE_PRESCRIPTION_LIST_REQUEST';
export const PENDING_SIGNATURE_PRESCRIPTION_LIST_SUCCESS = 'PENDING_SIGNATURE_PRESCRIPTION_LIST_SUCCESS';
export const PENDING_SIGNATURE_PRESCRIPTION_LIST_FAILED = 'PENDING_SIGNATURE_PRESCRIPTION_LIST_FAILED';

export const IMMUNIZATION_LIST_REQUEST = 'IMMUNIZATION_LIST_REQUEST';
export const IMMUNIZATION_LIST_SUCCESS = 'IMMUNIZATION_LIST_SUCCESS';
export const IMMUNIZATION_LIST_FAILED = 'IMMUNIZATION_LIST_FAILED';

export const MEDICAL_CERTIFICATE_LIST_REQUEST = 'MEDICAL_CERTIFICATE_LIST_REQUEST';
export const MEDICAL_CERTIFICATE_LIST_SUCCESS = 'MEDICAL_CERTIFICATE_LIST_SUCCESS';
export const MEDICAL_CERTIFICATE_LIST_FAILED = 'MEDICAL_CERTIFICATE_LIST_FAILED';
export const MEDICAL_CERTIFICATE_LIST_RESET = 'MEDICAL_CERTIFICATE_LIST_RESET';

export const USER_NOTIFICATION_COUNT = 'USER_NOTIFICATION_COUNT';
export const USER_NOTIFICATION_COUNT_RESET = 'USER_NOTIFICATION_COUNT_RESET';
export const PRESCRIPTION_ORDER_REMINDER_LIST = 'PRESCRIPTION_ORDER_REMINDER_LIST';

export const APPOINTMENT_LIST_REQUEST = 'APPOINTMENT_LIST_REQUEST';
export const APPOINTMENT_LIST_SUCCESS = 'APPOINTMENT_LIST_SUCCESS';
export const APPOINTMENT_LIST_FAILED = 'APPOINTMENT_LIST_FAILED';

export const SERVICE_PROVIDER_LOCATION_REQUEST = 'SERVICE_PROVIDER_LOCATION_REQUEST';
export const SERVICE_PROVIDER_LOCATION_SUCCESS = 'SERVICE_PROVIDER_LOCATION_SUCCESS';
export const SERVICE_PROVIDER_LOCATION_FAILED = 'SERVICE_PROVIDER_LOCATION_FAILED';

export const HOLIDAY_LIST_REQUEST = 'HOLIDAY_LIST_REQUEST';
export const HOLIDAY_LIST_SUCCESS = 'HOLIDAY_LIST_SUCCESS';
export const HOLIDAY_LIST_FAILED = 'HOLIDAY_LIST_FAILED';

export const AMBULANCE_RECORD_REQUEST = 'AMBULANCE_RECORD_REQUEST';
export const AMBULANCE_RECORD_SUCCESS = 'AMBULANCE_RECORD_SUCCESS';
export const AMBULANCE_RECORD_FAILED = 'AMBULANCE_RECORD_FAILED';

export const FETCH_AMBULANCE_RECORD_REQUEST = 'FETCH_AMBULANCE_RECORD_REQUEST';
export const FETCH_AMBULANCE_RECORD_SUCCESS = 'FETCH_AMBULANCE_RECORD_SUCCESS';
export const FETCH_AMBULANCE_RECORD_FAILED = 'FETCH_AMBULANCE_RECORD_FAILED';

export const CLEAR_HOSPITAL_PATIENT_LIST = 'CLEAR_HOSPITAL_PATIENT_LIST';
export const HOSPITAL_PATIENT_LIST_REQUEST = 'HOSPITAL_PATIENT_LIST_REQUEST';
export const HOSPITAL_PATIENT_LIST_SUCCESS = 'HOSPITAL_PATIENT_LIST_SUCCESS';
export const HOSPITAL_PATIENT_LIST_FAILED = 'HOSPITAL_PATIENT_LIST_FAILED';

export const PATIENT_HISTORIC_DATA_LIST_REQUEST = 'PATIENT_HISTORIC_DATA_LIST_REQUEST';
export const PATIENT_HISTORIC_DATA_SUCCESS = 'PATIENT_HISTORIC_DATA_SUCCESS';
export const PATIENT_HISTORIC_DATA_FAILED = 'PATIENT_HISTORIC_DATA_FAILED';

export const UPLOADED_HISTORIC_DATA_LIST_REQUEST = 'UPLOADED_HISTORIC_DATA_LIST_REQUEST';
export const UPLOADED_HISTORIC_DATA_LIST_SUCCESS = 'UPLOADED_HISTORIC_DATA_LIST_SUCCESS';
export const UPLOADED_HISTORIC_DATA_LIST_FAILED = 'UPLOADED_HISTORIC_DATA_LIST_FAILED';

export const COVID_REPORT_LIST_REQUEST = 'COVID_REPORT_LIST_REQUEST';
export const COVID_REPORT_LIST_SUCCESS = 'COVID_REPORT_LIST_SUCCESS';
export const COVID_REPORT_LIST_FAILED = 'COVID_REPORT_LIST_FAILED';

export const LIST_URGENT_CARE = 'LIST_URGENT_CARE';
export const CLEAR_LIST_URGENT_CARE = 'CLEAR_LIST_URGENT_CARE';

export const LIST_URGENT_CARE_APPROVAL = 'LIST_URGENT_CARE_APPROVAL';
export const CLEAR_LIST_URGENT_CARE_APPROVAL = 'CLEAR_LIST_URGENT_CARE_APPROVAL';

export const PROCEDURE_LIST_REQUEST = 'PROCEDURE_LIST_REQUEST';
export const PROCEDURE_LIST_SUCCESS = 'PROCEDURE_LIST_SUCCESS';
export const PROCEDURE_LIST_FAILED = 'PROCEDURE_LIST_FAILED';

export const DATA_MANAGEMENT_LIST_REQUEST = 'DATA_MANAGEMENT_LIST_REQUEST';
export const DATA_MANAGEMENT_LIST_SUCCESS = 'DATA_MANAGEMENT_LIST_SUCCESS';
export const DATA_MANAGEMENT_LIST_FAILED = 'DATA_MANAGEMENT_LIST_FAILED';

export const PROFILE_EDIT_QUEUE_LIST_REQUEST = 'PROFILE_EDIT_QUEUE_LIST_REQUEST';
export const PROFILE_EDIT_QUEUE_LIST_SUCCESS = 'PROFILE_EDIT_QUEUE_LIST_SUCCESS';
export const PROFILE_EDIT_QUEUE_LIST_FAILED = 'PROFILE_EDIT_QUEUE_LIST_FAILED';

export const PROFILE_EDIT_QUEUE_ADD_REQUEST = 'PROFILE_EDIT_QUEUE_ADD_REQUEST';
export const PROFILE_EDIT_QUEUE_ADD_SUCCESS = 'PROFILE_EDIT_QUEUE_ADD_SUCCESS';
export const PROFILE_EDIT_QUEUE_ADD_FAILED = 'PROFILE_EDIT_QUEUE_ADD_FAILED';

export const PROFILE_EDIT_QUEUE_UPDATE_REQUEST = 'PROFILE_EDIT_QUEUE_UPDATE_REQUEST';
export const PROFILE_EDIT_QUEUE_UPDATE_SUCCESS = 'PROFILE_EDIT_QUEUE_UPDATE_SUCCESS';
export const PROFILE_EDIT_QUEUE_UPDATE_FAILED = 'PROFILE_EDIT_QUEUE_UPDATE_FAILED';

export const FETCH_PROFILE_EDIT_QUEUE = 'FETCH_PROFILE_EDIT_QUEUE';

export const PROFILE_EDIT_QUEUE_ADD = 'PROFILE_EDIT_QUEUE_ADD';
export const PROFILE_EDIT_QUEUE_FETCH = 'PROFILE_EDIT_QUEUE_FETCH';
export const PROFILE_EDIT_QUEUE_RESET = 'PROFILE_EDIT_QUEUE_RESET';
export const PROFILE_EDIT_QUEUE_UPDATE = 'PROFILE_EDIT_QUEUE_UPDATE';

export const PRE_REGISTERED_PATIENT_LIST_REQUEST = 'PRE_REGISTERED_PATIENT_LIST_REQUEST';
export const PRE_REGISTERED_PATIENT_LIST_SUCCESS = 'PRE_REGISTERED_PATIENT_LIST_SUCCESS';
export const PRE_REGISTERED_PATIENT_LIST_FAILED = 'PRE_REGISTERED_PATIENT_LIST_FAILED';

export const LIMS_TEST_LIST_REQUEST = 'LIMS_TEST_LIST_REQUEST';
export const LIMS_TEST_LIST_SUCCESS = 'LIMS_TEST_LIST_SUCCESS';
export const LIMS_TEST_LIST_FAILED = 'LIMS_TEST_LIST_FAILED';
export const LIMS_TEST_LIST_RESET = 'LIMS_TEST_LIST_RESET';

export const NEW_REPORT_REQUEST = 'NEW_REPORT_REQUEST';
export const NEW_REPORT_SUCCESS = 'NEW_REPORT_SUCCESS';
export const NEW_REPORT_FAILED = 'NEW_REPORT_FAILED';

export const PENDING_REPORT_REQUEST = 'PENDING_REPORT_REQUEST';
export const PENDING_REPORT_SUCCESS = 'PENDING_REPORT_SUCCESS';
export const PENDING_REPORT_FAILED = 'PENDING_REPORT_FAILED';

export const PROCESSING_REPORT_REQUEST = 'PROCESSING_REPORT_REQUEST';
export const PROCESSING_REPORT_SUCCESS = 'PROCESSING_REPORT_SUCCESS';
export const PROCESSING_REPORT_FAILED = 'PROCESSING_REPORT_FAILED';

export const COMPLETED_REPORT_REQUEST = 'COMPLETED_REPORT_REQUEST';
export const COMPLETED_REPORT_SUCCESS = 'COMPLETED_REPORT_SUCCESS';
export const COMPLETED_REPORT_FAILED = 'COMPLETED_REPORT_FAILED';

export const PRINTED_REPORT_REQUEST = 'PRINTED_REPORT_REQUEST';
export const PRINTED_REPORT_SUCCESS = 'PRINTED_REPORT_SUCCESS';
export const PRINTED_REPORT_FAILED = 'PRINTED_REPORT_FAILED';

export const DONE_REPORT_REQUEST = 'DONE_REPORT_REQUEST';
export const DONE_REPORT_SUCCESS = 'DONE_REPORT_SUCCESS';
export const DONE_REPORT_FAILED = 'DONE_REPORT_FAILED';

export const DOCTOR_LIST_REQUEST = 'DOCTOR_LIST_REQUEST';
export const DOCTOR_LIST_SUCCESS = 'DOCTOR_LIST_SUCCESS';
export const DOCTOR_LIST_FAILED = 'DOCTOR_LIST_FAILED';
export const DOCTOR_LIST_RESET = 'DOCTOR_LIST_RESET';

export const LIMS_MASTER_TEST_LIST_REQUEST = 'LIMS_MASTER_TEST_LIST_REQUEST';
export const LIMS_MASTER_TEST_LIST_SUCCESS = 'LIMS_MASTER_TEST_LIST_SUCCESS';
export const LIMS_MASTER_TEST_LIST_FAILED = 'LIMS_MASTER_TEST_LIST_FAILED';
export const LIMS_MASTER_TEST_LIST_RESET = 'LIMS_MASTER_TEST_LIST_RESET';