import React from 'react';
import { Link } from "react-router-dom";
import _ from "lodash";
import { Modal, Button } from 'react-bootstrap';
import DataGrid from '../DataGrid';
import Notification from "../../utils/notification";
import ReferralService from '../../services/referralService';

class PatientLimsLabReport extends React.Component {
    state = {
        show: false,
        patientOtp: '',
        selectedReferral: ''
    }
    handleReportUploadModal = () => {
        this.setState({ show_upload_document_modal: !this.state.show_upload_document_modal });
    }

    columns = () => {
        console.log("history", this.props.history);
        return [
            {
                dataField: "sample_collected_on",
                text: "Date Received",
                sort: true,
                formatter: this.dateTimeFormat,
            },
            {
                dataField: "doctor_name",
                text: "Referred Doctor",
                sort: false,
            },
            {
                dataField: "sample_collected_at",
                text: "Sample Collected At",
                sort: false,
            },
            {
                dataField: "verified1.name",
                text: "First Verified",
                sort: false,
            },
            {
                dataField: "verified2.name",
                text: "Second Verified",
                sort: false,
            },
            {
                dataField: "status",
                text: "Status",
                formatter: this.statusFormatter,
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: this.actionFormatter,
            },
        ];
    }

    statusFormatter = (row) => {
        return _.capitalize(row);
    };

    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>
                {this.props.auth ?
                    <Link onClick={e => this.openModel(row.id)} title="View">
                        <span className="icon file"></span>
                    </Link>
                    :
                    <Link to={"/patients/" + this.props.patient.patient_id + "/lims-referrals/" + row.id} title="View">
                        <span className="icon file"></span>
                    </Link>
                }
            </React.Fragment>
        );
    };

    openModel = (referralId) => {
        this.setState({ selectedReferral: referralId, show: true }, () => {
            this.generateOtp()
        });
    };

    generateOtp = () => {
        ReferralService.generateOtp(this.props.patient.patient_id).then(response => {
            Notification.show('success', { message: "Otp sent" })
        }).catch(error => {
            if (error.response) {
                Notification.show('error', error.response.data)
            }
        });
    };

    handleSubmit = () => {
        let data = {
            "patient_id": this.props.patient.patient_id,
            "referral_id": this.state.selectedReferral,
            "patient_otp": this.state.patientOtp
        }
        ReferralService.verifyOtp(data).then(response => {
            Notification.show('success', { message: "Otp Verified Successfully" })
            this.props.history.push("/patients/" + this.props.patient.patient_id + "/lims-referrals/" + this.state.selectedReferral)
        }).catch(error => {
            if (error.response) {
                Notification.show('error', error.response.data)
            }
        });
    };

    handleOTPChange = (event) => {
        this.setState({ patientOtp: event.target.value });
    };

    handleHideModal = (event) => {
        this.setState({ show: false });
    };

    render() {
        return (
            <React.Fragment>
                {this.props.limsReports && <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={this.props.limsReports.data}
                        meta={this.props.limsReports.meta}
                        noDataIndication="No records found"
                        handleTableChange={this.props.handlePatientsLimsLabReportTableChange}
                    />
                </div>}

                <Modal size='md' centered aria-labelledby="contained-modal-title-vcenter" show={this.state.show} backdrop="static"
                    keyboard={false}>
                    <Modal.Header>
                        <h6>Authentication</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h6>Enter Patient's OTP</h6>
                            <input className="form-control" type="text" value={this.state.patientOtp} maxLength={6} onChange={this.handleOTPChange} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleSubmit}>
                            Verify
                        </Button>
                        <Button variant="outline-primary" onClick={this.handleHideModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}
export default PatientLimsLabReport