import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import Notification from '../../utils/notification';
import * as loaderAction from '../../actions/loaderAction';
import SimpleReactValidator from 'simple-react-validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import LimsTestService from '../../services/limsTestService';
import { pathologyLimsTestAdd } from '../../actions/limsTestAction';

class UploadPathologyLimsTestPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            file: ''
        };
        this.validator = new SimpleReactValidator();
        this.fileInput = React.createRef();
    }

    componentDidMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (this.state.file[0] && /\.(csv|CSV)$/i.test(this.state.file[0].name) === false) {
            Notification.show('error', { message: 'Only CSV file is allowed to upload' });
            return false;
        }
        const fd = new FormData();
        fd.append('file', this.state.file[0]);
        this.props.showLoader();

        LimsTestService.updatePathologyLimsTest(fd).then(resp => {
            Notification.show('success', resp.data)
            this.resetForm();
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data)
            }
        })
    }

    resetForm = () => {
        this.setState({ file: '' });
        this.validator.hideMessages();
        if (this.fileInput.current) {
            this.fileInput.current.value = null;
            this.forceUpdate();
        }
    }

    downloadTestCSV = () => {
        LimsTestService.downloadPathologyLimsTest().then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "LimsTestList.csv")
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(error => {
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data)
            }
        })
    }

    handleFileUpload = (e) => {
        this.setState({ file: e.target.files })
    }

    render() {
        this.validator.purgeFields();
        return (<div>
            <Helmet>
                <title>Upload Lims Tests</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <div className='page-title'>
                        <span>Upload Lims Tests</span>
                    </div>
                    <div className="content-wrapper upload-test">

                        <div className="col-md-24 select-chart-dropdown d-flex">
                            <label className='wc-200'>Download Sample File</label>
                            <div className="form-group ">
                                <button onClick={() => this.downloadTestCSV()} className="btn btn-link" name="download" id="download"> <FontAwesomeIcon icon={faDownload} /> &nbsp; Download</button>
                            </div>
                        </div>

                        <div className="col-md-24 select-chart-dropdown d-flex">
                            <label className='wc-200'>Upload</label>
                            <div className="form-group">
                                <div>
                                    <input type="file" ref={this.fileInput} onChange={this.handleFileUpload} accept=".csv" />
                                </div>
                                <div className='mt-2'>{this.validator.message('file', this.state.file, 'required')}</div>
                            </div>
                        </div>

                        <div className="col-md-24 select-chart-dropdown d-flex">
                            <small>Note: Please do not add new test and avoid altering categories or test names in downloaded CSV.</small>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-24">
                                <button type="button" className="btn btn-primary mr-2" onClick={this.handleSubmit}>Submit</button>
                                <Link to="/pathology-lims-tests" className="btn btn-secondary" >Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert,
});

const mapActionsToProps = {
    pathologyLimsTestAdd: pathologyLimsTestAdd,
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(mapStateToProps, mapActionsToProps)(UploadPathologyLimsTestPage);
