import * as types from './actionTypes';
import ReferralService from "../services/referralService";
import Notification from '../utils/notification';

export function list({page, sizePerPage, sortBy, searchText, filter}) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.NEW_REPORT_REQUEST, request: { page, sizePerPage, sortBy, searchText, filter} });

        ReferralService.getNewReferrals({page, sizePerPage, sortBy, searchText, filter})
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.NEW_REPORT_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER ' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}

export function draftList({page, sizePerPage, sortBy, searchText, filter}) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.NEW_REPORT_REQUEST, request: { page, sizePerPage, sortBy, searchText, filter} });

        ReferralService.getDraftReferrals({page, sizePerPage, sortBy, searchText, filter})
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.NEW_REPORT_SUCCESS, ...data });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER ' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}
