import React from 'react';
import { Helmet } from "react-helmet";
import _ from "lodash";
import { Tab, Tabs } from 'react-bootstrap';
import LabLimsTests from '../../components/Lims/LabLimsTests';
import MasterLimsTests from '../../components/Lims/MasterLimsTests';

class PathologyLimsTestListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>LIMS Test List</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-24">
                        <div className='page-title'>
                            <span>LIMS Test List</span>
                        </div>
                        <div className="content-wrapper" id="lims-tests">
                            <Tabs
                                defaultActiveKey="lims-tests"
                            >
                                <Tab eventKey="lims-tests" title="Lims Tests" className='width-100'>
                                    <div className="content-wrapper pt-4">
                                        <LabLimsTests />
                                    </div>
                                </Tab>
                                <Tab eventKey="master-lims-tests" title="Master Lims Tests" className='width-100'>
                                    <div className="content-wrapper pt-4">
                                        <MasterLimsTests />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default PathologyLimsTestListPage