export const roles = [
    {
        name: "Doctor",
        slug: "doctor",
        provider: "doctor",
        assign_to: ["doctor-nurse", "junior-doctor"]
    },
    {
        name: "Associate Doctor",
        slug: "associate-doctor",
        provider: "doctor",
        assign_to: ["doctor-nurse", "junior-doctor"]
    },
    {
        name: "Medical Practice Manager",
        slug: "medical-practice-manager",
        provider: "doctor"
    },
    {
        name: "Doctor's Assistant",
        slug: "junior-doctor",
        provider: "doctor"
    },
    {
        name: "Receptionist",
        slug: "doctor-receptionist",
        provider: "doctor"
    },
    {
        name: "Nurse",
        slug: "doctor-nurse",
        provider: "doctor"
    },
    {
        name: "Pharmacist",
        slug: "pharmacist",
        provider: "pharmacy"
    },
    {
        name: "Technician",
        slug: "pharmacy-technician",
        provider: "pharmacy"
    },
    {
        name: 'Pharmacy Manager',
        slug: "pharmacy-manager",
        provider: "pharmacy"
    },
    {
        name: "Owner",
        slug: "pharmacy",
        provider: "pharmacy"
    },
    {
        name: "Pathologist",
        slug: "pathologist",
        provider: "pathology"
    },
    {
        name: "Receptionist",
        slug: "pathology-receptionist",
        provider: "pathology"
    },
    {
        name: "Owner",
        slug: "pathology",
        provider: "pathology"
    },
    {
        name: 'Manager',
        slug: 'pathology-manager',
        provider: 'pathology'
    },{
        name: 'Phlebotomist',
        slug: 'pathology-phlebotomist',
        provider: 'pathology'
    },
    {
        name: 'Med Tech',
        slug: 'pathology-med-tech',
        provider: 'pathology'
    },
    {
        name: 'Lab Tech',
        slug: 'pathology-lab-tech',
        provider: 'pathology'
    },{
        name: 'Data Entry Clerk',
        slug: 'data-entry-clerk',
        provider: 'pathology'
    },
    {
        name: "Radiologist",
        slug: "radiologist",
        provider: "radiology"
    },
    {
        name: "Receptionist",
        slug: "radiology-receptionist",
        provider: "radiology"
    },
    {
        name: "Owner",
        slug: "radiology",
        provider: "radiology"
    },
    {
        name: 'Admin',
        slug: 'ambulance-admin',
        provider: 'ambulance',
    },
    {
        name: 'Assistant',
        slug: 'ambulance-assistant',
        provider: 'ambulance',
    },
    {
        name: 'Admin',
        slug: 'hospital-admin',
        provider: 'hospital',
    },
    {
        name: 'Paramedic Doctor',
        slug: 'paramedic-doctor',
        provider: 'hospital',
    },
    {
        name: 'Doctor - Covid',
        slug: 'covid-doctor',
        provider: 'covid-center',
    },
    {
        name: 'Nurse - Covid',
        slug: 'covid-nurse',
        provider: 'covid-center',
    },
    {
        name: 'Technician - Covid',
        slug: 'covid-technician',
        provider: 'covid-center',
    },
    {
        name: 'Vendor - Covid',
        slug: 'covid-vendor',
        provider: 'covid-center',
    },
    {
        name: 'Covid Center Super Admin',
        slug: 'covid-center-owner',
        provider: 'covid-center',
    },
    {
        name: 'Authority Access',
        slug: 'airline-owner',
        provider: 'airline',
    },
    {
        name: 'Authority Access Assistant',
        slug: 'airport-assistant',
        provider: 'airline',
    },
    {
        name: "Non DH Doctor",
        slug: "nondhdoctor",
        provider: "nondhdoctor"
    }
]

