import React from 'react';
import * as AddAssessmentComponents from './components/AddAssessmentComponent';
import PrescriptionService from '../../../services/prescriptionService';
import { isEmpty, groupBy } from 'lodash';
import { removeAutoSaveWithInterval, setAutoSaveWithInterval } from '../../../utils/helpers';

class AddAssessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icd_block_show: false,
            cpt_block_show: false,
            assessment: {
                cpt_data: [],
                icd_data: [],
                remark: '',
                treatmentplan: '',
            },
        }
    }
    componentDidMount() {
        this.props.showLoader();
        PrescriptionService.getAssessment(this.props.prescriptionId).then(resp => {
            let { data } = resp;
            let modified = [];
            if (data.icd_cpts) {
                const prescription_icd_data = groupBy(data.icd_cpts, 'icd_code')
                Object.keys(prescription_icd_data).forEach((key) => {
                    prescription_icd_data[key].forEach((icd, _index) => (
                        modified.push({
                            selectedIcd: {
                                icd_code: icd.icd_code,
                                full_description: icd.icd_description
                            },
                            icdCrosswalks: [{
                                cpt_code: icd.cpt_code,
                                full_description: icd.cpt_description
                            }]
                        })
                    ))
                });
            }
            this.setState({
                assessment: {
                    icd_data: modified,
                    cpt_data: this.state.assessment.cpt_data,
                    remark: data.remark ? data.remark : '',
                    treatmentplan: data.treatmentplan ? data.treatmentplan : '',
                }
            });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
        setAutoSaveWithInterval(this.props.selected_user_profile, this.handleAssessmentSaveAndNext)
    }

    componentWillUnmount() {
        removeAutoSaveWithInterval()
    }
    handleCptBlock = () => {
        this.setState({
            cpt_block_show: !this.state.cpt_block_show
        });
    }

    handleCptData = cptData => {
        let { assessment } = this.state;
        assessment['cpt_data'] = assessment.cpt_data.concat([{
            selectedCpt: cptData.selectedCpt,
            cptCrosswalks: cptData.selectedCrosswalk,
        }])
        this.setState({ assessment });
        this.props.handleIsPrescriptionEdited('assessment')
    }

    handleIcdBlock = () => {
        this.setState({
            icd_block_show: !this.state.icd_block_show
        });
    }

    handleIcdData = icdData => {
        let { assessment } = this.state
        let icd_data = assessment.icd_data.concat([{
            selectedIcd: icdData.selectedIcd,
            icdCrosswalks: icdData.selectedCrosswalk,
        }])
        assessment['icd_data'] = icd_data
        this.setState({ assessment });
        this.props.handleIsPrescriptionEdited('assessment')
    }

    handleRemoveCptData = (event) => {
        let { assessment } = this.state;
        const idx = Number(event.target.dataset["id"]);
        assessment['cpt_data'] = assessment.cpt_data.filter((s, sidx) => idx !== sidx)
        this.setState({ assessment });
        this.props.handleIsPrescriptionEdited('assessment')
    };

    handleRemoveIcdData = (event) => {
        let { assessment } = this.state;
        const idx = Number(event.target.dataset["id"]);
        assessment['icd_data'] = assessment.icd_data.filter((s, sidx) => idx !== sidx)
        this.setState({ assessment });
        this.props.handleIsPrescriptionEdited('assessment')
    };
    handleChange = e => {
        let { assessment } = this.state;
        assessment[e.target.name] = e.target.value
        this.setState({ assessment });
        this.props.handleIsPrescriptionEdited('assessment')
    }
    handleAssessmentSaveAndNext = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";
        let icd_cpts = [];
        !isEmpty(this.state.assessment.cpt_data) && this.state.assessment.cpt_data.map(cpt => (
            (!isEmpty(cpt.cptCrosswalks)) ?
                cpt.cptCrosswalks.map((crosswalk, _idx) => (
                    icd_cpts = icd_cpts.concat({
                        cpt_code: cpt.selectedCpt.cpt_code,
                        icd_code: crosswalk.icd_code
                    })
                ))
                :
                icd_cpts = icd_cpts.concat({ cpt_code: cpt.selectedCpt.cpt_code, })
        ))
        !isEmpty(this.state.assessment.icd_data) && this.state.assessment.icd_data.map(icd => (
            (!isEmpty(icd.icdCrosswalks)) ?
                icd.icdCrosswalks.map((crosswalk, idx) => (
                    icd_cpts = icd_cpts.concat({
                        icd_code: icd.selectedIcd.icd_code,
                        cpt_code: crosswalk.cpt_code,
                    })
                ))
                :
                icd_cpts = icd_cpts.concat({ icd_code: icd.selectedIcd.icd_code, })
        ));
        let data = {
            'icd_cpts': icd_cpts,
            'remark': this.state.assessment.remark ? this.state.assessment.remark : '',
            'patient_id': this.props.patientId,
            'treatmentplan':this.state.assessment.treatmentplan ? this.state.assessment.treatmentplan : '',
        }
        e && this.props.showLoader();
        PrescriptionService.updateAssessment(this.props.prescriptionId, data).then(_resp => {
            e && this.props.hideLoader();
            type !== "auto_save" && this.props.handleSaveAndNext();
        }).catch(error => {
            e && this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    render() {
        let { cpt_data, icd_data, remark,treatmentplan } = this.state.assessment;
        return (
            <React.Fragment>
                <div className="content-wrapper lg-height">
                    {/* {hasRole(this.props.selected_user_profile, ['doctor', 'junior-doctor', "associate-doctor", "medical-practice-manager"]) && */}
                    <div className="content-wrapper padding-bottom-10">
                        <h6>ICD CPT</h6>
                        <AddAssessmentComponents.IcdCpt
                            cpt_data={cpt_data}
                            icd_data={icd_data}
                            handleRemoveCptData={this.handleRemoveCptData}
                            handleRemoveIcdData={this.handleRemoveIcdData}
                            handleCptBlock={this.handleCptBlock}
                            handleCptData={this.handleCptData}
                            handleIcdBlock={this.handleIcdBlock}
                            handleIcdData={this.handleIcdData}
                            cpt_block_show={this.state.cpt_block_show}
                            icd_block_show={this.state.icd_block_show}
                        />
                    </div>
                    {/* } */}
                    <ul className="prescription-patient-details react-tags-input-wrapper">
                        <AddAssessmentComponents.AssessmentRemark
                            handleChange={this.handleChange}
                            remark={remark}
                        />

                    </ul>
                    <ul className="prescription-patient-details react-tags-input-wrapper">
                        <AddAssessmentComponents.TreatmentPlan
                            handleChange={this.handleChange}
                            treatmentplan={treatmentplan}
                        />

                    </ul>
                </div>
                <div className="bottom-btns">
                    <button className="btn btn-primary" onClick={e => this.handleAssessmentSaveAndNext(e, 'save_and_next')}>Save & Next</button>
                    <button className="btn btn-default btn-outline-primary" onClick={this.props.handlePrevious}>Previous</button>
                </div>
            </React.Fragment>
        )
    }
}
export default AddAssessment