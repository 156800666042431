import React, { Component } from "react";
import { connect } from 'react-redux';
import { Table, Form, Button, Col, Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReferralPatientDetails from "../../components/Report/ReferralPatientDetails";
import AddTestCategoryBlock from "../../components/AddTestCategoryBlock";
import ReferralService from "../../services/referralService";
import Notification from "../../utils/notification";
import * as SelectedProfileAction from '../../actions/selectedProfileAction';
import * as loaderActions from '../../actions/loaderAction';

class AddReferralForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathology: '',
      show_add_pathology_block: false,
      referral_form_data: [],
      selected_file: '',
      error: '',
      reset: false,
    };
    this.fileInput = React.createRef();
    this.referralFormRef = React.createRef();
  }

  handleCheckboxChange = (e, name) => {
    this.setState({ [name]: e.target.checked });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const allTests = this.state.pathology ? this.state.pathology : [];
    const tests = allTests ? allTests.map(({ test_id, sample_nos }) => ({ test_id, value: null, sample_nos })) : [];
    if (this.referralFormRef.current && !this.referralFormRef.current.isValid()) {
      return false;
    } else if (!tests.length) {
      this.setState({
        error: "Kindly Add Tests"
      })
      return false;
    }
    //  else if (!this.state.selected_file) {
    //   this.setState({
    //     error: "Kindly upload a document"
    //   })
    //   return false;
    // } 
    else {
      const formData = new FormData();
      formData.append('referral_file', this.state.selected_file);
      formData.append('patient_id', this.props.match.params.id);
      formData.append('doctor_name', this.state.referral_form_data.doctor);
      formData.append('doctor_email', this.state.referral_form_data.doctor_email);
      formData.append('doctor_contact_number', this.state.referral_form_data.doctor_contact ? this.state.referral_form_data.doctor_contact: "");
      formData.append('sample_collected_at', this.state.referral_form_data.sample_collected_at);
      formData.append('tests', JSON.stringify(tests));
      formData.append('patient_height', this.state.referral_form_data.height);
      formData.append('patient_weight', this.state.referral_form_data.weight);
      formData.append('patient_bmi', this.state.referral_form_data.bmi);
      formData.append('patient_lab_id', this.state.referral_form_data.lab_id);
      formData.append('patient_comments', this.state.referral_form_data.comments);

      this.props.loaderShow();
      ReferralService.createReferral(formData).then(resp => {
        this.props.history.push('/patient-referral-list');
        Notification.show('success', { message: 'Referral added successfully' })
        this.props.loaderHide();
      }).catch(error => {
        this.props.loaderHide();
        Notification.show('error', error.response.data)
      })
    }
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    // Perform file type validation
    if (file.type !== 'application/pdf' && file.type !== 'image/jpeg') {
      alert('Only PDF and JPEG files are allowed.');
      this.fileInput.current.value = null; // Clear the file input
      return;
    }
    // Update the selected file state and display its name in the input field
    this.setState({ selected_file: file });
  };

  handleBrowseClick = () => {
    this.fileInput.current.click(); // Trigger the file input click event
  };

  handleResetTestDetails = () => {
    this.referralFormRef.current.handleReset();
    this.setState({
      pathology: null,
      selected_file: null,
      reset: true,
    });
    this.fileInput.current.value = null; // Clear the file input
  }

  updateResetValue = (reset) => {
    this.setState({ reset });
  };

  handleReferralFormChange = (data) => {
    this.setState({
      referral_form_data: data
    })
  }

  handlePathology = (data) => {
        let newData = [];
    data.forEach((test) => {
      if (test.sub_tests.length) {
        test.sub_tests.map(function (sub_test, index) {
          sub_test.test_id = sub_test.id;
          sub_test.test_name = sub_test.name;
          sub_test.test_category = test.test_category + " [" + test.test_name + "]";
          newData.push(sub_test)
        })
      } else {
        newData.push(test)
      }
    })

    let { pathology } = this.state
    pathology = newData;
    this.setState({ pathology });
  }

  handlePathologySampleNos = (e) => {
    if (this.state.pathology) {
      if (e.target.value) {
        const pathologyTest = this.state.pathology.map((item) => {
          if (item.test_id == e.target.id) {
            return {
              ...item,
              sample_nos: e.target.value
            };
          }
          return item;
        })
        this.setState({ pathology: pathologyTest });
      }
    }
  }

  handleAddTestCategoryBlock = () => {
    this.setState({
      show_add_pathology_block: !this.state.show_add_pathology_block,
    });
  }

  render() {
    let { pathology, selected_file } = this.state;
    const { match } = this.props;
    const { user_location } = this.props.selected_profile;
    return (
      <div>
        <div>
          <Helmet>
            <title>Lab Test Referral</title>
          </Helmet>
          <div className="row">
            <div className="col-md-24">
              <h4 className="page-title">Lab Test Referral</h4>
              <div className="content-wrapper add-lab-report">
                <div className="content-wrapper padding-bottom-10">
                  <ReferralPatientDetails match={match} userlocation={user_location.name} ref={this.referralFormRef} onReferralFormChange={this.handleReferralFormChange} />
                </div>
                <div className="col-md-12 content-wrapper padding-bottom-10">
                  <Table className='test-details'>
                    <thead>
                      <tr>
                        <th>Test Category</th>
                        <th>Test</th>
                        <th>Sample Nos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pathology && pathology.map((test, index) => (
                        <tr key={index}>
                          <th>{test.test_category}</th>
                          <th>{test.test_name}</th>
                          <th>
                            <input type="text" id={test.test_id} name="sample_nos" onChange={e => this.handlePathologySampleNos(e)} />
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <button onClick={this.handleAddTestCategoryBlock} href className="add-new btn btn-primary" disabled={this.state.edit_pathology}>Add</button>
                  <AddTestCategoryBlock
                    show={this.state.show_add_pathology_block}
                    handleAddTestCategoryBlock={this.handleAddTestCategoryBlock}
                    handlePathology={this.handlePathology}
                    resetValue={this.state.reset}
                    updateResetValue={this.updateResetValue}
                  />
                </div>
                <br />
                <div className="content-wrapper padding-bottom-10">
                  <Form>
                    <Container style={{ backgroundColor: '#E4F0FF', padding: '20px', width: '780px', marginLeft: "0px" }}>
                      <Row className="align-items-center">
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="Select File"
                            readOnly
                            value={selected_file ? selected_file.name : ''}
                            style={{ backgroundColor: "white" }}
                          />
                        </Col>
                        <Col>
                          <Button
                            variant=" btn btn-primary"
                            onClick={this.handleBrowseClick}
                          >
                            Browse
                            <Form.Control
                              type="file"
                              ref={this.fileInput}
                              onChange={this.handleFileChange}
                              accept=".pdf,.jpeg,.jpg"
                              style={{ display: 'none' }}
                            />
                          </Button>
                        </Col>
                      </Row>
                      <Form.Text className="text-muted">File types: PDF, JPEG</Form.Text>
                    </Container>
                    <br />
                    {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                    <br />
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={this.handleSubmit}
                    >
                      SUBMIT
                    </Button>{" "}
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={this.handleResetTestDetails}
                    >
                      RESET
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  selected_profile: state.selected_user_profile,
});

const mapActionsToProps = ({
  editProfile: SelectedProfileAction.editProfile,
  loaderShow: loaderActions.loaderShow,
  loaderHide: loaderActions.loaderHide,
})
export default connect(mapStateToProps, mapActionsToProps)(AddReferralForm);
