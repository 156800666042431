import React from "react";
import MedicineService from "../../services/medicineService";
import axios from "axios";
import { apiRoute, getApiHeader } from "../../utils/helpers";
import AutoSuggest from "../AutoSuggest";
import { hasRole } from "../../utils/auth";

class MedicineBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
            drug_dosage_form: '',
        }
    }

    _handleChange = (evt) => {
        const { medicine } = this.props;

        let fieldName = evt.target.name;
        let fieldValue = evt.target.value;

        if (evt.target.type === 'radio') {
            if (evt.target.checked && fieldValue === "true") {
                medicine['is_generic_allowed'] = true;
            } else {
                medicine['is_generic_allowed'] = false;
            }
        } else if (fieldName === "dosage_form") {
            medicine[fieldName] = fieldValue;
            medicine['drug_type'] = evt.target[evt.target.selectedIndex].getAttribute("data-type");
            medicine['over_dosage'] = evt.target[evt.target.selectedIndex].getAttribute("data-overdosage");
        } else {
            medicine[fieldName] = fieldValue;
        }
        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    _handleSearch = (query) => {
        this.setState({ isLoading: true, options: [] });
        MedicineService.searchMedicines({ searchText: query })
            .then(resp => {
                let data = resp.data;
                const items = data.data;
                const options = items.map(i => ({
                    id: i.id,
                    name: i.name,
                    brand_name: i.name,
                    generic_id: i.generic_drug.id,
                    generic_name: i.generic_drug.name,
                    drug_type: i.dosage_form.type,
                    dosage_form: i.dosage_form.key,
                    over_dispatch: i.dosage_form.is_over_dispatch
                }));

                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    get_dosage_forms() {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/drugs/dosage-forms'), requestOptions);
    }

    componentDidMount() {
        this.get_dosage_forms().then(res => {
            this.setState({
                drug_dosage_form: res.data
            });

            this.checkValidation();
        });

        let { medicine } = this.props;
        if (medicine.drug_id === "" && medicine.drug_name !== "") {
            medicine.is_generic_allowed = true;
            medicine.is_generic_allowed_disable = true;
        } else {
            // medicine.is_generic_allowed = false;
            medicine.is_generic_allowed_disable = false;
        }
        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    checkValidation = () => {
        let { medicine } = this.props;
        let { drug_dosage_form } = this.state;
        if (drug_dosage_form) {
            drug_dosage_form.forEach((forms) => {
                if (forms.key === medicine.dosage_form) {
                    medicine.over_dosage = forms.is_over_dispatch;
                }
            });
        }

        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    _onChange = (option) => {
        let { medicine } = this.props;

        if (option) {
            if (option.customOption === true) {
                medicine.drug_id = "";
                medicine.drug_generic_id = "";
                medicine.drug_name = option.brand_name;
                medicine.drug_generic_name = "";
                medicine.dosage_form = "";
                medicine.drug_type = "";
                medicine.is_other = true;
                medicine.over_dosage = false;
                medicine.is_generic_allowed = true;
                medicine.is_generic_allowed_disable = true;
            } else {
                medicine.drug_id = option.id;
                medicine.drug_name = option.brand_name;
                medicine.drug_generic_id = option.generic_id;
                medicine.drug_generic_name = option.generic_name;
                medicine.drug_type = option.drug_type;
                medicine.dosage_form = option.dosage_form;
                medicine.is_other = false;
                medicine.over_dosage = option.over_dispatch;
                medicine.is_generic_allowed = false;
                medicine.is_generic_allowed_disable = false;
            }
        } else {
            medicine.drug_id = "";
            medicine.drug_name = "";
            medicine.drug_generic_id = "";
            medicine.drug_generic_name = "";
            medicine.drug_type = "";
            medicine.dosage_form = "";
            medicine.is_other = false;
            medicine.over_dispatch = false;
        }
        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    render() {
        this.props.validator.purgeFields()
        return (
            <React.Fragment>
                <li key={"medicine" + this.props.idx}>
                    <div className="form-group half">
                        <label>Brand Name</label>
                        <AutoSuggest
                            disabled={this.props.disabled}
                            allowNew={true}
                            isLoading={this.state.isLoading}
                            options={this.state.options}
                            newSelectionPrefix="New Medicine: "
                            labelKey="brand_name"
                            placeholder="Search drug"
                            defaultValue={this.props.medicine.drug_name}
                            onSearch={this._handleSearch}
                            onChange={this._onChange}
                            renderMenuItem={(option) => {
                                return <div key={option.id}>
                                    <div>{option.brand_name}</div>
                                    <small>Generic Name: {option.generic_name}</small>
                                </div>
                            }}
                        />
                        {this.props.validator.message('Brand_name', this.props.medicine.drug_name, 'required')}
                    </div>
                    <div className="form-group half">
                        <label>Generic Name</label>
                        <input type="text" name="drug_generic_name" className="form-control" value={this.props.medicine.drug_generic_name} onChange={this._handleChange} readOnly="readOnly" />
                    </div>
                    <div className="form-group">
                        <label>Frequency</label>
                        <select name="dosage" className="form-control" value={this.props.medicine.dosage ? this.props.medicine.dosage : ''} onChange={this._handleChange} disabled={this.props.disabled}>
                            <option value=""></option>
                            <option value="od">OD</option>
                            <option value="bd">BD/BID</option>
                            <option value="tid">TID</option>
                            <option value="qid">QID</option>
                            <option value="sos">SOS</option>
                        </select>
                        {(!this.props.not_required && !this.props.medicine.over_dosage) ? this.props.validator.message('Dosage', this.props.medicine.dosage, 'required') : ''}
                    </div>
                    <div className="form-group">
                        <label>Meal</label>
                        <select className="form-control" name="meal" value={this.props.medicine.meal ? this.props.medicine.meal : ''} onChange={this._handleChange} disabled={this.props.disabled}>
                            <option></option>
                            <option value="after">After</option>
                            <option value="before">Before</option>
                        </select>
                    </div>
                    <div className="form-group duration">
                        <label>Duration</label>
                        <input type="number" className="form-control" name="duration" value={this.props.medicine.duration ? this.props.medicine.duration : ''} onChange={this._handleChange} disabled={this.props.role === 'pharmacist' ? true : this.props.disabled} min="0" ></input>
                        <select name="duration_type" className="form-control" value={this.props.medicine.duration_type ? this.props.medicine.duration_type : ''} onChange={this._handleChange} disabled={this.props.role === 'pharmacist' ? true : this.props.disabled}>
                            <option value=""></option>
                            <option value="day">/7</option>
                            <option value="week">/52</option>
                            <option value="month">/12</option>
                        </select>
                        {(!this.props.not_required && !this.props.medicine.over_dosage) ? this.props.validator.message('Duration', this.props.medicine.duration, 'required|min:1,num|integer') : ''}
                        {(!this.props.not_required && !this.props.medicine.over_dosage) ? this.props.validator.message('Duration_type', this.props.medicine.duration_type, 'required') : ''}
                    </div>
                    <div className="form-group">
                        <label>Dosage</label>
                        <input type="number" name="quantity" className="form-control" value={this.props.medicine.quantity ? this.props.medicine.quantity : ''} onChange={this._handleChange} min="1" disabled={this.props.disabled} />
                        {(!this.props.not_required && !this.props.medicine.over_dosage) ? this.props.validator.message('Quantity', this.props.medicine.quantity, 'required|num') : ''}
                    </div>
                    <div className="form-group">
                        <label>Type</label>
                        <select className="form-control" name="drug_type" value={this.props.medicine.drug_type} disabled>
                            <option value=""></option>
                            <option value="tablespoon">Tablespoon</option>
                            <option value="piece">Piece</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Dosage Form</label>
                        <select className="form-control" name="dosage_form" value={this.props.medicine.dosage_form} disabled={!this.props.medicine.is_other} onChange={this._handleChange}>
                            <option value=""></option>
                            {this.state.drug_dosage_form && this.state.drug_dosage_form.map((index, key) => (
                                <option key={key} value={index.key} data-type={index.type} data-overdosage={index.is_over_dispatch}>{index.label}</option>
                            ))}
                        </select>
                        {this.props.validator.message('Dosage_form', this.props.medicine.dosage_form, 'required')}
                    </div>
                    <div className="form-group">
                        <label>Substitution</label>
                        <div className="float-left"><input type="radio" id={'generic_allowed' + this.props.idx} name={"is_generic_allowed" + this.props.idx} value={true} checked={this.props.medicine.is_generic_allowed === true ? true : false} disabled={this.props.role && (this.props.role === 'pharmacist') ? true : (this.props.disabled ? true : this.props.medicine.is_generic_allowed_disable)} onChange={this._handleChange} />
                            <label htmlFor={'generic_allowed' + this.props.idx}>Yes</label>
                        </div>
                        <div className="float-right"><input type="radio" id={'generic_not_allowed' + this.props.idx} name={"is_generic_allowed" + this.props.idx} value={false} checked={this.props.medicine.is_generic_allowed === false ? true : false} disabled={this.props.role && (this.props.role === 'pharmacist') ? true : (this.props.disabled ? true : this.props.medicine.is_generic_allowed_disable)} onChange={this._handleChange} />
                            <label htmlFor={'generic_not_allowed' + this.props.idx}>No</label>
                        </div>
                    </div>
                    {hasRole(this.props.selected_user_profile, ["pharmacist", "doctor", "junior-doctor", "associate-doctor", "doctor-nurse", "medical-practice-manager"]) && <div className="form-group full">
                        <label>Instructions</label>
                        <textarea className="form-control" name="remarks" value={this.props.medicine.remarks ? this.props.medicine.remarks : ''} onChange={this._handleChange} placeholder="Type instructions..." disabled={this.props.disabled}></textarea>
                    </div>}
                    {this.props.type === "current_medicine" && <div className="form-group full">
                        <label>Side effects/Complaint of medicaion</label>
                        <textarea className="form-control" name="side_effects" value={this.props.medicine.side_effects ? this.props.medicine.side_effects : ''} onChange={this._handleChange} placeholder="Type here..."></textarea>
                    </div>}
                    {this.props.selected_user_profile && (((this.props.selected_user_profile.role_slug === 'pharmacist') ? '' : this.props.disabled === false) || this.props.show_cancel) && <span className="cancel" data-id={this.props.idx} onClick={this.props.handleRemoveMedicine}></span>}

                </li>
            </React.Fragment>
        )
    }
}

export default MedicineBlock;
