import React from 'react';

export const Breast = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Breast</h6>
        <input type="checkbox" id='breast-normal' name='breast-normal' onChange={e => handleChange(e, 'breast')} checked={checkIfProblemExist('breast', 'breast-normal') ? 'checked' : ''} />
        <label htmlFor='breast-normal'>Normal</label>
    </React.Fragment>
}
export const ExternalExamination = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">External Examination </label>
            <input type="checkbox" id='breast-external-examination-lumps' name='breast-external-examination-lumps' onChange={e => handleChange(e, 'breast')} checked={checkIfProblemExist('breast', 'breast-external-examination-lumps') ? 'checked' : ''} />
            <label htmlFor='breast-external-examination-lumps'>Lumps</label>
            <input type="checkbox" id='breast-external-examination-bumps' name='breast-external-examination-bumps' onChange={e => handleChange(e, 'breast')} checked={checkIfProblemExist('breast', 'breast-external-examination-bumps') ? 'checked' : ''} />
            <label htmlFor='breast-external-examination-bumps'>Bumps</label>
            <input type="checkbox" id='breast-external-examination-skin-changes' name='breast-external-examination-skin-changes' onChange={e => handleChange(e, 'breast')} checked={checkIfProblemExist('breast', 'breast-external-examination-skin-changes') ? 'checked' : ''} />
            <label htmlFor='breast-external-examination-skin-changes'>Skin Changes</label>
        </div>
    </React.Fragment>
}
export const Palpation = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Palpation </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'breast', 'breast-palpation')} value={getProblemComment('breast', 'breast-palpation') ? getProblemComment('breast', 'breast-palpation') : ''} disabled={checkIfProblemExist('breast', 'breast-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Consistency = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Consistency </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'breast', 'breast-consistency')} value={getProblemComment('breast', 'breast-consistency') ? getProblemComment('breast', 'breast-consistency') : ''} disabled={checkIfProblemExist('breast', 'breast-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Sensitivity = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Sensitivity </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'breast', 'breast-sensitivity')} value={getProblemComment('breast', 'breast-sensitivity') ? getProblemComment('breast', 'breast-sensitivity') : ''} disabled={checkIfProblemExist('breast', 'breast-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const NippleDischarge = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Nipple Discharge </label>
            <input type="checkbox" id='breast-nipple-discharge-yes' name='breast-nipple-discharge-yes' onChange={e => handleChange(e, 'breast')} checked={checkIfProblemExist('breast', 'breast-nipple-discharge-yes') ? 'checked' : ''} />
            <label htmlFor='breast-nipple-discharge-yes'>Yes</label>
            <input type="checkbox" id='breast-nipple-discharge-no' name='breast-nipple-discharge-no' onChange={e => handleChange(e, 'breast')} checked={checkIfProblemExist('breast', 'breast-nipple-discharge-no') ? 'checked' : ''} />
            <label htmlFor='breast-nipple-discharge-no'>No</label>
        </div>
    </React.Fragment>
}
export const MammogramOrUltrasound = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Mammogram Or Ultrasound </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'breast', 'breast-mammogram')} value={getProblemComment('breast', 'breast-mammogram') ? getProblemComment('breast', 'breast-mammogram') : ''} disabled={checkIfProblemExist('breast', 'breast-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'breast')} disabled={checkIfProblemExist('breast', 'breast-normal') ? 'disabled' : ''} value={getCategoryComment('breast') ? getCategoryComment('breast') : ''}></textarea>
    </React.Fragment>
}