import React from 'react';

export const Genitalia = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <h6>Genitalia</h6>
        <input type="checkbox" id='genitalia-normal' name='genitalia-normal' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-normal') ? 'checked' : ''} />
        <label htmlFor='genitalia-normal'>Normal</label>
    </React.Fragment>
}
export const AbnormalGrowthOrLumps = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Abnormal Growth Or Lumps </label>
            <input type="checkbox" id='genitalia-lumps-yes' name='genitalia-lumps-yes' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-lumps-yes') ? 'checked' : ''} />
            <label htmlFor='genitalia-lumps-yes'>Yes</label>
            <input type="checkbox" id='genitalia-lumps-no' name='genitalia-lumps-no' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-lumps-no') ? 'checked' : ''} />
            <label htmlFor='genitalia-lumps-no'>No</label>
        </div>
    </React.Fragment>
}
export const SkinChange = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Skin Change </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'genitalia', 'genitalia-skin-change')} value={getProblemComment('genitalia', 'genitalia-skin-change') ? getProblemComment('genitalia', 'genitalia-skin-change') : ''} disabled={checkIfProblemExist('genitalia', 'genitalia-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Discharge = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Discharge </label>
            <input type="checkbox" id='genitalia-discharge-yes' name='genitalia-discharge-yes' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-discharge-yes') ? 'checked' : ''} />
            <label htmlFor='genitalia-discharge-yes'>Yes</label>
            <input type="checkbox" id='genitalia-discharge-no' name='genitalia-discharge-no' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-discharge-no') ? 'checked' : ''} />
            <label htmlFor='genitalia-discharge-no'>No</label>
        </div>
    </React.Fragment>
}
export const Sensitivity = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Sensitivity </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'genitalia', 'genitalia-sensitivity')} value={getProblemComment('genitalia', 'genitalia-sensitivity') ? getProblemComment('genitalia', 'genitalia-sensitivity') : ''} disabled={checkIfProblemExist('genitalia', 'genitalia-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const SoresOrLesions = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Sores or Lesions </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'genitalia', 'genitalia-sores')} value={getProblemComment('genitalia', 'genitalia-sores') ? getProblemComment('genitalia', 'genitalia-sores') : ''} disabled={checkIfProblemExist('genitalia', 'genitalia-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Swelling = ({ handleChange, checkIfProblemExist }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">Swelling </label>
            <input type="checkbox" id='genitalia-swelling-yes' name='genitalia-swelling-yes' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-swelling-yes') ? 'checked' : ''} />
            <label htmlFor='genitalia-swelling-yes'>Yes</label>
            <input type="checkbox" id='genitalia-swelling-no' name='genitalia-swelling-no' onChange={e => handleChange(e, 'genitalia')} checked={checkIfProblemExist('genitalia', 'genitalia-swelling-no') ? 'checked' : ''} />
            <label htmlFor='genitalia-swelling-no'>No</label>
        </div>
    </React.Fragment>
}
export const Testicles = ({ handleChange, getProblemComment, checkIfProblemExist }) => {
    return <React.Fragment>
            <label className="heading">Testicles </label>
            <textarea className="form-control" name="comment" onChange={e => handleChange(e, 'genitalia', 'genitalia-testicles')} value={getProblemComment('genitalia', 'genitalia-testicles') ? getProblemComment('genitalia', 'genitalia-testicles') : ''} disabled={checkIfProblemExist('genitalia', 'genitalia-normal') ? 'disabled' : ''}></textarea>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist }) => {
    return <React.Fragment>
        <label className="heading-comment">Comment </label>
        <textarea className="form-control" name="comments" placeholder="Type here..." onChange={e => handleChange(e, 'genitalia')} disabled={checkIfProblemExist('genitalia', 'genitalia-normal') ? 'disabled' : ''} value={getCategoryComment('genitalia') ? getCategoryComment('genitalia') : ''}></textarea>
    </React.Fragment>
}