import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class ReferralService {
    static createReferral(postData) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/pathology/lims-referrals'), postData, requestOptions);
    }

    static getReferrals(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/' + id), requestOptions);
    }

    static getNewReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/referral'), requestOptions);
    }

    static getDraftReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/draft-referral'), requestOptions);
    }

    static exportDraftReferral({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/draft-referral/export'), requestOptions);
    }

    static getProcessingReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/processing'), requestOptions);
    }

    static exportProcessingReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/processing/export'), requestOptions);
    }

    static getPendingReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/pending'), requestOptions);
    }

    static exportPendingReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/pending/export'), requestOptions);
    }

    static getCompletedReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/complete'), requestOptions);
    }

    static exportCompletedReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/complete/export'), requestOptions);
    }

    static getPrintedReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/printed'), requestOptions);
    }

    static verification(id, data, role) {
        const requestOptions = {
            headers: getApiHeader()
        };
        if (role === "pathology-med-tech") {
            return axios.put(apiRoute('/v1/pathology/lims-referrals/med-tech-verification/' + id), JSON.stringify(data), requestOptions);
        }
        return axios.put(apiRoute('/v1/pathology/lims-referrals/lab-tech-verification/' + id), JSON.stringify(data), requestOptions);
    }

    static sendForVerification(id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/pathology/lims-referrals/send-verification/' + id), JSON.stringify(data), requestOptions);
    }

    static saveAsDraft(id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/pathology/lims-referrals/draft/' + id), JSON.stringify(data), requestOptions);
    }
    static saveAsPhlebotomist(id, data) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.put(apiRoute('/v1/pathology/lims-referrals/phlebotomist/' + id), JSON.stringify(data), requestOptions);
    }

    static sendReferralMail(postData, user) {
        const requestOptions = {
            headers: getApiHeader()
        };
        if (user === 'doctor') {
            return axios.post(apiRoute('/v1/pathology/lims-referrals/email-doctor'), postData, requestOptions);
        } else {
            return axios.post(apiRoute('/v1/pathology/lims-referrals/email-patient'), postData, requestOptions);
        }
    }

    static printReport(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/pathology/lims-referrals/' + id + '/print'), {}, requestOptions);
    }

    static getPathologyLabReport({ page, sizePerPage, sortBy, filter }, id) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/patient/' + id), requestOptions);
    }

    static getDoneReferrals({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/test-done'), requestOptions);
    }

    static getReportDownload(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/' + id + '/report-download'), requestOptions);
    }

    static getReferralsDownload(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/' + id + '/download'), requestOptions);
    }

    static exportTestDoneReport({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/test-done/export'), requestOptions);
    }

    static exportPatientReferalListReport({ page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };
        return axios.get(apiRoute('/v1/pathology/lims-referrals/patient-referal-list/export'), requestOptions);
    }

    static getLimsLabReport(patientId, { page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute(`/v1/patients/${patientId}/lims-referrals`), requestOptions);
    }

    static getPatientReferral(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/patients/lims-referrals/' + id), requestOptions);
    }

    static getPatientReferralsDownload(id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/patients/lims-referrals/' + id + '/download'), requestOptions);
    }

    static generateOtp(patient_id) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/patients/' + patient_id + '/lims-referrals/generate-otp'), requestOptions);
    }

    static verifyOtp(postData) {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.post(apiRoute('/v1/patients/lims-referrals/verify-otp'), postData, requestOptions);
    }
}

export default ReferralService;
