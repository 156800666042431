import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as DaisyDoctorAction from "../../actions/daisyDoctorAction";
import DataGrid from "../../components/DataGrid";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import * as Config from '../../config';

class DaisyHealthUsersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                doctor_name: "",
                specialization: "",
                city: "",
                parish: "",
            },
            tags: {
                doctor_name: "",
                specialization: "",
                city: "",
                parish: "",
            }
        };

        this.validator = new SimpleReactValidator();
        this.handleEvent = this.handleEvent.bind(this);
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
    }

    columns = () => {
        return [
            {
                dataField: "user_full_name",
                text: "Name",
                formatter: this.nameFormatter
            },
            {
                dataField: "specialization",
                text: "Specialization",
            },
            {
                dataField: "location_city",
                text: "Community"
            },
            {
                dataField: "location_parish",
                text: "Parish"
            }
        ];
    };

    nameFormatter = (row, cell) => {
        return cell.user_first_name + ' ' + cell.user_last_name;
    }

    handleChange = (e) => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }

        const filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        /* External javascript function  */
        window['searchToggle']();

        this.setState({
            tags: {
                doctor_name: this.state.filter.doctor_name,
                specialization: this.state.filter.specialization,
                city: this.state.filter.city,
                parish: this.state.filter.parish,
            }
        });

        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.filter
        });
    }

    resetSearch = (e) => {
        e.preventDefault()
        this.setState({
            filter: {
                doctor_name: "",
                specialization: "",
                city: "",
                parish: "",
            },
            tags: {
                doctor_name: "",
                specialization: "",
                city: "",
                parish: "",
            }
        }, () => {
            window['searchToggle']();

            this.props.paginate({
                sortBy: { sortField: "updated_at", sortOrder: "desc" },
                filter: this.state.filter
            });
        });
    }

    handleEvent = (event, picker) => {
        this.setState({
            filter: {
                doctor_name: this.state.filter.doctor_name,
                specialization: this.state.filter.specialization,
                city: this.state.filter.city,
                parish: this.state.filter.parish,
            },
            tags: {
                doctor_name: this.state.filter.doctor_name,
                specialization: this.state.filter.specialization,
                city: this.state.filter.city,
                parish: this.state.filter.parish,
            }
        });

        this.props.paginate({ filter: this.state.filter });
    }

    removeFilter = (e) => {

        const filter = this.state.filter;
        const tags = this.state.tags;
        filter[e.target.dataset.tag] = '';
        tags[e.target.dataset.tag] = '';

        this.setState({ filter: filter, tags: tags });
        this.props.paginate({ filter: this.state.filter });
    }

    componentWillUnmount() {
        this.props.resetList();
    }

    render() {
        let { data, meta } = this.props.doctors_list;
        let tags = this.state.tags;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Daisy Health Users</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Daisy Health Users</h4>
                        <div className="search-wrapper">
                            <label>Search</label>
                            <div className="search-box clearfix">
                                {(Object.keys(tags)).map((tag) => {
                                    if (tags[tag] !== '' && !_.isEmpty(tags[tag])) {
                                        return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                                    }
                                    return <></>
                                })
                                }
                                <span className="search-box-icon"></span>

                                <div className="search-container">
                                    <form>
                                        <ul>
                                            <li>
                                                <span>Name</span>
                                                <input type="text" value={this.state.filter.doctor_name} onChange={this.handleChange} name="doctor_name"></input>
                                                {this.validator.message('name', this.state.filter.doctor_name, 'alpha_num_space')}
                                            </li>
                                            <li>
                                                <span>Specialization</span>
                                                <select className="form-control" id="specialization" name="specialization" onChange={this.handleChange} value={this.state.filter.specialization} >
                                                    <option value=""> </option>
                                                    {Config.doctor_specialization.map((value) => {
                                                        return <option value={value}>{value}</option>
                                                    })}
                                                </select>
                                            </li>
                                            <li>
                                                <span>Community</span>
                                                <input type="text" value={this.state.filter.city} onChange={this.handleChange} name="city"></input>
                                                {this.validator.message('community', this.state.filter.city, 'alpha_num_space')}
                                            </li>
                                            <li>
                                                <span>Parish</span>
                                                <select className="form-control" value={this.state.filter.parish} id="parish" name="parish" onChange={this.handleChange}>
                                                    <option value=""></option>
                                                    {Object.keys(Config.parish).map(function (key, idx) {
                                                        return <option className="travelcompany-input" key={idx} value={key}>
                                                            {Config.parish[key]}
                                                        </option>;
                                                    })}
                                                </select>
                                            </li>
                                            <li>
                                                <button onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                                                <button onClick={this.handleSubmit} className="btn btn-primary">Search</button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    {data && (
                        <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            handleTableChange={this.handleTableChange}
                            noDataIndication="No Record Found"
                        />
                    )}
                </div>
            </React.Fragment >
        );
    }
}
function mapStateToProps(state) {
    return {
        user: state.session.user,
        doctors_list: state.doctors_list,
        selected_user_profile: state.selected_user_profile,
    };
}

const mapActionsToProps = {
    paginate: DaisyDoctorAction.listDoctors,
    resetList: DaisyDoctorAction.resetList,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(DaisyHealthUsersPage);
