import TempDocReportService from '../services/tempDocReportService';
import Notification from '../utils/notification';

export function verifiedlist({ page, sizePerPage, sortBy  }) {
    return dispatch => {
        dispatch({ type: 'REPORT_LIST_REQUEST', request: { page, sizePerPage, sortBy  } });

        TempDocReportService.verifiedlist({ page, sizePerPage, sortBy  })
            .then(resp => {                
                let data = resp.data;
                dispatch({ type: 'REPORT_LIST_SUCCESS', ...data }); 
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'REPORT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}

export function list({ page, sizePerPage, sortBy, patientId  }) {
    return dispatch => {
        dispatch({ type: 'REPORT_LIST_REQUEST', request: { page, sizePerPage, sortBy, patientId  } });

        TempDocReportService.list({ page, sizePerPage, sortBy, patientId  })
            .then(resp => {                
                let data = resp.data;
                dispatch({ type: 'REPORT_LIST_SUCCESS', ...data }); 
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'REPORT_LIST_FAILED' });
                    Notification.show('error', error.response.data)
                }
            });
    }
}