import React from "react";
import ReactToPrint from 'react-to-print';

class PrintReferralReportButton extends React.Component {
    componentDidMount() {
        if (this.printBtnRef && this.props.autoPrint === true) {
            this.printBtnRef.click();
        }
    }

    render() {
        const { component: Component, ...props } = this.props;

        return (<React.Fragment>
            <ReactToPrint
                trigger={() => <button className="btn btn-primary ml-2 mt-3" ref={el => (this.printBtnRef = el)}>{this.props.title ? this.props.title : 'Print'}</button>}
                content={() => this.componentRef}
            />

            <div style={{ display: "none" }}>
                <Component
                    ref={el => (this.componentRef = el)}
                    {...props}
                />
            </div>

        </React.Fragment>)
    }
}

export default PrintReferralReportButton;