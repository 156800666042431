import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as DoneReportAction from "../../actions/limsDoneReportAction";
import DataGrid from "../../components/DataGrid";
import _ from "lodash";
import { capitalize } from 'lodash';
import moment from "moment";
import SimpleReactValidator from 'simple-react-validator';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReferralService from '../../services/referralService';
import ServiceProviderLocations from '../../components/ServiceProviderLocations';
import LimsTestService from "../../services/limsTestService";
import AdditionalInformationView from "../../components/Patient/AdditionalInformationView";

class ListTestDoneReport extends React.Component {
  constructor(props) {
    super(props);

    this.initFilter = {
      sex_at_birth: "",
      age_range: "",
      flag: "",
      start_date: null,
      end_date: null,
      user_location_id: "",
      showModal: false,
      health_information: []
    }

    this.state = {
      lims_tests: [],
      sortBy: { sortField: "created_at", sortOrder: "desc" },
      date: '',
      filter: this.initFilter,
    };

    this.validator = new SimpleReactValidator();
    this.getLimsTestList();
  }

  componentDidMount() {
    this.props.paginate({ sortBy: this.state.sortBy });
  }

  getLimsTestList = () => {
    LimsTestService.listAll().then(response => {     
      let specialTest = response.data.data.filter((s, test) => s.category == "Special");
      const groupedTests = specialTest.reduce((acc, test) => {
        if (!acc[test.category]) {
            acc[test.category] = [];
        }
        acc[test.category].push(test);
        return acc;
    }, {});
    let limsData = [];
            Object.keys(groupedTests).map(function (key, index) {
                let groupTests = groupedTests[key];
                if(groupTests.length >1){
                        const groupedTests1 = groupTests.reduce((acc, test) => {
                            if (!acc[test.name]) {
                                acc[test.name] = [];
                            }
                            acc[test.name].push(test);
                            return acc;
                        }, {});
                    Object.keys(groupedTests1).map(function (key, index) {
                        if(groupedTests1[key].length >1){
                            let findData = groupedTests1[key].find(d1 => d1.new === true)
                            limsData.push(findData);
                        }else{
                            limsData.push(groupedTests1[key][0]);
                        }
                    });
                }else{
                    limsData.push(groupedTests[key][0]);
                }
            })
      this.setState({ lims_tests: limsData })
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  columns = () => {
    return [
      {
        text: "Date",
        dataField: "date",
        sort: false,
      },
      {
        dataField: "patient_name",
        text: "Patient Name"
      },
      {
        dataField: "patient_age.year",
        text: "Age"
      },
      {
        dataField: "patient_sex_at_birth",
        text: "Sex at Birth",
        formatter: this.genderFormatter
      },
      {
        dataField: "lims_test.category",
        text: "Test Category",
        sort: false,
      },
      {
        dataField: "lims_test.name",
        text: "Test Name",
        sort: false,
      },
      {
        dataField: "test_lower_range",
        text: "Test Lower Range",
        formatter: this.testLowerRangeFormatter,
      },
      {
        dataField: "test_upper_range",
        text: "Test Upper Range",
        formatter: this.testUpperRangeFormatter,
      },
      {
        dataField: "value",
        text: "Result",
        formatter: this.testResultFormatter,
      },
      {
        dataField: "unit_measurement",
        text: "Unit Measurement",
        formatter: this.unitMeasurementFormatter,
      },
      {
        dataField: "flag",
        text: "Flag",
        formatter: this.flagFormatter,
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.actionFormatter,
      },
    ];
  };

  genderFormatter = (cell, row) => {
    return capitalize(cell)
  }

  testLowerRangeFormatter = (cell, row) => {
    return row.lims_test.lower_limit ? row.lims_test.lower_limit : "";
  }

  testUpperRangeFormatter = (cell, row) => {
    return row.lims_test.upper_limit ? row.lims_test.upper_limit : "";
  }

  unitMeasurementFormatter = (cell, row) => {
    return row.lims_test.unit_of_measurement ? row.lims_test.unit_of_measurement : "";
  }

  testResultFormatter = (cell, row) => {
    return row.lims_test.type == "select" ? capitalize(cell) : cell;
  }

  flagFormatter = (cell, row) => {
    if (!cell) {
      return '';
    }
    return capitalize(cell)
  }

  actionFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <Link onClick={e => this.openModel(row.health_information)} title="View">
          <span className="icon file"></span>
        </Link>
      </React.Fragment>
    );
  };

  handleChange = (e) => {
    const filter = this.state.filter;
    filter[e.target.name] = e.target.value ? e.target.value : undefined;
    this.setState({ filter });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText, filter }) => {
    if (type === "search") {
      page = 1;
    }

    let sortBy = sortField ? { sortField, sortOrder } : this.state.sortBy;
    filter = filter ? filter : this.state.filter;

    this.props.paginate({ page, sizePerPage, sortBy, searchText, filter });
  };

  handleLocationFilter = (location_id, location_name) => {
    const filter = this.state.filter;
    filter.user_location_id = location_id ? location_id : null;

    this.setState({
      filter: filter,
    });
  }

  handleDateApply = (_evt, picker) => {
    this.setState({
      ...this.state,
      date: picker.startDate.format('DD MMM YYYY') + ' - ' + picker.endDate.format('DD MMM YYYY'),
      filter: {
        ...this.state.filter,
        start_date: picker.startDate.format('YYYY-MM-DD'),
        end_date: picker.endDate.format('YYYY-MM-DD')
      }
    })
  }

  resetSearch = () => {
    this.setState({
      filter: this.initFilter,
      date: "",
      user_location_id: ""
    });

    window["searchToggle"]();

    this.props.paginate({ sortBy: this.state.sortBy });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    window['searchToggle']();
    this.props.paginate({ sortBy: this.state.sortBy, filter: this.state.filter });
  };

  filterComponent = () => {
    return (
      <div className="search-wrapper">
        <label>Search</label>
        <div className="search-box clearfix">
          <span className="search-box-icon"></span>
          <div className="search-container">
            <form onSubmit={this.handleSubmit}>
              <ul>

                <li>
                  <span>Test Name</span>
                  <select name="lims_test" className="form-control" value={this.state.filter.lims_test} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    {this.state.lims_tests.map((test, idx) => {
                      return <option value={test.id} key={idx}>{test.name}</option>
                    })}
                  </select>
                </li>

                {/* <li>
                  <span>Location</span>
                  <ServiceProviderLocations
                    service_provider_id={this.props.selected_profile.service_provider_id}
                    handleLocationChange={this.handleLocationFilter} />
                </li> */}

                <li>
                  <span>Sex at Birth</span>
                  <select name="sex_at_birth" className="form-control" value={this.state.filter.sex_at_birth} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value={'male'} key={1}>Male</option>
                    <option value={'female'} key={2}>Female</option>
                  </select>
                </li>

                <li>
                  <span>Age</span>
                  <select name="age_range" className="form-control" value={this.state.filter.age_range} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value={"0-10"} key={1}>0-10</option>
                    <option value={"11-20"} key={2}>11-20</option>
                    <option value={"21-30"} key={3}>21-30</option>
                    <option value={"31-40"} key={4}>31-40</option>
                    <option value={"41-50"} key={5}>41-50</option>
                    <option value={"51-60"} key={6}>51-60</option>
                    <option value={"61-70"} key={7}>61-70</option>
                    <option value={"71-80"} key={8}>71-80</option>
                    <option value={"81-90"} key={9}>81-90</option>
                    <option value={"91-100"} key={10}>91-100</option>
                    <option value={"100"} key={11}>{">100"}</option>
                  </select>
                </li>

                <li>
                  <span>Flag</span>
                  <select name="flag" className="form-control" value={this.state.filter.flag} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    <option value={'normal'}>Normal</option>
                    <option value={'low'}>Low</option>
                    <option value={'high'}>High</option>
                    <option value={'Positive'}>Positive</option>
                    <option value={'Negative'}>Negative</option>
                  </select>
                </li>

                <li>
                  <span>Date</span>
                  <DateRangePicker
                    onApply={this.handleDateApply}
                    autoApply={true}
                    maxDate={moment()}
                  >
                    <div class="input-group">
                      <input type="text" class="form-control" value={this.state.date} placeholder="DD MMM YYYY - DD MMM YYYY" />
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                    </div>
                  </DateRangePicker>
                </li>

                <li>
                  <button
                    type="button"
                    onClick={this.resetSearch}
                    className="btn btn-secondary"
                  >
                    Reset
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  };

  handleExport = () => {
    ReferralService.exportTestDoneReport({ sortBy: this.state.sortBy, filter: this.state.filter }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "TestDoneReport.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(error => {
      Notification.show('error', error.response.data)
    })
  }

  openModel = (health_information) => {
    this.setState({ health_information: health_information, showModal: true });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  render() {
    let { data, isLoading, meta } = this.props.lims_done_report;
    return (
      <React.Fragment>
        <Helmet>
          <title>Test Done (Report)</title>
        </Helmet>
        <div className="row">
          <div className="col-md-24">
            <h4 className="page-title">Test Done (Report)</h4>
          </div>
        </div>

        <div className="col-md-24">
          {this.filterComponent()}
        </div>

        <div className="content-wrapper new_patient_report">
          <button type="button" className="btn btn-primary" onClick={this.handleExport}>Export CSV</button>

          <DataGrid
            loading={isLoading}
            noDataIndication="No Record Found"
            columns={this.columns()}
            data={data}
            handleTableChange={this.handleTableChange}
            keyField="lims_referral_id"
            selectRow={this.selectRow}
            meta={meta}
            pagination={true}
            export_class="btn btn-default btn-outline-primary"
          />
        </div>
        {this.state.showModal && (
          <AdditionalInformationView
            show={this.state.showModal}
            handleAdditionalInformation={this.toggleModal}
            getData={this.state.health_information}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lims_done_report: state.lims_done_report,
  selected_profile: state.selected_user_profile
});

const mapActionsToProps = {
  paginate: DoneReportAction.list,
};

export default connect(mapStateToProps, mapActionsToProps)(ListTestDoneReport);
