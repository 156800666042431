import React from 'react'
import IdleModal from './IdleModal'
import IdleTimer from 'react-idle-timer'

export class InactivityTimer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeout: 1000 * 60 * 30, // 30 minutes
            modelTimeout: 1000 * 60 * 240, // 4hr
            showModal: false,
        }

        this.idleTimer = null
        this.modalTimer = null
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    _onIdle(e) {
        this.setState({ showModal: true })
        this.idleTimer.reset()

        this.modalTimer = setTimeout(() => {
            this.setState({ showModal: false })
            this.props.logout(this.props.history);
        }, this.state.modelTimeout)
    }

    handleClose() {
        this.setState({ showModal: false })
        clearTimeout(this.modalTimer)
    }

    handleLogout() {
        this.setState({ showModal: false })
        clearTimeout(this.modalTimer)
        this.props.logout(this.props.history);
    }

    render() {
        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    onActive={this.onActive}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout} />

                <IdleModal
                    showModal={this.state.showModal}
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
            </>
        )
    }
}

export default InactivityTimer
