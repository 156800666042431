import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import DataGrid from "../../components/DataGrid";
import AppointmentService from '../../services/appointmentService';
import * as HELPER from '../../utils/helpers';
import { hasRole, hasProvider } from '../../utils/auth';
import { Button, Tab, Tabs } from "react-bootstrap";
import Notification from "../../utils/notification";
import { includes, capitalize } from 'lodash';
import * as LoaderAction from "../../actions/loaderAction";
import moment from 'moment';
import AppointmentFilter from '../../components/Slot/AppointmentFilter';

const DoctorAddQueueButton = ({ handleQueueClick }) => {
    return (
        <button className="btn btn-secondary" onClick={handleQueueClick}>
            Add To Queue
        </button>
    );
}

class QueuePage extends React.Component {
    columns = () => {
        return [
            {
                dataField: "id",
                text: "ID",
                hidden: true
            },
            {
                dataField: "patient_code",
                text: "Patient Id",
            },
            {
                dataField: "prescription_id",
                text: "Prescription Id",
                hidden: !hasRole(this.props.selected_user_profile, ['pathologist', 'radiologist', 'pathology-receptionist', 'radiology-receptionist'])
            },
            {
                dataField: "patient_name",
                text: "Patient Name"
            },
            {
                dataField: "patient_gender",
                text: "Patient Sex at Birth",
                formatter: this.genderFormatter
            },
            {
                dataField: "doctor_name",
                text: "Doctor Name"
            },
            {
                dataField: "updated_at",
                text: "Added On",
                formatter: this.dateFormatter
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };
    componentDidUpdate(_prevProps, prevState) {
        if (!this.state.doctors && prevState.key !== this.state.key) {
            this.getDoctorByLocation()
        }
    }
    appointmentColumn = () => [{
        dataField: "id",
        text: "ID",
        hidden: true
    },
    {
        dataField: "patient_code",
        text: "Patient Id",
    },
    {
        dataField: "patient_name",
        text: "Patient Name"
    },
    {
        dataField: "doctor_name",
        text: "Doctor Name"
    },
    {
        dataField: "patient_contact",
        text: "Contact Number"
    },
    {
        dataField: "slot",
        text: "Slot",
        formatter: this.slotFormatter
    },
    {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.queueActionFormatter
    }];
    queueActionFormatter = (_cell, row) => {
        if (row) {
            if (row.queue_status) {
                return 'Already Added'
            } else {
                return !row.patient_id ? <span>Add via search</span> : <DoctorAddQueueButton handleQueueClick={() => this.addToQueue(row.patient_id, row.doctor_id)} />
            }
        }
    }
    addToQueue = (patientId, doctor_profile_id) => {
        const queueData = {
            patient_id: patientId,
            appointment_type: 'queue',
            prescription_id: '',
            doctor_profile_id: doctor_profile_id,
        };
        AppointmentService.create({ data: queueData }).then(_resp => {
            Notification.show("success", { message: 'The patient added to the queue successfully.' });
            this.handleAppointmentList();
        }).catch(error => {
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data);
            }
        });
    }
    slotFormatter = (_cell, row) => {
        if (row.start_time && row.end_time) {
            return moment(row.start_time, 'HH:mm:ss').format('hh:mm A') + " to " + moment(row.end_time, 'HH:mm:ss').format('hh:mm a')
        }
    }
    genderFormatter = cell => {
        return capitalize(cell);
    };
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            queueData: [],
            key: 'queue',
            filter: {},
            appointments: '',
            doctors: '',
            tags: {}
        };
    }
    componentDidMount() {
        this._getQueueData();
    }

    componentWillMount() {
        this.interval = setInterval(() => {
            this._getQueueData();
        }, 180000);
    }

    getTodaysDate = () => {
        let datetime = new Date();
        let today = datetime.getFullYear() + "-" + String(datetime.getMonth() + 1).padStart(2, '0') + "-" + String(datetime.getDate()).padStart(2, '0');
        return today;
    }

    _getQueueData = () => {
        this.initiateService();
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    dateFormatter = (cell, _row) => {
        return HELPER.getDateTimeFormat(cell);
    }

    actionFormatter = (_cell, row) => {
        if (hasProvider(this.props.selected_user_profile.service_provider, ['doctor', 'covid-center'])) {
            if (!hasRole(this.props.selected_user_profile, ['doctor-receptionist', 'medical-practice-manager']) && row.prescription_id) {
                return <React.Fragment>
                    <Button className="btn btn-primary" onClick={this.handleButtonClick.bind(this, 'doctor_edit_prescription', row)}>Edit Prescription</Button>
                    <Button className="btn btn-secondary" onClick={this.handleButtonClick.bind(this, 'remove_queue', row)}>Remove From Queue</Button>
                </React.Fragment>
            } else {
                return (<React.Fragment>
                    <Button className="btn btn-primary" onClick={this.handleButtonClick.bind(this, 'doctor_view_patient', row)}>View Patient</Button>
                    <Button className="btn btn-secondary" onClick={this.handleButtonClick.bind(this, 'remove_queue', row)}>Remove From Queue</Button>
                </React.Fragment>)
            }
        }

        if (hasRole(this.props.selected_user_profile, ['pathology-receptionist', 'radiology-receptionist'])) {
            return <Button className="btn btn-secondary" onClick={this.handleButtonClick.bind(this, 'remove_queue', row)}>Remove From Queue</Button>;
        } else if (hasRole(this.props.selected_user_profile, ['pathologist'])) {
            return <Button className="btn btn-secondary" onClick={this.handleButtonClick.bind(this, 'pathology_view_prescription', row)}>View Prescription</Button>;
        } else if (hasRole(this.props.selected_user_profile, ['radiologist'])) {
            return <Button className="btn btn-secondary" onClick={this.handleButtonClick.bind(this, 'radiology_view_prescription', row)}>View Prescription</Button>;
        }
    }

    handleButtonClick = (type, row) => {
        this.props.loaderShow();
        AppointmentService.remove(row.id).then(() => {
            this.props.loaderHide();
            if (type === 'remove_queue') {
                Notification.show('success', 'User has been removed from the queue successfully');
                this._getQueueData();
            } else if (type === 'doctor_view_patient') {
                if (hasProvider(this.props.selected_user_profile.service_provider, ['covid-center'])) {
                    this.props.history.push('/covid-centers/patients/' + row.patient_id)
                } else {
                    this.props.history.push('/patients/' + row.patient_id)
                }

            } else if (type === 'doctor_edit_prescription') {
                this.props.history.push('/patients/' + row.patient_id + '/prescriptions/' + row.prescription_id)
            } else if (type === 'pathology_view_prescription') {
                this.props.history.push('/patient/pathology-test/' + row.prescription_id)
            } else if (type === 'radiology_view_prescription') {
                this.props.history.push('/patient/radiology-test/' + row.prescription_id)
            }
        })

    }

    actionButtonClick = (id) => {
        this.props.loaderShow();
        AppointmentService.remove(id)
            .then(resp => {
                this.props.loaderHide();
                this._getQueueData();
            });
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        if (this.state.key === "queue")
            this.initiateService(page, sizePerPage);
        else if (this.state.key === "appointment")
            this.handleAppointmentList(page, sizePerPage)
    }

    initiateService = (page, sizePerPage) => {
        const today = this.getTodaysDate()
        const filter = {
            appointment_date: today
        };
        this.setState({ isLoading: true })
        AppointmentService.list({ page, sizePerPage, filter: filter })
            .then(resp => {
                this.setState({
                    isLoading: false,
                    queueData: resp.data
                })
            });
    }
    handleAppointmentList = (page, sizePerPage) => {
        this.props.loaderShow();
        AppointmentService.todaysAppointments({ page, sizePerPage, filter: this.state.filter })
            .then(resp => {
                this.setState({ appointments: resp.data, key: 'appointment' })
                this.props.loaderHide();
            }).catch(_error => {
                this.props.loaderHide();
                Notification.show('error', { message: 'Something went wrong.' })
            })
    }
    handleSelectTab = (key) => {
        if (key === "queue")
            this.initiateService();
        else if (key === "appointment")
            this.handleAppointmentList()
        this.setState({ key });
    }
    handleFilterChange = (evt) => {
        let { filter } = this.state;
        filter[evt.target.name] = evt.target.value;
        if (evt.target.name === "doctor_profile_id") {
            let index = evt.nativeEvent.target.selectedIndex;
            filter['doctor_name'] = evt.nativeEvent.target[index].text
        }
        this.setState({ filter })
    }
    handleSearch = (e) => {
        e.preventDefault();
        let { tags, filter } = this.state
        this.handleAppointmentList();
        Object.assign(tags, filter);
        this.setState({ tags })
        window['searchToggle']();
    }
    handleResetFilter = (e) => {
        e.preventDefault();
        let { filter, tags } = this.state;
        (Object.keys(filter)).map((tag) => {
            filter[tag] = '';
            tags[tag] = ''
            return true;
        });
        this.setState({ filter, tags });
        this.handleAppointmentList();
        window['searchToggle']();
    }
    removeFilter = (tag) => {
        let { filter, tags } = this.state;
        delete filter[tag];
        delete tags[tag]
        this.setState({ filter, tags })
        this.handleAppointmentList();
    }
    getDoctorByLocation = () => {
        this.props.loaderShow();
        let { role_slug, user_location_id } = this.props.selected_user_profile;
        let location_id = includes(['doctor', 'associate-doctor'], role_slug) ? user_location_id : ''
        AppointmentService.getDoctorByLocation(location_id).then(resp => {
            this.setState({
                doctors: resp.data,
            });
            this.props.loaderHide();
        }).catch(_error => {
            this.props.loaderHide();
            Notification.show('error', { message: 'Something went wrong.' })
        })
    }
    render() {
        let { data, meta } = this.state.queueData;
        let { selected_user_profile: { service_provider } } = this.props

        return (<React.Fragment>
            <Helmet>
                <title>{service_provider.service_provider_type === 'doctor' ? 'Scheduled Appointment' : "Today's Queue"}</title>
            </Helmet>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-24 requester-tabs">
                        <h4 className="page-title">{service_provider.service_provider_type === 'doctor' ? 'Scheduled Appointment' : "Today's Queue"}</h4>
                    </div>
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs
                            defaultActiveKey="queue"
                            onSelect={this.handleSelectTab}
                        >
                            <Tab eventKey="queue" title="Today's Queue">
                                <div className="content-wrapper lg-height">
                                    {this.state.key === "queue" && <DataGrid
                                        columns={this.columns()}
                                        loading={this.state.isLoading}
                                        noDataIndication="No Record Found"
                                        data={data}
                                        meta={meta}
                                        pagination={true}
                                        handleTableChange={this.handleTableChange}
                                    />}
                                </div>
                            </Tab>
                            {service_provider.service_provider_type === 'doctor' && <Tab eventKey="appointment" title="Scheduled Appointment">
                                <AppointmentFilter
                                    tags={this.state.tags}
                                    filter={this.state.filter}
                                    handleSearch={this.handleSearch}
                                    handleFilterChange={this.handleFilterChange}
                                    doctors={this.state.doctors}
                                    handleResetSearch={this.handleResetFilter}
                                    removeFilter={this.removeFilter} />
                                <div className="content-wrapper">
                                    {this.state.key === "appointment" && this.state.appointments && <DataGrid
                                        columns={this.appointmentColumn()}
                                        loading={this.state.isLoading}
                                        noDataIndication="No Record Found"
                                        data={this.state.appointments.data}
                                        meta={this.state.appointments.meta}
                                        pagination={true}
                                        handleTableChange={this.handleTableChange}
                                    />}
                                </div>
                            </Tab>}
                        </Tabs>
                    </div>
                </div>
            </section>
        </React.Fragment >);
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    selected_doctor: state.selected_doctor,
    selected_user_profile: state.selected_user_profile,
});
const mapActionsToProps = ({
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
})
export default connect(mapStateToProps, mapActionsToProps)(QueuePage);
