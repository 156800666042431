import React, { Component } from 'react';

class FileDownloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: ''
    };
  }

  handleDownload = () => {
    const { fileUrl } = this.props;
    if (!fileUrl) {
      console.error('File URL not provided.');
      return;
    }

    const customFilename = 'download'; 
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', customFilename);
        link.click();

        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  };

  render() {
    return (
        <button className='btn btn-primary' onClick={this.handleDownload}>Download File</button>
    );
  }
}

export default FileDownloader;
