import React from 'react';
import DataGrid from '../DataGrid';

class PatientPathologyLabReport extends React.Component {
   constructor(props) {
    super(props);
   }
    render() {
        return (
            <React.Fragment>
                {<div className="content-wrapper">
                    <DataGrid
                        columns={this.props.pathologyColumns}
                        data={this.props.documents && this.props.documents.data}
                        meta={this.props.documents && this.props.documents.meta}
                        noDataIndication="No records found!"
                        handleTableChange={this.props.handlePathologyTableChange}
                    />
                </div>}
            </React.Fragment>
        )
    }
}
export default PatientPathologyLabReport